import { createBrowserRouter } from "react-router-dom"

import App                   from "./App"
import CaseManagement        from "./Pages/CaseManagement"
import DesktopConfigurations from "./Pages/DesktopConfigurations"
import Groups                from "./Pages/Groups"
import MTPCaseList           from "./Pages/MobileTreatmentPlan/MTPCaseList"
import Case from "./Pages/CaseManagement/Case"
import UserHistory from "./Pages/CaseManagement/UserHistory"
import Transitions from "./Pages/CaseManagement/Transitions/Transitions"
import GroupItem from "./Pages/Groups/GroupItem"
import Workflow from "./Pages/Workflow/Workflow"
import MTPCase from "./Pages/MobileTreatmentPlan/MTPCase"

const Router = createBrowserRouter([
    { 
        path: "/*", 
        element: <App/>,
        children: [
            {
                path: '',
                element: <CaseManagement/>,
            },
                {
                    path:"case-management/case/:caseId",
                    element: <Case/>
                },
                {
                    path:"case-management/case/:caseId/history",
                    element: <Case activeTab='HISTORY'/>
                },
                {
                    path:"case-management/case/:caseId/inspector",
                    element: <Case activeTab='SMILE_INSPECTOR'/>
                },
                {
                    path:"case-management/case/:caseId/automation",
                    element: <Case activeTab='MILD_CASE_AUTOMATION'/>
                },
                {
                    path:"case-management/case/:caseId/comments",
                    element: <Case activeTab='COMMENTS'/>
                },
                {
                    path:"case-management/user-history",
                    element: <UserHistory />
                },
                {
                    path:"case-management/case-status-transitions",
                    element: <Transitions/>
                },
                {
                    path:"case-management/workflow",
                    element: <Workflow />
                },
            {
                path: "desktop-configuration",
                element: <DesktopConfigurations/>
            },
            {
                path: "groups",
                element: <Groups/>
            },
                {
                    path:"groups/:uuid",
                    element: <GroupItem/>
                },
            {
                path: "mobile-treatment-plan",
                element: <MTPCaseList/>
            },
                {
                    path:"mobile-treatment-plan/:caseId",
                    element: <MTPCase/>
                },
            {
                path:"logincallback",
                element: <></>
            },
            {
                path:"*",
                element: <div> 404 , PATH { window.location.pathname } </div>
            }
        ]
    },
])

export default Router

