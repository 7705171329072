import { authClient } from "../App"
const isOktaTokenPresentInLocalStorage = async () =>{
    let result = false

    // token stored in 
    // const oktaToken = localStorage.getItem('okta-token-storage')
    // or session storage  
    await authClient.tokenManager.get('accessToken')
    .then(accessToken => {
        if (!accessToken) {
            // You're not logged in, you need a sessionToken
            authClient.token.getWithRedirect({
                responseType: 'code'
            })
        }else{
            result = true
        }
    })

    return result
} 

export default isOktaTokenPresentInLocalStorage
