import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from "react"
import getTransitionFromStatus from "../../../Api/CaseManagement/v2/get-transition-from-status"
import postUpdateCaseTransitionStatus from "../../../Api/CaseManagement/v2/post-update-case-trnsition-status"
import { useAction } from "../../../Store/Hook/use-action"
type TCaseStatusProps = {
    caseId: string
    statusValueExternal: string
    needUpdateCaseView: ()=> void
}

const CaseStatus = (props: TCaseStatusProps) =>{
    const { statusValueExternal, caseId, needUpdateCaseView } = props
    const [ open, setOpen] = useState(false)
    const [ isNeedToUpdateStatusesFromApi, setNeedUpdateStatusesFromApi ] = useState(false)
    const [ isNeedToSendStatus, setNeedToSendStatus ] = useState(false)
    const [ statusesList, setStatusesList ] = useState<string[]>([])
    const [ selectedStatus, setSelectedStatus ] = useState('')
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    const handleClickOpenDialog = () => {
        setOpen(true)
        setNeedUpdateStatusesFromApi(true)
    }
    
    const handleCloseDialog = () => {
        setOpen(false)
        setStatusesList([])
        setSelectedStatus('')
    }

    const changeSelectedStatus = (e:SelectChangeEvent) => {
        setSelectedStatus(e.target.value)
    }

    const handleClickApply = () =>{
        setNeedToSendStatus(true)
    }

    useEffect(()=>{
        if(isNeedToUpdateStatusesFromApi === true){
            setNeedUpdateStatusesFromApi(false)
            getTransitionFromStatus(statusValueExternal)
            .then((response)=>{
                setStatusesList(response.data.transitions)
            })
            .catch((error)=>{
                console.log('CANT GET TRANSITIONS FROM STATUS' + statusValueExternal )
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET TRANSITIONS FROM STATUS' + statusValueExternal 
                })
                setCaseManagementErrorNeedToShow(true)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToUpdateStatusesFromApi])

    useEffect(()=>{
        if(isNeedToSendStatus === true){
            setNeedToSendStatus(false)
            postUpdateCaseTransitionStatus({
                caseId: caseId,
                status: selectedStatus,
                smoVersion: '',
            })
            .then((response)=>{
                needUpdateCaseView()
            })
            .catch((error)=>{
                console.log('CANT UPDATE TRANSITION STATUS ' + selectedStatus )
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET TRANSITIONS FROM STATUS ' + statusValueExternal 
                })
                setCaseManagementErrorNeedToShow(true)
            })
            .finally(()=>{
                handleCloseDialog()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendStatus])

    return(
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            { statusValueExternal }

            <Tooltip title="Change status">
                <IconButton 
                    sx={{ margin: '0 0 0 10px' }}
                    onClick={handleClickOpenDialog}
                >
                    <EditIcon sx={{fill:'#eee'}}/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    Change status { statusValueExternal }
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ minWidth: 120, margin: '8px 0 0 0' }}>
                        <FormControl fullWidth>
                            <InputLabel id="select-status-label">New Status</InputLabel>
                            <Select
                                labelId="select-status-label"
                                value={selectedStatus}
                                label="New Status"
                                onChange={changeSelectedStatus}
                            >
                                { 
                                    statusesList.map((item, index)=>{
                                        return(
                                            <MenuItem
                                                key={`select-status-transition-key-${index}`}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                
                <DialogActions>
                    <Button 
                        autoFocus 
                        onClick={handleClickApply}
                        disabled={ selectedStatus === '' }
                    >
                        Apply
                    </Button>
                    <Button onClick={handleCloseDialog} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}

export default CaseStatus
