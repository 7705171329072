import { Dispatch } from 'redux'
import { 
    enumStoreCaseManagementAction,
    TCaseManagementSetCasesV2,
    TCaseManagementSetError,
    TCaseManagementSetErrorNeedToShow,
    TCaseManagementSetErrorPayload,
    TCaseManagementSetFilterData,
    TCaseManagementSetNeedUpdateGroupsFromAPI, 
    TCaseManagementSetPageNumber, 
    TCaseManagementSetPageSize, 
    TCase, 
} from '../../Types/Store/StoreCaseManagement'
import { TApiGetCasesByFilterParams } from '../../Api/CaseManagement/v2/get-cases-by-filter'

export const setNeedUpdateCaseManagementFromAPI = ( isNeedToUpdate: boolean) =>{
    return((dispatch: Dispatch<TCaseManagementSetNeedUpdateGroupsFromAPI>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_NEED_UPDATE_GROUPS_FROM_API,
            payload: isNeedToUpdate
        })
    })
}

export const setCaseManagementError = ( error: TCaseManagementSetErrorPayload ) => {
    return((dispatch: Dispatch<TCaseManagementSetError>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_ERROR,
            payload: error
        })
    })
}

export const setCaseManagementErrorNeedToShow = ( isErrorNeedToShow: boolean ) => {
    return((dispatch: Dispatch<TCaseManagementSetErrorNeedToShow>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_ERROR_NEED_TO_SHOW,
            payload: isErrorNeedToShow
        })
    })
}

export const setCaseManagementCases = ( casesList: TCase[] ) => {
    return((dispatch: Dispatch<TCaseManagementSetCasesV2>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_CASES,
            payload: casesList
        })
    })
}

export const setCaseManagementPageNumber = ( pageNumber: number ) => {
    return((dispatch: Dispatch<TCaseManagementSetPageNumber>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_PAGE_NUMBER,
            payload: pageNumber
        })
    })
}

export const setCaseManagementPageSize = ( pageSize: number ) => {
    return((dispatch: Dispatch<TCaseManagementSetPageSize>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_PAGE_SIZE,
            payload: pageSize
        })
    })
}

export const setCaseManagementFilterData = ( filterData: TApiGetCasesByFilterParams ) => {
    return((dispatch: Dispatch<TCaseManagementSetFilterData>) =>{
        dispatch({
            type: enumStoreCaseManagementAction.SET_CM_FILTER_DATA,
            payload: filterData
        })
    })
}
