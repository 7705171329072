import { CSSProperties } from "react"
import formatDateToLocal from "../../../../Utils/format-date-to-local"
import formatBytes from "../../../../Utils/format-bytes"


export type TCaseExtraViewProps = {
    extra?: [string, unknown][]
    exclude?: string[]
} 
const FilesTreeNodeActionInfoView = (props: TCaseExtraViewProps) =>{
    
    const { extra, exclude} = props
    let style:CSSProperties = {
        minWidth: '100px',
        margin: '0 10px 0 0',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'flex-end',
    }

    return(
        <>
        {
            extra ? 
                extra
                .filter((item)=>{
                    return(
                        exclude && exclude.indexOf(item[0])<0 ? true : false
                    )
                })
                .map((item,index)=>{
                    
                    let itemContent = <></>

                    switch(item[0]){

                        case 'createdAt':
                            itemContent = 
                                <>
                                    { formatDateToLocal(`${item[1]}`) }
                                </>
                            break

                        case 'updatedAt':
                            itemContent = 
                                <>
                                    { formatDateToLocal(`${item[1]}`) }
                                </>
                            break
                        
                        case 'size':
                                itemContent = 
                                <>
                                    { formatBytes(Number(item[1])) }
                                </>
                                break


                        default:
                            itemContent = 
                                <>
                                    {` ${item[1]}`}
                                </>
                            break
                    }
                        

                    return(

                        <span key={`$extra-${index}`}
                            style={{
                                display: 'flex',
                            }}
                        >
                            <span style={style}>
                                { item[0]}:
                            </span> 
                            { itemContent }
                            <br/>
                        </span>

                    )})
            : null
        }
        </>
    )
}

export default FilesTreeNodeActionInfoView
