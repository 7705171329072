const converObjectToGetString = (parametersObject: object):string =>{

    let result = Object
    .entries( parametersObject )
    .map( entrie => `${entrie[0]}=${entrie[1]}`)
    .join('&')


    return( result ? `?${result}` : '' )
}

export default converObjectToGetString
