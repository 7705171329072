import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import { enumApiParameter } from "../../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/getByCaseId

export type TApiGetCaseByCaseIdParams = {
    [enumApiParameter.CASE_ID    ] : string
}

const getCaseByCaseId = async ( requestParams: TApiGetCaseByCaseIdParams ): Promise<AxiosResponse<TCase>>  =>{
    return await API.get(
        
        `/v2/cases/${ requestParams.caseId }`
    )
}

export default getCaseByCaseId
