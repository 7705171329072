import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"
import { UserGroup } from "../../Types/Store/StoreGroups"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Groups%20API/getById

export type TApiGetGroupParams = {
    [enumApiParameter.GROUP_ID ] : string 
}

const getGroup = async ( params: TApiGetGroupParams ): Promise<AxiosResponse<UserGroup>>  =>{
    return await API.get(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}`
    
    )
}

export default getGroup
