import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import { toolbarButtonStyle } from "./FilesToolbar"
import useValidation, { enumFormValidationRule } from '../../../formValidator'

type TFilesToolbarActionAddNewFolderProps = {
    getNewFolderName: (newFolderName:string)=> void
}

const FilesToolbarActionAddNewFolder = (props:TFilesToolbarActionAddNewFolderProps) =>{

    const { getNewFolderName } = props
    const [open, setOpen] = useState(false)
    const [ trigger, setTrigger ] = useState(false)
    const [
        nameValue,
        isNameValid,
        ,
        onNameChange
    ] = useValidation([enumFormValidationRule.REQUIRED],'')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        onNameChange('',true)
        setOpen(false)
    }

    const handleCreate = () => {
        onNameChange(nameValue)
        setTrigger(true)
    }

    useEffect(()=>{
        if(trigger === true){
            setTrigger(false)
            if(isNameValid){
                getNewFolderName(nameValue)
                setOpen(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[trigger])

    return(
        <>
            <Button 
                size="small"
                variant="outlined" 
                startIcon={<CreateNewFolderIcon />}
                sx={toolbarButtonStyle}
                onClick={handleClickOpen}
            >
                Add Folder
            </Button>
            <Dialog 
                open={open} 
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle>Add Folder</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New folder name"
                    type="New Folder"
                    fullWidth
                    variant="standard"
                    value={nameValue}
                    onChange={(e)=>{
                        if(String(e.target.value).trim() === ''){
                            onNameChange(String(e.target.value).trim(),true)
                        }else{
                            onNameChange(String(e.target.value),false)
                        }
                    }}
                    error={!isNameValid}
                />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FilesToolbarActionAddNewFolder
