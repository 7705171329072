import { enumSORT, enumSortingField } from "../../Types/General"
import { enumStoreDesktopConfigurationsAction, IStoreDesktopConfigurations, TDesktopConfigurationsStoreAction } from "../../Types/Store/StoreDesktopConfig"

const initialState:IStoreDesktopConfigurations = {
    
    // applicationsList will be depricated
    applicationsList:[
        'SmileOS',
        'KoolShape',
    ],

    applicationsDescription:[],

    applications:{
        needUpdateFromAPI: true,
        configs          : [],
        totalElements    : 0,
        number           : 0,
        size             : 100,
        sorting          : enumSORT.ASC,
        current          : undefined,
    },

    groups:{
        needUpdateFromAPI: false,
        groupsList       : [],
        configs          : [],
        totalElements    : 0,  // total number of records
        number           : 0,  // page_number
        size             : 100, // records per page
        selectedApplicationVersion: undefined,
        selectedApplicationIndex: 0,
        sorting          : enumSORT.ASC,
        periodFrom       : null,
        periodTo         : null,
        
        current          : undefined,
    },

    users:{
        needUpdateFromAPI: false,
        configs          : [],

        totalElements    : 0,
        number           : 0,
        size             : 100,
        
        selectedApplicationVersion: undefined,
        selectedApplicationIndex : 0,
        sortingDirection         : enumSORT.ASC,
        sortingField             : enumSortingField.LOGIN,
        
        periodFrom               : null,
        periodTo                 : null,
        
        loginFilter              : '',
        
        current          : undefined,
    },

    error:{
        needToShow: false,
        status    : '',
        path      : '',
        message   : '',
    }


}

export const desktopConfigsReducer = ( 

    state = initialState,
    action : TDesktopConfigurationsStoreAction

) : IStoreDesktopConfigurations => {

    switch(action.type){

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS_SELECTED_APPLICATION_VERSION:
            return({
                ...state,
                users:{
                    ...state.users,
                    selectedApplicationVersion: action.payload
                }
            })

        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS_SELECTED_APPLICATION_VERSION:
            return({
                ...state,
                groups:{
                    ...state.groups,
                    selectedApplicationVersion: action.payload
                }
            })

        case enumStoreDesktopConfigurationsAction.SET_DC_APPLICATIONS_DESCRIPTION:
            return({
                ...state,
                applicationsDescription:[
                    ...action.payload
                ]
            })

        //====================================================================
        // APPLICATIONS CONFIGS SECTION
        //====================================================================
        
        case enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_APPLICATIONS_FROM_API:{
            return({
                ...state,
                applications:{
                    ...state.applications,
                    needUpdateFromAPI: action.payload
                }
            })
        }

        //====================================================================
        // GROUPS CONFIGS SECTION
        //====================================================================

        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS:
            return({
                ...state,
                groups:{
                    ...state.groups,
                    configs: action.payload,
                    needUpdateFromAPI: false,
                },
            })
        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_LIST:
            return({
                ...state,
                groups:{
                    ...state.groups,
                    groupsList: action.payload
                },
            })
            

        case enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_GROUPS_FROM_API:{
            return({
                ...state,
                groups:{
                    ...state.groups,
                    needUpdateFromAPI: action.payload
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PAGINATION:{
            return({
                ...state,
                groups:{
                    ...state.groups,
                    ...action.payload,
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PERIOD: {
            return({
                ...state,
                groups:{
                    ...state.groups,
                    ...action.payload
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_SORTING_DIRECTION:{
            return({
                ...state,
                groups:{
                    ...state.groups,
                    sorting: action.payload.sortingDirection
                }
            })
        }

        //====================================================================
        // USERS CONFIGS SECTION
        //====================================================================

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS:{
            return({
                ...state,
                users:{
                    ...state.users,
                    configs: action.payload,
                    needUpdateFromAPI: false,
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_USERS_FROM_API:{
            return({
                ...state,
                users:{
                    ...state.users,
                    needUpdateFromAPI: action.payload
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_PAGINATION:{
            return({
                ...state,
                users:{
                    ...state.users,
                    ...action.payload
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_SELECTED_APPLICATION_INDEX:{
            return({
                ...state,
                users:{
                    ...state.users,
                    selectedApplicationIndex: action.payload
                }
            })
        }
        
        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_LOGIN_FILTER :{
            return({
                ...state,
                users:{
                    ...state.users,
                    loginFilter: action.payload
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_SORTING_DIRECTION:{
            return({
                ...state,
                users:{
                    ...state.users,
                    sortingDirection: action.payload.sortingDirection,
                    sortingField    : action.payload.sortingField
                }
            })
        }

        case enumStoreDesktopConfigurationsAction.SET_DC_USERS_PERIOD:{
            return({
                ...state,
                users:{
                    ...state.users,
                    ...action.payload,
                }
            })
        }





        case enumStoreDesktopConfigurationsAction.SET_DC_ERROR_NEED_TO_SHOW:{
            return({
                ...state,
                error:{
                    ...state.error,
                    needToShow: action.payload
                }
            })
        }
        
        case enumStoreDesktopConfigurationsAction.SET_DC_ERROR:{
            return({
                ...state,
                error:{
                    ...state.error,
                    ...action.payload
                }
            })
        }

        default:
            return state
    }

}
