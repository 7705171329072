

const convertMsToHM = (millisecondsValue:number | null) => {
    let milliseconds = millisecondsValue === null ? 0 : millisecondsValue
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = seconds >= 30 ? minutes + 1 : minutes
    minutes = minutes % 60
    hours = hours % 24

    let result = ''
    if(hours > 0){
        result+=hours+'h '
    }

    if(minutes > 0){
        result+=  minutes+'m '
    }

    if(seconds > 0){
        result+= seconds+'s'
    }

    if(milliseconds< 1000 && millisecondsValue!== null){
        result+=milliseconds+'ms'
    }

    return result
}

export default convertMsToHM
