
const downloadFileByUrl = (fileName:string, fileUrl: string ) =>{

    fetch(fileUrl)
    .then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        if(a.download !== "null")
        {
            a.click()
        }
        window.URL.revokeObjectURL(url)
    })
    .catch(() => alert('Could not download file.'))
}


const downloadFileByUrlWithHref = (fileName:string, fileUrl: string) => {
    const element = document.createElement('a')
    element.setAttribute('href', fileUrl)
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

export { downloadFileByUrlWithHref, downloadFileByUrl }
