import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TApiPostCreateUpdateUserConfigParams } from "./post-create-user-config"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/User%20configuration%20API/update

const putUpdateUserConfig = async ( params: TApiPostCreateUpdateUserConfigParams ): Promise<AxiosResponse>  =>{
    return await API.put(
        `/v2/configurations/users/${String(params[enumApiParameter.APPLICATION])}/${params[enumApiParameter.VERSION]}/${params[enumApiParameter.LOGIN]}`,
        params.data
    )
}

export default putUpdateUserConfig
