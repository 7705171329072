import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseFileDescription } from "../../../Types/CaseManagement"
import { enumApiParameter } from "../../../Types/General"
import { TApiGetFileListByCaseIdParams } from "./get-file-links-by-case-id"

const getFileListByCaseId = async ( params: TApiGetFileListByCaseIdParams ): Promise<AxiosResponse<TCaseFileDescription[]>>  =>{
    return await API.get(
        `/v2/cases/${params[enumApiParameter.ID]}/files`,
    )
}

export default getFileListByCaseId
