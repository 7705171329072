import { useEffect } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useAction } from "../../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { enumApiParameter, enumSORT } from "../../../../Types/General"

import getGroupsByName from '../../../../Api/UserGroups/get-groups-by-name'
import getListOfGroupConfigSortByUpdatedAt, { TApiGetListOfGroupConfigByApplicationSortByUpdatedAtParams } from '../../../../Api/DesktopConfiguration/v2/get-list-of-group-config'
import { DateRangePicker } from 'rsuite'
import { getDateInputTemplate } from '../../../../Utils/format-date-to-local'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { isAfter } from 'date-fns'

const GroupsListFilters = () =>{
    const {
        needUpdateFromAPI,
        sorting,
        number,
        size,
        periodFrom,
        periodTo,
        selectedApplicationVersion
    } = useTypedSelector(store => store.desktopConfigurations.groups)

    const { 
        applicationsDescription
    } = useTypedSelector(store => store.desktopConfigurations)

    const {
        SetGroupsPagination,
        SetGroupsDesktopConfigsFromAPI,
        SetGroupsPeriod,
        SetNeedUpdateGroupConfigsFromAPI,
        SetError,
        SetErrorNeedToShow,
        SetGroupsSelectedApplicationVersion,
        SetGroupsDesktopGroupsListFromAPI
    } = useAction()

    const handleChangeApplication = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value.split('|')
        
        SetGroupsSelectedApplicationVersion({
            name: selectedValue[0],
            version: selectedValue[1]
        })
        SetNeedUpdateGroupConfigsFromAPI(true)
    }

    useEffect(()=>{
        if(needUpdateFromAPI===true){
            
            const parameters:TApiGetListOfGroupConfigByApplicationSortByUpdatedAtParams = {
                [enumApiParameter.APPLICATION]: selectedApplicationVersion ? selectedApplicationVersion.name : '',
                [enumApiParameter.VERSION    ]: selectedApplicationVersion ? selectedApplicationVersion.version : '',
                [enumApiParameter.PAGE_SIZE]  : size,
                [enumApiParameter.PAGE_NUMBER]: number,
                [enumApiParameter.DIRECTION]  : sorting,
                [enumApiParameter.PERIOD_FROM]: periodFrom === null ? undefined : periodFrom,
                [enumApiParameter.PERIOD_TO]  : periodTo === null ? undefined : periodTo
            }
            if(typeof(parameters[enumApiParameter.PERIOD_FROM]) === 'undefined'){
                delete parameters[enumApiParameter.PERIOD_FROM]
            }
            if(typeof(parameters[enumApiParameter.PERIOD_TO]) === 'undefined'){
                delete parameters[enumApiParameter.PERIOD_TO]
            }
            
            getListOfGroupConfigSortByUpdatedAt({
                ...parameters
            }).then((responce)=>{
                SetGroupsPagination({
                    number: responce.data.number,
                    size: responce.data.size,
                    totalElements: responce.data.totalElements,
                })
                SetGroupsDesktopConfigsFromAPI(responce.data.content)

            }).catch((error)=>{
                SetError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET LIST OF GROUP CONFIGS SORTED BY UPDATED AT'
                })
                SetErrorNeedToShow(true)
                console.log('CANT GET LIST OF GROUP CONFIGS SORTED BY UPDATED AT', JSON.stringify(error))
            })

            // Internal request for groupList dropdown from create/uddate form
            getGroupsByName({
                [enumApiParameter.DIRECTION]  : enumSORT.ASC,
                [enumApiParameter.PAGE_NUMBER]: 0,
                [enumApiParameter.PAGE_SIZE]  : 64535,
            }).then((groupListResponse)=>{
                SetGroupsDesktopGroupsListFromAPI(groupListResponse.data.content.map(item=>{
                    return({
                        groupId: item.id,
                        groupName: item.name,
                    })
                }))
            }).catch((error)=>{
                SetError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET WHOLE LIST OF GROUPS CONFIGS SORTED BY UPDATED AT'
                })
                SetErrorNeedToShow(true)
                console.log('CANT GET LIST OF GROUP CONFIGS SORTED BY UPDATED AT', JSON.stringify(error))
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateFromAPI])

    const onDataRangeChange = (e: DateRange | null ) =>{
        if(e === null){
            SetGroupsPeriod({
                periodFrom: null,
                periodTo: null
            })
        }else{
            const dateStrings = e.map(item=>item.toISOString())
            SetGroupsPeriod({
                periodFrom: dateStrings[0],
                periodTo: dateStrings[1]
            })
        }
        SetNeedUpdateGroupConfigsFromAPI(true)
    }

    return(
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                padding: '10px 0 8px 0',
                margin: '10px 0 0 0',
                borderRadius: '6px',
                backgroundColor: '#f3f3f3', 
            }}
        >
                <FormControl >
                    <InputLabel id="software-selector-label">Software</InputLabel>
                    <Select
                        labelId="software-selector-label"
                        id="software-selector"
                        value={ typeof selectedApplicationVersion!=='undefined' ? `${selectedApplicationVersion.name}|${selectedApplicationVersion.version}` : ''}
                        size='small'
                        label="Software"
                        onChange={ handleChangeApplication }
                        sx={{
                            margin: ' 8px 0 0 8px', 
                            padding: 0,
                            width: '200px',
                            backgroundColor: '#fff'
                        }}
                    >
                        
                        {
                            applicationsDescription.map((applicationItem, applicationItemindex)=>{
                                return(
                                    
                                    applicationItem.versions.map((versionItem, versionItemIndex)=>{
                                        return(
                                            <MenuItem 
                                                value={ `${applicationItem.name}|${versionItem}` }
                                                key = {`version-${applicationItemindex}-${versionItemIndex}`}
                                            >
                                                { `${applicationItem.name} ${versionItem}` }
                                            </MenuItem>
                                        )
                                    })
                                    
                                )
                            })
                        }
                        
                    </Select>
                </FormControl>

                {
                    selectedApplicationVersion && selectedApplicationVersion.name ?
                        <div style = {{
                            display: 'flex'
                        }}>
                            <DateRangePicker
                                placeholder="Updated Date"
                                format={ getDateInputTemplate()[0] }
                                placement={'auto'}
                                onChange={ onDataRangeChange }
                                
                                style={{
                                    padding: '8px',
                                    zIndex: 20,
                                    width: '220px',
                                }}
                                value = { 
                                    periodFrom && periodTo ? [ new Date(periodFrom), new Date(periodTo) ] : null
                                }
                                shouldDisableDate={ date => isAfter(date, new Date())}
                            />
                        </div>
                    :null
                }
        </Box>
    )
}

export default GroupsListFilters
