import { useEffect, useState } from 'react'
import getCaseStatusTransitions from '../../../Api/CaseManagement/v2/get-case-status-transitions'

const useCaseStatus = () =>{

    const [statusArray, setStatusArray] = useState<string[]>([])

    useEffect(()=>{

        getCaseStatusTransitions()
        .then((response)=>{
            setStatusArray(response.data.map(item => item.status))
        })
        .catch((error)=>{
            console.log('CANT GET STATUS TRANSITIONS', error)
        })

    },[])

    return(statusArray)
}

export default useCaseStatus
