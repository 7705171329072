import { OktaAuth } from "@okta/okta-auth-js"

const getTokensWithRedirect = (
    authClient: OktaAuth,
    setErrorCode    : (errorCode:string) => void,
    setErrorSummary : (errorSummary: string) => void
) => {
    return(
        authClient.token.getWithRedirect({
            responseType: ['refresh_token','code','id_token'],
            responseMode: 'fragment',
        }).catch((error)=>{
            console.log('GET TOKEN WITH REDIRECT AFTER REFRESH_TOKEN AND ID_TOKEN RENEW ERROR', JSON.stringify(error))
            setErrorCode(error.errorCode)
            setErrorSummary(error.errorSummary)
        })
    )
}

export default getTokensWithRedirect
