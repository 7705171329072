import { Box, Button, Modal, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import deleteStatusTransition from "../../../Api/CaseManagement/v2/delete-case-status-transitions-status"
import { TWorkflowSetErrorPayload } from "../../../Types/Store/StoreWorkflow"

export type TTransitionDeleteFormProps = {
    status: string
    setNeedUpdateFromAPI  : Dispatch<SetStateAction<boolean>>
    setApiError           : Dispatch<SetStateAction<TWorkflowSetErrorPayload>>
    clearFilter           : () => void
}

const TransitionDeleteForm = ( props:TTransitionDeleteFormProps ) =>{
    
    const { status, setNeedUpdateFromAPI, setApiError, clearFilter } = props

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [ isRequestNeeded    , setRequestNeeded       ] = useState(false)

    useEffect(()=>{
        if(isRequestNeeded === true){

            deleteStatusTransition(status)
            .then(()=>{
                setNeedUpdateFromAPI(true)
            })
            .catch((error)=>{
                setApiError({
                    needToShow: true,
                    message: error.response.data.message,
                    path: error.response.data.path,
                    status: error.response.data.status
                })
            })
            .finally(()=>{
                handleClose()
            })
            setRequestNeeded(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isRequestNeeded])

    return(
        <>
            <Button
                variant="outlined"
                color="warning"
                onClick={ handleOpen }
            >
                Delete
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                onContextMenu = {(e)=>{
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'max-content',
                        bgcolor: '#fff',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}

                >
                    <Typography  variant="h6" component="h2">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column'
                            }}
                        >
                            <Box>
                                Do you really want to delete this transition?
                            </Box>
                            <Box
                                sx={{width: '100%', margin: '30px' }}
                            >
                                { status }
                            </Box>
                        </Box>
                    </Typography>
                    
                    
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button 
                            variant='outlined' 
                            onClick={()=>{
                                setRequestNeeded(true)
                                clearFilter()
                            }} 
                            sx={{margin: '0 30px 0 0'}}
                        >
                            Delete
                        </Button>

                        <Button variant='outlined' onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default TransitionDeleteForm
