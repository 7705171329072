import { AxiosResponse } from "axios"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/getByStatus


export type TCaseStatusTransition = {
    status      : string
    initial     : boolean
    transitions : string[]
    version     : number | null
}

const getTransitionFromStatus = async ( status: string ): Promise<AxiosResponse<TCaseStatusTransition>>  => {
    return await API.get(
        `/v2/case-status-transitions/${ status }`
    )
}

export default getTransitionFromStatus
