import { useState, useEffect } from "react"
import { Box, Button, Modal, Typography } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import { TWorkflowConfiguration } from "../../Types/Store/StoreWorkflow"
import deleteConfiguration from "../../Api/Workflow/v2/delete-configuration"
import { TApiErrorDescription } from "./ErrorModal"

type TDeleteWorkflowConfigurationProps = {
    itemToDelete: TWorkflowConfiguration
    needUpdateFromApi: ()=>void
    setApiError: (params:TApiErrorDescription)=>void
}

const DeleteWorkflowConfiguration = (props:TDeleteWorkflowConfigurationProps) =>{
    
    const { itemToDelete, needUpdateFromApi, setApiError } = props
    const [ isOpen, setOpen ] = useState(false)
    const [ isNeedToSendRequest, setNeedSendRequest ] = useState(false)

    const handleOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const handleDelete = () =>{
        setNeedSendRequest(true)
    }

    useEffect(()=>{
        if(isNeedToSendRequest === true){
            setNeedSendRequest(false)

            deleteConfiguration(itemToDelete)
            .then((response)=>{
            })
            .catch((error)=>{
                console.log('CANT DELETE WORKFLOW CONFIGURATION', JSON.stringify(error))
                setApiError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT DELETE WORKFLOW CONFIGURATION"
                })
            })
            .finally(()=>{
                handleClose()
                needUpdateFromApi()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])
    
    return(
        <>
            <Box
                sx={{
                    margin: '0px 8px',
                    transition: 'all .2s ease-out',
                    borderRadius: '6px',
                    '&:hover':{
                        cursor: 'pointer',
                        fill: '#5700ff',
                        backgroundColor: '#eee',
                    }
                }}
                component = { CancelIcon }
                onClick   = { handleOpen }
            />

            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'max-content',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2"
                        sx={{
                            margin: '0 0 32px 0'
                        }}
                    >
                        Are you sure you want remove property
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong> { itemToDelete.description } </strong> : {  itemToDelete.value  }
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '32px 0 0 0',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default DeleteWorkflowConfiguration
