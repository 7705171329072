import { enumStoreBaseAction, IStoreBase, TBaseStoreAction } from "../../Types/Store/StoreBase";

const initialState:IStoreBase = {
    userInfo:{
        email: undefined
    }
}

export const baseReducer = ( 

    state = initialState,
    action : TBaseStoreAction

) : IStoreBase => {

    switch(action.type){

        case  enumStoreBaseAction.SET_BASE_USER_INFO_FROM_API :            
            return ({
                ...state,
                userInfo:{
                    email: action.payload
                }
            })

        default:
            return state
    }

}
