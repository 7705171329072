import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Users%20in%20groups%20API/add

export type TApiAddUserToGroupParams = {
    [enumApiParameter.GROUP_ID ] : string
    [enumApiParameter.LOGIN    ] : string // email
}

const postAddUserToGroup = async ( params: TApiAddUserToGroupParams ): Promise<AxiosResponse>  =>{
    return await API.post(
    
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,
    
        { 
            [enumApiParameter.LOGIN] : params[enumApiParameter.LOGIN] 
        }
    )
}

export default postAddUserToGroup
