import { AxiosResponse } from "axios"
import API from '../api-base'
import { TProcessInstanceActivityDescription } from "../../../Types/Store/StoreCaseManagement"


type TParams = {
    processInstanceId: string | undefined
}

type TResonse = TProcessInstanceActivityDescription[]


const postReceiveProcessInstanceActivities = async ( params: TParams ): Promise<AxiosResponse<TResonse>>  =>{

    const { processInstanceId } = params

    const data = {
        "processInstanceId": processInstanceId,
        "sorting": [
            {
                "sortBy": "occurrence",
                "sortOrder": "asc"
            }
        ]
    }

    return await API.post(
        `/engine-rest/history/activity-instance`,
    
        { 
            ...data
        }
    )
}

export default postReceiveProcessInstanceActivities
