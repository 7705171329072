import { Box } from "@mui/material"

const Warning = () =>{
    return(
        <Box 
            sx={{ 
                width: '100%',
                height: '400px',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            No files found in case
        </Box>
    )
}

export default Warning
