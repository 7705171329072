import { AxiosResponse } from "axios"
import API from '../api-base'
import { TProcessInstanceDescription } from "../../../Types/Store/StoreCaseManagement"



type TParams = {
    caseId: string | undefined
}

type TResonse = TProcessInstanceDescription[]


const postReceiveProcessInstances = async ( params: TParams ): Promise<AxiosResponse<TResonse>>  =>{

    const { caseId } = params

    const data = {
    "variables": [
        {
            "name": "caseId",
            "operator": "eq",
            "value": caseId ? caseId : ""
        }
    ],
    "processDefinitionKeyIn": ["pre-setup-automation", "export-to-smilecheck"],
    "sorting": [
        {
            "sortBy": "startTime",
            "sortOrder": "asc"
        }
    ]
}

    return await API.post(
        `/engine-rest/history/process-instance`,
    
        { 
            ...data
        }
    )
}

export default postReceiveProcessInstances
