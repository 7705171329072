const convertPathToStringArray = (filePath: string):string[] =>{
    
    const filePathWithoutRootSlash = filePath.slice(1,filePath.length)

    return(
        filePathWithoutRootSlash.split('/')
    )
}

export default convertPathToStringArray
