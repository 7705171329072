
import { 
    OktaAuthOptions,
} from '@okta/okta-auth-js'



export const OKTA_CLIENT_ID:string =  
    window['REACT_APP_OKTA_CLIENTID'] ?

        window['REACT_APP_OKTA_CLIENTID'] 
    : 
        ( process.env.REACT_APP_OKTA_CLIENTID ?
            
            process.env.REACT_APP_OKTA_CLIENTID 
        : 
            'CLIENT_ID_UNDEINED'
        )
export const OKTA_DOMAIN:string    = window['REACT_APP_OKTA_DOMAIN']    ? window['REACT_APP_OKTA_DOMAIN']   : (process.env.REACT_APP_OKTA_DOMAIN   ? process.env.REACT_APP_OKTA_DOMAIN   : 'OKTA_DOMAIN_UNDEFINED')

// 0oa14wsarj3re5Wk30h8
// smileco.oktapreview.com

const oktaAuthConfig:OktaAuthOptions = {
    issuer: `https://${OKTA_DOMAIN}`,
    clientId: OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/logincallback',
    responseMode: 'fragment',
    tokenManager: {  // https://support.okta.com/help/s/article/How-does-the-Auto-Renewal-of-tokens-work-in-Auth-JS?language=en_US 
        storage: 'localStorage',//sessionStorage',
        autoRenew: true,
    },
    pkce: true,
}

export { oktaAuthConfig }
