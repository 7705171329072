import { Button, Modal } from "@mui/material"
import { Box } from "@mui/system"
import { TWorkflowSetErrorPayload } from "../../Types/Store/StoreWorkflow"


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: '0 0  150px #000',
    pt: 2,
    px: 4,
    pb: 3,
}

export type TErrorModalProps = {
    header?: string
    error: TWorkflowSetErrorPayload
    errorControl: (isNeedToShow: boolean) => void
}

const ErrorModal = ( props:TErrorModalProps ) => {
    const { header, error, errorControl } = props

    const handleClose = () => {
        errorControl(false)
    }

    return (
        <Modal
            hideBackdrop
            open={error.needToShow}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...style }} className = {'text-overflow-clip'}>
                <h2 style={{
                    margin: '0 0 30px 0',
                }}>
                    {
                        header ? header : 'Case Workflow Rule Error'
                    }
                </h2>
                
                <p>
                    <strong> Status: </strong> { error.status }
                </p>

                <p>
                    <strong> Path: </strong> { error.path }
                </p>

                <p style={{margin: '20px 0 0 0'}} className = {'text-overflow-clip'}>
                    <strong> Message:</strong> { error.message }
                </p>

                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button  variant="outlined" onClick={handleClose}>
                        Ok
                    </Button>
                </Box>
            </Box>
        </Modal>

    )
}

export default ErrorModal
