import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Dispatch, SetStateAction } from 'react'
import MenuIcon from './MenuIcon'
import { NavLink } from 'react-router-dom'

import { menuConfig } from './menuConfig'
import colorLibrary from '../../Utils/colors'
import AppVersion from '../AppVersion'
import { useOutsideClick } from './useOutsideClick'


export type TSideMenuProps = {
    isOpened: boolean
    setOpened: Dispatch<SetStateAction<boolean>>
}


const SideMenu = (props: TSideMenuProps) => {

    const {
        isOpened,
        setOpened
    } = props
    
    const onMenuItemClick = () =>{
        setOpened(false)
    }

    const menuRef = useOutsideClick(onMenuItemClick)

    return (

        
        <Box
            ref = { menuRef }
            key = {'side-menu-box'}
            sx  = {{
                display: 'flex',
                position: 'fixed',
                top: 0,
                left: 0,
                height: 'calc( 100vh - 64px )',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                margin: '64px 0 0 0',
                whiteSpace: 'nowrap',
                transition: 'all 0.25s ease-out',
                transform: `translateX(${   isOpened ? 0 : '-255px' })`,
                borderRight: `2px solid ${colorLibrary.backgroundColor}`,
                backgroundColor: '#fff',
                boxShadow: `0 0  ${ isOpened ? 250 : 0}px #00000088`,
                zIndex: 10,
            }}
        >
            <List
                component="nav"
                key={'side-menu-list-nav'}
            >
                {
                    menuConfig.map(( menuConfigItem, menuItemindex )=>{

                        return(
                            
                            <Box key={`box-key-${menuItemindex}`}>
                                {
                                    menuConfigItem.linkType === 'INTERNAL' &&
                                    <NavLink
                                        to= { menuConfigItem.linkTo }  
                                        className={ ({isActive})=> isActive ? 'list-item-active' : ''}
                                        style={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                borderBottom: '1px solid #cfcfcf'
                                            }}
                                            onClick = {onMenuItemClick}
                                        >
                                            <ListItemIcon>
                                                <MenuIcon
                                                    path={ menuConfigItem.icon } 
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={ menuConfigItem.name } 
                                            />

                                        </ListItemButton>
                                    </NavLink>
                                }

                                {
                                    menuConfigItem.linkType === 'EXTERNAL' &&
                                    <a
                                        href={menuConfigItem.linkTo}
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <ListItemButton
                                            sx={{
                                                borderBottom: '1px solid #cfcfcf'
                                            }}
                                            onClick = {onMenuItemClick}
                                        >
                                            <ListItemIcon>
                                                <MenuIcon
                                                    path={ menuConfigItem.icon } 
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={ menuConfigItem.name } 
                                            />

                                        </ListItemButton>
                                    </a>
                                }
                                
                                {
                                    menuConfigItem.child.length !== 0 ?

                                        <List
                                            key={`child-list-key-${menuItemindex}`}
                                            component="div"
                                            sx={{ 
                                                padding: '0 0 0 20px',
                                                backgroundColor: '#ccc',
                                            }}
                                        >
                                            {
                                                menuConfigItem.child.map((childItem, childIndex)=>{

                                                    return(
                                                        <NavLink
                                                            key={`child-list-key-${menuItemindex}-${childIndex}`}
                                                            to= { childItem.linkTo }  
                                                            className={ ({isActive})=> isActive ? 'list-item-active' : ''}
                                                            style={{
                                                                textDecoration: 'none'
                                                            }}
                                                        >
                                                            <ListItemButton
                                                                sx={{
                                                                    borderLeft: '1px solid #cfcfcf',
                                                                    backgroundColor: '#eee',
                                                                }}
                                                                onClick = { onMenuItemClick }
                                                            >
                                                                <ListItemIcon>
                                                                    <MenuIcon
                                                                        path={ childItem.icon } 
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={ childItem.name } 
                                                                />
                                                            </ListItemButton>
                                                        </NavLink>
                                                    )
                                                })
                                            }
                                        </List>
                                    : null
                                    
                                }
                                    
                            </Box>

                        )
                    })
                }
            </List>
            <AppVersion />
        </Box>

    )
}

export default SideMenu
