import './preloader.sass'

const Preloader = () =>{
    return(
        <div className="preloader">
            
            <div className="logo-wrapper">
                <img
                    src="/images/logo-sdc.svg"
                    alt=""
                />
            </div>

            <div className="box">
                <div className="container">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
            </div>
        </div>
    )
}

export default Preloader
