import { useSearchParams } from "react-router-dom"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import useDebounce from "../../../Utils/use-input-debounce"

const useDebouncedFilterFields = () =>{
    let [ searchParams, setSearchParams ] = useSearchParams()
    const [
        filterCaseId,
        isFilterCaseIdValid,
        ,
        onFilterCaseIdChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED,enumFormValidationRule.UUID], 
            searchParams.get('cms_id') !== null ? 
                String(searchParams.get('cms_id')) 
            : ''
        )

    const [
        source,
        isSourceValid,
        ,
        onSourceChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('source') !== null ? 
                String(searchParams.get('source')) 
            : ''
        )

    const [
        externalId,
        isExternalIdValid,
        ,
        onExternalIdChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('external_id') !== null ?
                String(searchParams.get('external_id'))
            : ''
        )
    
        const [
            isMca,
            isMcaValid,
            ,
            onMcaChange
        ] = useValidation(
                [enumFormValidationRule.REQUIRED], 
                searchParams.get('is_mca') !== null ?
                    String(searchParams.get('is_mca'))
                : ''
            )

    const [
        locked,
        isLockedValid,
        ,
        onLockedChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED], 
            searchParams.get('locked') !== null ?
                String(searchParams.get('locked'))
            : ''
        )

    const [
        createdBy,
        isCreatedByValid,
        ,
        onCreatedByChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('created_by') !== null ?
                String(searchParams.get('created_by'))
            : ''
        )
    
    const [
        createdDate,
        ,
        ,
        onCreatedDateChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('created_date') !== null ?
                String(searchParams.get('created_date'))
            : ''
        )

    const [
        updatedDate,
        ,
        ,
        onUpdatedDateChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('updated_date') !== null ?
                String(searchParams.get('updated_date'))
            : ''
        )

    const [
        status,
        ,
        ,
        onStatusChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('status') !== null ?
                String(searchParams.get('status'))
            : ''
        )

    const debouncedFilterCaseId = useDebounce(filterCaseId, 500)
    const debouncedSource       = useDebounce(source      , 500)
    const debouncedExternalId   = useDebounce(externalId  , 500)
    const debouncedLocked       = useDebounce(locked      , 500)
    const debouncedCreatedBy    = useDebounce(createdBy   , 500)
    const debouncedCreatedDate  = useDebounce(createdDate , 500)
    const debouncedUpdatedDate  = useDebounce(updatedDate , 500)
    const debouncedStatus       = useDebounce(status      , 500)
    const debouncedisMca        = useDebounce(isMca       , 500)

    return({
        filterCaseId,
        source,
        externalId,
        isMca,
        locked,
        createdBy,
        createdDate,
        updatedDate,
        status,

        debouncedFilterCaseId,
        debouncedSource,
        debouncedExternalId,
        debouncedLocked,
        debouncedCreatedBy,
        debouncedCreatedDate,
        debouncedUpdatedDate,
        debouncedStatus,
        debouncedisMca,

        isFilterCaseIdValid, 
        isSourceValid, 
        isExternalIdValid, 
        isMcaValid, 
        isLockedValid, 
        isCreatedByValid, 

        onFilterCaseIdChange,
        onSourceChange,
        onExternalIdChange,
        onMcaChange,
        onLockedChange,
        onCreatedByChange,
        onCreatedDateChange,
        onUpdatedDateChange,
        onStatusChange,
    })
}

export default useDebouncedFilterFields
