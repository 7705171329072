import { enumSORT, enumSortingField } from "../../Types/General"
import { enumStoreCaseManagementUserHistoryAction, IStoreCaseManagementUserHistory, TCaseManagementUserHistoryStoreAction } from "../../Types/Store/StoreCaseManagement"

const initialState:IStoreCaseManagementUserHistory = {
    needUpdateListFromAPI: false,
    
    historyList: [],
    
    sorting: enumSORT.ASC,
    sortingField: enumSortingField.CREATED_AT,
    
    error:{
        needToShow: false,
        status    : '',
        path      : '',
        message   : '',
    },

    filter:{
        from: '',
        to: '',
        user: '',
    },

    pagination:{
        totalElements: 0,
        size  : 100,
        number: 0,
    },
}


export const caseManagementUserHistoryReducer = ( 

    state = initialState,
    action : TCaseManagementUserHistoryStoreAction

) : IStoreCaseManagementUserHistory => {
    
    switch(action.type){
        case enumStoreCaseManagementUserHistoryAction.SET_CMUH_NEED_UPDATE_FROM_API :
            return ({
                ...state,
                needUpdateListFromAPI: action.payload,
            })
            
        case  enumStoreCaseManagementUserHistoryAction.SET_CMUH_SET_PAGINATION :            
            return ({
                ...state,
                pagination: action.payload,
            })
        case  enumStoreCaseManagementUserHistoryAction.SET_CMUH_FILTER_DATA :            
            return ({
                ...state,
                filter: { ...action.payload },
            })

        case  enumStoreCaseManagementUserHistoryAction.SET_CMUH_HISTORY_LIST :            
            return ({
                ...state,
                historyList: action.payload,
            })
        
        case  enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR :            
            return ({
                ...state,
                error:{
                    ...state.error,
                    ...action.payload
                }
            })

        case enumStoreCaseManagementUserHistoryAction.SET_CMUH_DIRECTION :
            return({
                ...state,
                sorting: action.payload
            })

        default:
            return state
    }
}
