import { useState } from 'react' 
import { Box } from "@mui/material"
import { TProcessInstanceActivityDescription, TProcessVariableDescription } from "../../../../../Types/Store/StoreCaseManagement"
import ActivityHeader from "./ActivityHeader"
import ActivityInfo from './ActivityInfo'
import { findChildren } from '../ProcessInstanceActivities'
import ProcessVariables from '../ProcessVariables'
import { getActivityVariables } from '../ProcessInstance'
type TProcessInstanceActivityItemProps = {
    level                 :number
    activityDescription   : TProcessInstanceActivityDescription
    childrenActivities    : TProcessInstanceActivityDescription[]
    allAvalableActivities : TProcessInstanceActivityDescription[]
    allAvalableVariables  : TProcessVariableDescription[]
}

const ProcessInstanceActivityItem = (props:TProcessInstanceActivityItemProps) =>{

    const { 
        level,
        activityDescription,
        childrenActivities,
        allAvalableActivities,
        allAvalableVariables,
    } = props

    const [isExpanded, setExpanded] = useState(false)


    return(
        <Box
        
            sx={{
                width: '100%',
                // border: '2px solid #000',
                borderRadius: `10px`,
                borderBottom: '1px solid #888',
                borderRight: '1px solid #888',
                margin: '12px 4px',
                padding: '6px',
                backgroundColor: `#0000ff${((level+1)*16).toString(16).padStart(2,'0')}`,
            }}
        >
            <ActivityHeader
                activityDescription = { activityDescription }
                isExpanded          = { isExpanded          }
                setExpanded         = { setExpanded         }
                level               = { level               }
            />
            {
                isExpanded &&
                <>
                    <ActivityInfo
                        activityDescription = { activityDescription }
                    />
                    <ProcessVariables
                        level={level}
                        variables={  getActivityVariables( activityDescription.id, allAvalableVariables) }
                    />
                </>
            }

            {
                childrenActivities.length > 0 &&
                childrenActivities.map((childItem,childItemIndex)=>{
                    return(
                        <ProcessInstanceActivityItem
                            key = { `activityDescItem-${childItemIndex}-${childItem.activityId}` }
                            level                 = { level + 1                                        }
                            activityDescription   = { childItem                                        }
                            childrenActivities    = { findChildren(childItem.id,allAvalableActivities) }
                            allAvalableActivities = { allAvalableActivities                            }
                            allAvalableVariables  = { allAvalableVariables                             }
                        />
                    )
                })
            }

        </Box>
    )
} 

export default ProcessInstanceActivityItem
