import { Dispatch } from "redux"
import { 
    enumStoreBaseAction, 
    TBaseActionSetUserInfoFromAPI 
} from "../../Types/Store/StoreBase"


export const setBaseUserInfoFromAPI = ( userEmail: string ) =>{
    return((dispatch: Dispatch<TBaseActionSetUserInfoFromAPI>) =>{
        dispatch({
            type: enumStoreBaseAction.SET_BASE_USER_INFO_FROM_API,
            payload: userEmail
        })
    })
}
