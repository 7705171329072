const formatDateToLocal = (dateString: string):string => {
    
    const date = new Date(dateString)
    if( isNaN(date.getTime()) === true ){
        return('')
    }
    
    let formatter = new Intl.DateTimeFormat(
        window.navigator.language,
        {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
        }
    )
    return(formatter.format(date))
} 

export default formatDateToLocal
type TDateTemplateWithLocale = [string, string, string[]]

export const dateTemplate:TDateTemplateWithLocale[] = [
    [ 'MM/dd/yyyy', '__/__/____', [ 'en-US' ] ],
    [ 'dd/MM/yyyy', '__/__/____', [ 'es-CR','en-GB' ] ],
    [ 'dd.MM.yyyy', '__.__.____', [ 'ru-RU','fr_FR' ] ],
    [ 'yyyy-MM-dd', '__-__-____', [ 'pl-PL','en-CA','cs-CZ', ] ],
]

export const getDateInputTemplate = () =>{
    const currentLocale = window.navigator.language
    
    let result = [ 'dd/MM/yyyy', '__/__/____' ]
    for(let i = 0; i<dateTemplate.length; i++){
        if(dateTemplate[i][2].indexOf(currentLocale)>-1){
            result = [ dateTemplate[i][0], dateTemplate[i][1] ]
        }
    }

    return result
}
