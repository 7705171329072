import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from "@mui/material"
import AceEditor from "react-ace"
import { TCaseFileDescription } from "../../../../Types/CaseManagement"
import { useEffect, useState } from "react"
import getUrlForCaseFile from "../../../../Api/CaseManagement/v2/get-url-for-case-file"
import convertS3keyToPath from "./Utils/convertS3keyToPath"

type TFilesToolbarFileViewerProps = {
    open: boolean
    onClose: ()=>void
    fileDescriptionToView: TCaseFileDescription | undefined
}

export const detectFileExtensionFromPath = (filePath:string[], fileExtension: string[] ) =>{
    const fileName = filePath[filePath.length-1]
    let extensionIndex = -1
    for(let i = 0; i<fileExtension.length; i++){
        if(extensionIndex ===-1){
            if(fileName.indexOf(fileExtension[i])>-1){
                extensionIndex = i
            } 
        }
    }
    return extensionIndex > -1 ? fileExtension[extensionIndex] : undefined
}

const getFileContentFromS3 = async (fileDescriptionToView:TCaseFileDescription) =>{
    try{
        const fileLinkUrlResponse = await getUrlForCaseFile({
            id: fileDescriptionToView.caseId,
            s3key: fileDescriptionToView.s3key,
            attachment: true
        })

        const fileContentResponse = await fetch(fileLinkUrlResponse.data.fileLink)
        const fileContent = await fileContentResponse.blob()
        return(await fileContent.text())

    } catch(error){
        console.log('CANT DOWNLOAD FILE FROM S3', JSON.stringify(error))
        return(undefined)
    }
}


const FilesToolbarFileViewer = (props: TFilesToolbarFileViewerProps) =>{
    
    const {
        onClose,
        open,
        fileDescriptionToView
    } = props
    
    const [ isFileLoaded, setFileLoaded ] = useState(false)
    const [ fileContent , setFileContent] = useState<string|undefined>(undefined)

    useEffect(()=>{
        if(isFileLoaded === false && fileDescriptionToView){

            const detectedImageType = detectFileExtensionFromPath(convertS3keyToPath(fileDescriptionToView.s3key),['.png', '.jpg' ])
            const detectedTextType  = detectFileExtensionFromPath(convertS3keyToPath(fileDescriptionToView.s3key),['.log', '.json'])
            
            if(detectedTextType){
                getFileContentFromS3(fileDescriptionToView)
                .then(fileContent =>{
                    setFileContent(fileContent)
                })
                .catch(e =>{
                    console.log(' CANT GET FILE CONTENT')
                })
                .finally(()=>{
                    setFileLoaded(true)
                })
            }

            if(detectedImageType){
                getUrlForCaseFile({
                    id: fileDescriptionToView.caseId,
                    s3key: fileDescriptionToView.s3key,
                    attachment: true
                })
                .then(response =>{
                    setFileContent(response.data.fileLink)
                })
                .catch(e =>{
                    console.log(' CANT GET FILE LINK')
                })
                .finally(()=>{
                    setFileLoaded(true)
                })
            }
        }
    },[isFileLoaded, fileDescriptionToView])


    const onCloseFileViewer =  () =>{
        onClose()
        setFileLoaded(false)
        setFileContent(undefined)
    }

    let viewerMode = 'text'
    if(fileDescriptionToView?.s3key){
        
        if(detectFileExtensionFromPath(convertS3keyToPath(fileDescriptionToView.s3key),['.log', '.json']) === '.json'){
            viewerMode = 'json'
        }
        const detectedImageType = detectFileExtensionFromPath(convertS3keyToPath(fileDescriptionToView.s3key),['.png', '.jpg' ])
        if(detectedImageType){
            viewerMode = detectedImageType.slice(1, detectedImageType.length)
        } 
    }
    
    return(
        <Dialog 
            open={open} 
            onClose={onCloseFileViewer}
            maxWidth={'lg'}
            fullWidth
            sx={{
                overflow: 'hidden'
            }}
        >

            <DialogTitle> File: { fileDescriptionToView?.fileName } </DialogTitle>

            <DialogContent>

                <Box sx={{ 
                    margin: '20px 0 20px 0',
                }}>

                    <Box sx={{
                        margin: `20px 24px 0 0`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '70vh',
                        overflowY: 'hidden'
                    }}>
                        
                        {
                            (isFileLoaded === false && typeof(fileContent) === 'undefined')&&
                            <CircularProgress/>
                        }

                        {
                            (isFileLoaded === true && typeof(fileContent) === 'undefined')&&
                            <>File was not loaded </>
                        }

                        {
                            (isFileLoaded === true && typeof(fileContent) !== 'undefined' && (viewerMode==='text' || viewerMode ==='json'))&&
                            <AceEditor
                                mode={ viewerMode }
                                theme="eclipse"
                                onChange={() => { /* do nothing */ }}
                                value={ fileContent }
                                editorProps={{ 
                                    $blockScrolling: true,
                                }}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    border: '1px solid grey',
                                }}
                                setOptions={{
                                    useWorker: false
                                }}
                            />
                        }

                        {
                            
                            (isFileLoaded === true && typeof(fileContent) !== 'undefined' && (viewerMode==='png' || viewerMode==='jpg'))&&
                            <img
                                alt={ fileDescriptionToView?.fileName }
                                src={ fileContent }
                                style={{
                                    width: 'auto',
                                    maxHeight: '60vh', 
                                }}
                            />
                        }
                    </Box>


                </Box>

                <DialogActions>
                    
                    <Button 
                        onClick  = { onCloseFileViewer }
                        variant  = { 'outlined' }
                    >
                        Close
                    </Button>

                </DialogActions>

            </DialogContent>

        </Dialog>
    )
}

export default FilesToolbarFileViewer
