import { AxiosResponse } from "axios"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Application%20configuration%20API/get

export type TApiGetApplicationConfigByNameAndVersionParams = {
    appName: string
    appVersion: string 
}

export type TApiGetApplicationConfigByNameAndVersionResponse = {

    application: string
    appVersion : string
    createdAt  : string
    updatedAt  : string
    version    : number
    data       : Object

}

const getApplicationConfigByNameAndVersion = async ( params: TApiGetApplicationConfigByNameAndVersionParams): Promise<AxiosResponse<TApiGetApplicationConfigByNameAndVersionResponse>>  =>{
    return await API.get(
        `/v2/configurations/applications/${params.appName}/${params.appVersion}`
    )
}

export default getApplicationConfigByNameAndVersion
