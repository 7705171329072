import { Box } from "@mui/material"
import { TMtpCase } from "../../../Types/Store/StoreMtp"
import formatDateToLocal from "../../../Utils/format-date-to-local"

export type TMTPCaseViewProps = {
    mtpCase: TMtpCase
}

const metaHeaderStyle = {
    fontSize: '12px', 
    fontWeight: 'bold', 
    margin: '0 20px 6px 0',
    minWidth: '80px',
}

const metaValueStyle = {
    fontSize: '12px', 
}

const flexStyle = {
    display: 'flex'
}

const MTPCaseView = (props: TMTPCaseViewProps) =>{

    const { mtpCase } = props

    return(
        <Box>
            <Box sx={flexStyle}>
                <div style={metaHeaderStyle}>
                    ID
                </div>
                <div style={metaValueStyle}>
                    { mtpCase.id }  
                </div>
            </Box>

            <Box sx={flexStyle}>
                <div style={metaHeaderStyle}>
                    Created At
                </div>
                <div style={metaValueStyle}>
                    { formatDateToLocal(mtpCase.createdAt) }
                </div>
            </Box>

            <Box sx={flexStyle}>
                <div style={metaHeaderStyle}>
                    CompletedAt
                </div>
                <div style={metaValueStyle}>
                    { formatDateToLocal(mtpCase.completedAt) }
                </div>
            </Box>

            <Box sx={flexStyle}>
                <div style={metaHeaderStyle}>
                    Status
                </div>
                <div style={metaValueStyle}>
                    { mtpCase.status }
                </div>
            </Box>

            <Box sx={flexStyle}>
                <div style={metaHeaderStyle}>
                    SMO Version
                </div>
                <div style={metaValueStyle}>
                    { mtpCase.smoVersion }
                </div>
            </Box>
        </Box>
    )
}

export default MTPCaseView
