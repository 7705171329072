import { TApiGetCasesByFilterParams } from "../../Api/CaseManagement/v2/get-cases-by-filter"
import { THistoryRecord } from "../CaseManagement"
import { enumSORT, enumSortingField } from "../General"


export enum enumStoreCaseManagementAction {
    SET_CM_NEED_UPDATE_GROUPS_FROM_API = 'SET_CM_NEED_UPDATE_GROUPS_FROM_API',
    SET_CM_ERROR                       = 'SET_CM_ERROR',
    SET_CM_ERROR_NEED_TO_SHOW          = 'SET_CM_ERROR_NEED_TO_SHOW',

    SET_CM_CASES                       = 'SET_CM_CASES',

    SET_CM_PAGE_NUMBER                 = 'SET_CM_PAGE_NUMBER',
    SET_CM_PAGE_SIZE                   = 'SET_CM_PAGE_SIZE',
    SET_CM_FILTER_DATA                 = 'SET_CM_FILTER_DATA',
}

export enum enumStoreCaseManagementUserHistoryAction {
    SET_CMUH_HISTORY_LIST         = 'SET_CMUH_HISTORY_LIST',

    SET_CMUH_NEED_UPDATE_FROM_API = 'SET_CMUH_NEED_UPDATE_FROM_API',
    SET_CMUH_SET_PAGINATION       = 'SET_CMUH_SET_PAGINATION',
    SET_CMUH_FILTER_DATA      = 'SET_CMUH_SET_FILTER_DATA',
    SET_CMUH_DIRECTION        = 'SET_CMUH_DIRECTION',
    
    SET_CMUH_ERROR                = 'SET_CM_ERROR',
}

export type TCase = {
    id                       : string
    externalId               : string
    createdBy                : string
    source                   : string
    version                  : number
    createdAt                : string
    updatedAt                : string
    medicalRecordNumber      : string
    status                   : string
    locked                   : boolean
    lockedBy?                : string 
    lockedAt?                : string 
    smoVersion?              : string
    smoObjExportPath?        : string
    treatmentOption?         : string
    tags?                    : string[]
    previousCaseId?          : string
    externalTreatmentPlanId? : string
    automatedMildCase?       : boolean
}

export type TPagination = {
    totalElements: number
    size  : number
    number: number
}

export type TCaseManagementFilterData = {
    filterId  :enumSortingField
    value     : string
    sorting   : enumSORT,
    pagination: TPagination
}

export type TCaseManagementSortingBy = 'CREATED_BY' | 'DATE_CREATED' | 'DATE_UPDATED'

export interface IStoreCaseManagement {
    needUpdateListFromAPI: boolean
    list: TCase[]

    filterData: TApiGetCasesByFilterParams

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}

export type TCaseManagementStoreAction =    TCaseManagementSetNeedUpdateGroupsFromAPI |
                                            TCaseManagementSetError |
                                            TCaseManagementSetErrorNeedToShow |
                                            TCaseManagementSetCasesV2 |
                                            TCaseManagementSetPageNumber |
                                            TCaseManagementSetPageSize |
                                            TCaseManagementSetFilterData

export type TCaseManagementSetNeedUpdateGroupsFromAPI = {
    type: enumStoreCaseManagementAction.SET_CM_NEED_UPDATE_GROUPS_FROM_API
    payload: boolean
}


export type TCaseManagementSetSortingPayload = {
    sortingField: enumSortingField
    sorting: enumSORT
}



export type TCaseManagementSetCasesV2 = {
    type: enumStoreCaseManagementAction.SET_CM_CASES
    payload: TCase[]
}

export type TCaseManagementSetErrorPayload = {
    status    : string
    message   : string
    path      : string
}

export type TCaseManagementSetError = {
    type: enumStoreCaseManagementAction.SET_CM_ERROR,
    payload: TCaseManagementSetErrorPayload
}

export type TCaseManagementSetErrorNeedToShow = {
    type: enumStoreCaseManagementAction.SET_CM_ERROR_NEED_TO_SHOW,
    payload: boolean
}

export type TCaseManagementUserHistoryStoreAction = TCaseManagementUserHistorySetNeedUpdateFromAPI |  
                                                    TCaseManagementUserHistorySetPagination |
                                                    TCaseManagementUserHistorySetError |
                                                    TCaseManagementUserHistoryListAction |
                                                    TCaseManagementUserHistorySetFilter |
                                                    TCaseManagementUserHistorySetSortingDirectionAction


export type TCaseManagementUserHistorySetPagination = {
    type    : enumStoreCaseManagementUserHistoryAction.SET_CMUH_SET_PAGINATION,
    payload : TPagination
}

export type TCaseManagementUserHistorySetFilter = {
    type    : enumStoreCaseManagementUserHistoryAction.SET_CMUH_FILTER_DATA,
    payload : {
        user : string
        from : string
        to : string
    }
}

export type TCaseManagementUserHistorySetNeedUpdateFromAPI = {
    type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_NEED_UPDATE_FROM_API
    payload: boolean
}

export type TCaseManagementUserHistorySetError = {
    type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR,
    payload: TCaseManagementSetErrorPayload
}

export type TCaseManagementUserHistoryListAction = {
    type:enumStoreCaseManagementUserHistoryAction.SET_CMUH_HISTORY_LIST,
    payload: THistoryRecord[]
}

export type TCaseManagementUserHistorySetSortingDirectionAction = {
    type:enumStoreCaseManagementUserHistoryAction.SET_CMUH_DIRECTION,
    payload: enumSORT
}
export interface IStoreCaseManagementUserHistory {
    
    needUpdateListFromAPI: boolean
    pagination: TPagination
    historyList: THistoryRecord[]
    
    sorting: enumSORT
    sortingField: enumSortingField,

    filter:{
        user : string
        from : string
        to : string
    },

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}

export type TCaseLockDescription = {
    caseId   : string
    createdBy: string
    createdAt: string
}

export type TCaseManagementSetPageNumber = {
    type    : enumStoreCaseManagementAction.SET_CM_PAGE_NUMBER
    payload : number
}

export type TCaseManagementSetPageSize = {
    type    : enumStoreCaseManagementAction.SET_CM_PAGE_SIZE
    payload : number
}

export type TCaseManagementSetFilterData = {
    type : enumStoreCaseManagementAction.SET_CM_FILTER_DATA
    payload: TApiGetCasesByFilterParams
}

export type TProcessInstanceDescription = {
    id                       : string  // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    businessKey              : string  // null,
    processDefinitionId      : string  // "pre-setup-automation:14:6f35323b-4710-11ee-b362-0a58a9feacb5",
    processDefinitionKey     : string  // "pre-setup-automation",
    processDefinitionName    : string  // "Pre-setup Automation",
    processDefinitionVersion : number  // 14,
    startTime                : string  // "2023-08-30T08:49:09.055+0000",
    endTime                  : string | null  // "2023-08-30T08:51:50.364+0000",
    removalTime              : string  // "2023-09-29T08:51:50.364+0000",
    durationInMillis         : number | null // 161309,
    startUserId              : string  // null,
    startActivityId          : string  // "Event_0irkk2w",
    deleteReason             : string  // null,
    rootProcessInstanceId    : string  // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    superProcessInstanceId   : string  // null,
    superCaseInstanceId      : string  // null,
    caseInstanceId           : string  // null,
    tenantId                 : string  // null,
    state                    : string  // "COMPLETED"
}

export type TProcessInstanceActivityDescription = {
    id                       :  string        // "Event_0irkk2w:157d23ee-4712-11ee-a069-0a58a9feacbe",
    parentActivityInstanceId :  string        // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    activityId               :  string        // "Event_0irkk2w",
    activityName             :  string        // "Start Pre-setup automation",
    activityType             :  string        // "messageStartEvent",
    processDefinitionKey     :  string        // "pre-setup-automation",
    processDefinitionId      :  string        // "pre-setup-automation:14:6f35323b-4710-11ee-b362-0a58a9feacb5",
    processInstanceId        :  string        // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    executionId              :  string        // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    taskId                   :  string | null // null,
    calledProcessInstanceId  :  string | null // null,
    calledCaseInstanceId     :  string | null // null,
    assignee                 :  string | null // null,
    startTime                :  string        // "2023-08-30T08:49:09.057+0000",
    endTime                  :  string        // "2023-08-30T08:49:09.057+0000",
    durationInMillis         :  number        // 0,
    canceled                 :  boolean       // false,
    completeScope            :  boolean       // false,
    tenantId                 :  string | null // null,
    removalTime              :  string        // "2023-09-29T08:51:50.364+0000",
    rootProcessInstanceId    :  string        // "157cd5c4-4712-11ee-a069-0a58a9feacbe"
}

export type TProcessVariableDescription = {
    type                  : string          // "variableUpdate",
    id                    : string          // "157cfcdb-4712-11ee-a069-0a58a9feacbe",
    processDefinitionKey  : string          // "pre-setup-automation",
    processDefinitionId   : string          // "pre-setup-automation:14:6f35323b-4710-11ee-b362-0a58a9feacb5",
    processInstanceId     : string          // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    activityInstanceId    : string          // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    executionId           : string          // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    caseDefinitionKey     : string | null   // null,
    caseDefinitionId      : string | null   // null,
    caseInstanceId        : string | null   // null,
    caseExecutionId       : string | null   // null,
    taskId                : string | null   // null,
    tenantId              : string | null   // null,
    userOperationId       : string | null   // null,
    time                  : string          // "2023-08-30T08:49:09.056+0000",
    removalTime           : string          // "2023-09-29T08:51:50.364+0000",
    rootProcessInstanceId : string          // "157cd5c4-4712-11ee-a069-0a58a9feacbe",
    variableName          : string          // "mrn",
    variableInstanceId    : string          // "157cfcda-4712-11ee-a069-0a58a9feacbe",
    variableType          : string          // "String",
    value                 : string | object // "545994893",
    valueInfo             : object          // {},
    initial               : boolean         // true,
    revision              : number          // 0,
    errorMessage          : string | null   // null
}
