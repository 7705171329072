import { 
    mdiApplicationCogOutline, 
    mdiTooth, 
    mdiBriefcaseCheck,
    mdiTransitConnectionVariant,
    mdiAccountGroup,
    mdiAccountClock,
    mdiCardAccountDetails,
    mdiAlphaCCircleOutline,
} from '@mdi/js'


export type TMenuItemDescripion = {
    name       : string
    moduleType : TTPModuleType
    icon       : string
    child      : TMenuItemDescripion[]
    linkTo     : string
    linkType   : 'INTERNAL'| 'EXTERNAL'
}

export type TTPModuleType = 
    `CASE${
        'S' |
        '_STATUS' |
        '_WORKFLOW' |
        '_STATUS_TRANSITIONS'
    }` |
    `DESKTOP_CONFIGURATION` |
    'GROUPS' |
    'MTP' |
    'CAMUNDA_DASHBOARD'

export const menuConfig:TMenuItemDescripion[] = [
    {
        name: 'Case Management',
        moduleType: 'CASES',
        icon: mdiTooth,
        linkType: 'INTERNAL',
        linkTo:  '/',
        child:[
            {
                name: 'Workflow',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiBriefcaseCheck,
                linkType: 'INTERNAL',
                linkTo:  'case-management/workflow',
                child:[]
            },
            {
                name: 'Status Transitions',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiTransitConnectionVariant ,
                linkType: 'INTERNAL',
                linkTo:  'case-management/case-status-transitions',
                child:[]
            },
            {
                name: 'User History',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiAccountClock ,
                linkType: 'INTERNAL',
                linkTo:  'case-management/user-history',
                child:[]
            },
        ]
    },
    {
        name: 'Mobile Treatment Plan',
        moduleType: 'MTP',
        icon: mdiCardAccountDetails,
        linkType: 'INTERNAL',
        linkTo:  'mobile-treatment-plan',
        child:[]
    },
    {
        name: 'Desktop Configuration',
        moduleType: 'DESKTOP_CONFIGURATION',
        icon: mdiApplicationCogOutline,
        linkType: 'INTERNAL',
        linkTo:  'desktop-configuration',
        child:[]
    },
    {
        name: 'Groups',
        moduleType: 'GROUPS',
        icon: mdiAccountGroup,
        linkType: 'INTERNAL',
        linkTo:  'groups',
        child:[]
    },
    {
        name: 'Camunda Dashboard',
        moduleType: 'CAMUNDA_DASHBOARD',
        icon: mdiAlphaCCircleOutline,
        linkType: 'EXTERNAL',
        linkTo:  `${window.REACT_APP_WORKFLOW_API_URL ? window.REACT_APP_WORKFLOW_API_URL : (process.env.REACT_APP_WORKFLOW_API_URL ? process.env.REACT_APP_WORKFLOW_API_URL : '')}/camunda/app/cockpit/default/#/processes` ,
        child:[]
    },
]



