import API from '../api-base'
import { AxiosResponse } from "axios"
import { TWorkflowConfiguration } from '../../../Types/Store/StoreWorkflow'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=Workflow%20service%20v1#/

type TGetConfidurationResponse = {
    items: TWorkflowConfiguration[]
}

const getConfigurations = async (  ): Promise<AxiosResponse<TGetConfidurationResponse>>  =>{
    return await API.get(
        `/api/configuration`
    )
}

export default getConfigurations
