import { AxiosResponse } from "axios"
import { enumApiParameter }      from "../../../Types/General"
import API from '../api-base'


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Group%20configuration%20API/delete_1

export type TApiDeleteGroupConfigParams = {
    [enumApiParameter.APPLICATION  ]  : string
    [enumApiParameter.VERSION      ]  : string
    [enumApiParameter.GROUP_ID     ]  : string
}

const deleteGroupConfig = async ( params: TApiDeleteGroupConfigParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/configurations/groups/${String(params[ enumApiParameter.APPLICATION]).toLowerCase()}/${params[ enumApiParameter.VERSION]}/${params[ enumApiParameter.GROUP_ID]}`
    )
}

export default deleteGroupConfig
