import { useState, useEffect } from 'react'
import { Box, SxProps, Theme } from "@mui/material"
import { TProcessInstanceDescription, TProcessVariableDescription } from "../../../../Types/Store/StoreCaseManagement"
import convertMsToHM from "../../../../Utils/convertMillisecondsToHMS"
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import formatDateToLocal from "../../../../Utils/format-date-to-local"
import ProcessInstanceActivities from './ProcessInstanceActivities'
import postReceiveProcessVariables from '../../../../Api/Workflow/Automation/post-receive-process-variables'
import ProcessVariables from './ProcessVariables'
import { WORKFLOW_API_URL } from '../../../../Api/Workflow/api-base'

export const processItemsParameterStyle:SxProps<Theme> = {
    margin: '4px 8px',
    fontWeight: 'bold'
}

export const processItemsInfoStyle:SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',

}

export  const processItemsInfoTitleStyle:SxProps<Theme> = {
    //fontWeight: 'bold',
    margin: '0 8px 0 0'
}

type TProcessInstanceProps = {
    processDesc: TProcessInstanceDescription
}

const getVariablesWithLatestRevision = (params:TProcessVariableDescription[]):TProcessVariableDescription[] => {

    const result:TProcessVariableDescription[] = []
    
    if(params.length > 0){

        const variablesNames = Array.from(new Set(params.map(item => item.variableName)))
    
        const variablesArrays:TProcessVariableDescription[][] = variablesNames
        .map(variableName => params.filter(variableDescription => variableDescription.variableName === variableName) )
        
        variablesArrays
        .forEach(variableArr => {
            const revisions = variableArr.map(variableItem => variableItem.revision)
            const maxRevision = Math.max(...revisions)
            const maxIndex = revisions.indexOf(maxRevision)
            result.push(variableArr[maxIndex])
        })
    }

    return result
}

export const getActivityVariables = (activityId:string, params:TProcessVariableDescription[]):TProcessVariableDescription[] => {
    const result:TProcessVariableDescription[] = []
    params.forEach(variableItem =>{
        if(activityId === variableItem.activityInstanceId){
            result.push(variableItem)
        }
    })
    return(result)
}
export const stillInProgress = 'Still in Progress'

const ProcessInstance = (props:TProcessInstanceProps) =>{

    const { processDesc } = props
    const [ isExpanded, setExpanded ] = useState(false)
    const [ isVariablesLoaded, setVariablesLoaded ] = useState(false)
    const [ processVariables, setProcessVariables ] = useState<TProcessVariableDescription[]>([])
    useEffect(()=>{
        if(isExpanded === true && isVariablesLoaded === false){
            postReceiveProcessVariables({
                processInstanceId: processDesc.id
            })
            .then((response)=>{
                setProcessVariables(response.data)
            })
            .catch((error)=>{
                console.log(error)
            })
            .finally(()=>{
                setVariablesLoaded(true)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isExpanded])

    return(
        <Box
            sx={{
                display:'flex',
                flexDirection: 'column',
                margin: '4px 0 0 0',
                
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#e7f8ff',
                    borderBottom: '2px solid #ddd',
                    borderRight: '2px solid #ddd',
                    borderRadius: '6px',
                    fontSize: '14px',
                    '&:hover':{
                        cursor: 'pointer',
                    },
                }}
                onClick={()=>{
                    setExpanded((prev=>{
                        if(prev===true){
                            setVariablesLoaded(false)
                            setProcessVariables([])
                        }
                        return(!prev)
                    }))
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        margin: '4px',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={processItemsParameterStyle}
                    >
                        { processDesc.processDefinitionName }
                    </Box>
                    | 
                    <Box
                        sx={processItemsParameterStyle}
                    >
                        { processDesc.state }
                    </Box>
                    |
                    <Box
                        sx={processItemsParameterStyle}
                    >
                        { processDesc.durationInMillis ? convertMsToHM( Number(processDesc.durationInMillis)) : stillInProgress }
                    </Box>
                </Box>

                <Box
                    component={ isExpanded=== false ? ControlPointIcon: RemoveCircleOutlineIcon}
                    sx={{
                        margin: '4px 8px',
                    }}
                />
            </Box>


            { //  ====================================== EXPANDABLE PART ====================================================
                isExpanded&&
                <Box
                    sx={{
                        display:'flex',
                        margin: '0 4px 4px 4px',
                        padding: '0 0 16px 32px',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        border: '2px solid #ddd',
                        borderTop: 'none',
                    }}
                >
                    <Box
                        sx={{...processItemsInfoStyle, 
                            margin:'16px 0 8px 0',
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }}
                    >
                        Process Instance Info
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > Process Instance       </Box>
                        <Box> { processDesc.id } </Box>
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > Process Definition       </Box>
                        <Box>
                            <a 
                                target= "_blank" 
                                rel   = "noreferrer"
                                href  = {`${WORKFLOW_API_URL}/camunda/app/cockpit/default/#/process-definition/${processDesc.processDefinitionId}`}
                            >
                                { processDesc.processDefinitionName }
                            </a> 
                        </Box>
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > Start Time       </Box>
                        <Box> { formatDateToLocal(processDesc.startTime) } </Box>
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > End Time       </Box>
                        <Box> { processDesc.endTime ? formatDateToLocal(processDesc.endTime) : stillInProgress  } </Box>
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > Duration       </Box>
                        <Box> { processDesc.durationInMillis ? convertMsToHM(processDesc.durationInMillis) : stillInProgress } </Box>
                    </Box>

                    <Box
                        sx={processItemsInfoStyle}
                    >
                        <Box
                            sx={processItemsInfoTitleStyle}
                        > State       </Box>
                        <Box> { processDesc.state } </Box>
                    </Box>

                    <ProcessVariables
                        level = {0}
                        variables={  getVariablesWithLatestRevision(processVariables) }
                    />

                    <ProcessInstanceActivities
                        processInstanceId={processDesc.id}
                        allAvalableVariables={processVariables}
                    />

                </Box>
            }
        </Box>
    )
}

export default ProcessInstance
