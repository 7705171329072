import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter, enumSORT } from "../../../Types/General"
import { TCaseManagementSortingBy } from "../../../Types/Store/StoreCaseManagement"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/exportCsv

export type TApiGetCasesExportCSVParams = {
    [enumApiParameter.ID               ]? : string
    [enumApiParameter.EXTERNAL_ID      ]? : string
    [enumApiParameter.SOURCE           ]? : string
    dateCreatedFrom                     ? : string
    dateCreatedTo                       ? : string
    dateUpdatedFrom                     ? : string
    dateUpdatedTo                       ? : string
    locked                              ? : string // boolean ?
    [enumApiParameter.CREATED_BY       ]? : string
    sortBy                              ? : TCaseManagementSortingBy
    [enumApiParameter.DIRECTION        ]? : enumSORT
}

const getCasesExportCSV = async ( params: TApiGetCasesExportCSVParams ): Promise<AxiosResponse<string>>  =>{

    return await API.get(
        `/v2/cases/export/csv`,
        { 
            params:{ ...params} 
        }
    )
}

export default getCasesExportCSV
