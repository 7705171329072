import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/Application%20configuration%20API/create_2

export type TApiPostCreateUpdateApplicationConfigParams = {
    [enumApiParameter.APPLICATION  ]  : string
    [enumApiParameter.VERSION      ]  : string
    [enumApiParameter.DATA         ]  : string
} // RESPONSES 204(no conent) 400 500

const postCreateApplicationConfig = async ( params: TApiPostCreateUpdateApplicationConfigParams ): Promise<AxiosResponse>  =>{
    return await API.post(

        `/v2/configurations/applications/${ String(params[enumApiParameter.APPLICATION]) }/${params[enumApiParameter.VERSION]}`,
        
        params.data
        
    )
}

export default postCreateApplicationConfig
