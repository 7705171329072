import { useEffect, useState } from 'react' 
import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import {
    Search as SearchIcon,
    Cancel as CancelIcon 
} from '@mui/icons-material'
import { useSearchParams } from "react-router-dom"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { useAction } from "../../../Store/Hook/use-action"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import useDebounce from "../../../Utils/use-input-debounce"
import { TStoreMTPFilterData } from '../../../Types/Store/StoreMtp'
import deepEqual from '../../../Utils/compare-objects'
import { DateRangePicker } from 'rsuite'
import { getDateInputTemplate } from '../../../Utils/format-date-to-local'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import isAfter from 'date-fns/isAfter'
import adjustDateRange from '../../../Utils/adjust-date-range'


type TMtpSearchField = 'STATUS' | 'INFO' | 'CREATED_DATE'

const textFieldStyle = {
    padding: '8px',
    'label':{
        margin: '8px 0 0 8px',
    }
}

type TMtpFilterPanelProps = {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const MtpFilterPanel = (props:TMtpFilterPanelProps ) =>{

    const { setLoading } = props
    let [searchParams, setSearchParams] = useSearchParams()

    const { filterData } = useTypedSelector( state => state.mtp.mtpCasesList )
    const { 
        setMtpNeedUpdateCasesListFromAPI,
        setMtpFilterData,
        setMtpListLastEvaluatedKeys,
        
    } = useAction()

    const [
        mtpStatus,
        isMtpStatusValid,
        ,
        onMtpStatusChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED], 
            searchParams.get('status') !== null ? 
                String(searchParams.get('status')) 
            : ''
        )

    const [
        mtpInfo,
        isMtpInfoValid,
        ,
        onMtpInfoChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED], 
            searchParams.get('info') !== null ? 
                String(searchParams.get('info')) 
            : ''
        )
    const [
        mtpCreatedDate,
        ,,
        onMtpCreatedDateChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED], 
            searchParams.get('created_date') !== null ? 
                String(searchParams.get('created_date')) 
            : ''
        )
    const debouncedMtpStatus   = useDebounce(mtpStatus     , 500)
    const debouncedMtpInfo     = useDebounce(mtpInfo       , 500)
    const debouncedCreatedDate = useDebounce(mtpCreatedDate, 500)
    const [ createdDateState, setCreatedState ] = useState('')

    useEffect(()=>{
        
        const filterDataNew = {
            status        : debouncedMtpStatus   ? debouncedMtpStatus   : '',
            info          : debouncedMtpInfo     ? debouncedMtpInfo     : '',
            'period.from' : debouncedCreatedDate ? debouncedCreatedDate.split('|')[0] : '',
            'period.to'   : debouncedCreatedDate ? debouncedCreatedDate.split('|')[1] : '',
        }

        const filterDataPrevious:Partial<TStoreMTPFilterData> = {...filterData }
        delete filterDataPrevious['page.number']
        delete filterDataPrevious['page.size']
        delete filterDataPrevious['direction']

        setMtpFilterData({
            ...filterData,
            "page.number": deepEqual(filterDataNew,filterDataPrevious) === true ? filterData['page.number'] : 0,
            status        : debouncedMtpStatus   ? debouncedMtpStatus   : '',
            info          : debouncedMtpInfo     ? debouncedMtpInfo     : '',
            'period.from' : debouncedCreatedDate ? debouncedCreatedDate.split('|')[0] : '',
            'period.to'   : debouncedCreatedDate ? debouncedCreatedDate.split('|')[1] : '',
        })
        
        setMtpListLastEvaluatedKeys([])
        setMtpNeedUpdateCasesListFromAPI(true)
        setLoading(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[
        debouncedMtpStatus,
        debouncedMtpInfo,
        debouncedCreatedDate,
    ])

    // ADD PARAMETERS FOR PAGINATION
    useEffect(()=>{
        const searchfields = ['page_size','direction']
        const isSearchParamsInsideURL = searchfields.filter(field => searchParams.has(field)).length > 0 
        if( isSearchParamsInsideURL === true ){
            searchParams.set('page_size'  , String(filterData['page.size'  ]) )
            searchParams.set('direction'  , String(filterData.direction     ) )
            setSearchParams(searchParams)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[filterData])

    const onChangeFilter = (filterField: TMtpSearchField, filterValue: string, isInit?: boolean) =>{

        switch (filterField) {
            
            case 'STATUS':
                onMtpStatusChange(filterValue, isInit)
                break

            case 'INFO':
                onMtpInfoChange( filterValue , isInit)
                break

            case 'CREATED_DATE':
                onMtpCreatedDateChange( filterValue , isInit)
                setCreatedState( filterValue ? filterValue : '')
                break

            default: break

        }
    }

    return(
        <Box sx={{
            margin: '0 8px 0 0',
            width: '360px',
            border: '2px solid #eee',
            borderRadius: '4px',
        }}>
            <Box
                sx={{
                    height: '38px',
                    backgroundColor: '#5700ff',
                    color: '#fff',
                    display: 'flex',
                    borderRadius: '4px',
                    alignItems: 'center'
                }}
            >
                <Box sx={{display: 'flex', margin: '0 0 0 8px'}}>
                    <SearchIcon />
                    <div>
                        Search by
                    </div>
                </Box>
            </Box>

            <TextField
                size='small'
                id      = "searchByStatus"
                label   = { 'Status' }
                type    = "text"
                variant = "outlined"
                value   = { 
                    mtpStatus
                }
                error={!isMtpStatusValid}
                onChange={(e)=>{
                    if(String(e.target.value).trim() === ''){
                        onChangeFilter('STATUS', '', true)
                    }else{
                        onChangeFilter('STATUS', String(e.target.value).trim(), false)
                    }
                }}
                fullWidth
                InputProps={{
                    endAdornment:
                        mtpStatus && 
                        <InputAdornment position="end">
                            <IconButton onClick={()=>{ onChangeFilter('STATUS', '', true) }} >
                                <CancelIcon/>
                            </IconButton>
                        </InputAdornment>
                }}
                sx={textFieldStyle}
            />

            <TextField
                size='small'
                id      = "searchByInfo"
                label   = { 'Info' }
                type    = "text"
                variant = "outlined"
                value   = { 
                    mtpInfo
                }
                error={!isMtpInfoValid}
                onChange={(e)=>{
                    if(String(e.target.value).trim() === ''){
                        onChangeFilter('INFO', '', true)
                    }else{
                        onChangeFilter('INFO', String(e.target.value).trim(), false)
                    }
                }}
                fullWidth
                InputProps={{
                    endAdornment:
                        mtpInfo && 
                        <InputAdornment position="end">
                            <IconButton onClick={()=>{ onChangeFilter('INFO', '', true) }} >
                                <CancelIcon/>
                            </IconButton>
                        </InputAdornment>
                }}
                sx={textFieldStyle}
            />

            <DateRangePicker
                placeholder="Created Date"
                format={ getDateInputTemplate()[0] }
                placement={'auto'}

                value = { createdDateState ? [ new Date( createdDateState.split('|')[0] ), new Date( createdDateState.split('|')[1] )] : null }
                
                onChange={(e: DateRange | null )=>{ 
                    if(e === null){
                        onChangeFilter('CREATED_DATE', '', true)
                    }else{
                        onChangeFilter('CREATED_DATE', adjustDateRange(e).map(item=>item.toISOString()).join('|'), false)
                    }
                }}
                
                style={{
                    width: '100%',
                    padding: '8px',
                    zIndex: 20,
                }}

                disabledDate={ date => isAfter(date, new Date())}
            />
        </Box>    
    )
}

export default MtpFilterPanel
