import { useEffect, useRef } from 'react'

export const useOutsideClick = (onClickOutsideHandler: () => void) => {

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutsideHandler()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        
        return () => { 
            document.removeEventListener('mousedown', handleClickOutside) 
        }

    }, [onClickOutsideHandler])

    return ref
}
