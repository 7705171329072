import { TCaseFileDescription } from "../../../../../Types/CaseManagement"
import { TNodeRecord } from "../CaseFiles"
import convertS3keyToPath from "./convertS3keyToPath"

const convertFilesToNodesRecords = (
    filesList:TCaseFileDescription[],
    newFolderPath?: string[][]
):TNodeRecord[] =>{

    const nodeRecords:TNodeRecord[] = filesList
    .map((fileItem)=>{
        return({
            path: convertS3keyToPath(fileItem.s3key),
            fileDescription: {
                ...fileItem
            }
        })
    })

    if(newFolderPath && newFolderPath.length > 0){
        for( let i = 0; i<newFolderPath.length; i++){
            if(newFolderPath[i].length > 0){
                nodeRecords.push({
                    path: newFolderPath[i],
                    fileDescription : undefined
                })
            }
        }
    }

    return(nodeRecords)
}

export default convertFilesToNodesRecords
