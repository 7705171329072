import { AxiosResponse } from "axios"
import API from '../api-base'
import { TProcessVariableDescription } from "../../../Types/Store/StoreCaseManagement"

type TParams = {
    processInstanceId: string | undefined
}

type TResonse = TProcessVariableDescription[]


const postReceiveProcessVariables = async ( params: TParams ): Promise<AxiosResponse<TResonse>>  =>{

    const { processInstanceId } = params

    const data = {
        "processInstanceId": processInstanceId,
        "variableUpdates": true
    }

    return await API.post(
        `/engine-rest/history/detail`,
    
        { 
            ...data
        }
    )
}

export default postReceiveProcessVariables