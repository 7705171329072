import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { Link } from "react-router-dom"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import formatDateToLocal from "../../../Utils/format-date-to-local"
import MTPCaseListTableHeader from "./MTPCaseListTableHeader"
import { tableHeaderDescription } from './mtpTableHeaderDescription'

type TMTPCaseListTableProps = {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const MTPCaseListTable = (props: TMTPCaseListTableProps) => {
    
    const { setLoading } = props
    const { mtpCasesList } = useTypedSelector(state => state.mtp)

    return(
        <Box>
            <Paper sx={{ width: '100%' }}>
                <TableContainer
                    sx={{ borderRadius: '4px'}}
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="small"
                    >
                        <MTPCaseListTableHeader setLoading={setLoading}/>

                        <TableBody>
                            {
                                mtpCasesList.list.map((item, itemIndex) =>{
                                    return(
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`mtp-table-row${itemIndex}`}
                                        >
                                            {
                                                tableHeaderDescription.map((headerItem, headerIndex)=>{
                                                    let result
                                                    
                                                    switch(headerItem.id){
                                                        case 'id':
                                                            result = 
                                                            <Link to={`/mobile-treatment-plan/${item.id}`}>
                                                                { item.id }
                                                            </Link> 
                                                            break
                                                        case 'status':
                                                            result = (item.status)
                                                            break
                                                        case 'createdAt':
                                                            result = formatDateToLocal(item.createdAt)
                                                            break
                                                        case 'completedAt':
                                                            result = formatDateToLocal(item.completedAt)
                                                            break
                                                        case 'selfUrl':
                                                            result = item.selfUrl
                                                            break
                                                        case 'info':
                                                            result = item.info
                                                            break
                                                        default: break
                                                    }

                                                    return(
                                                        <TableCell 
                                                            align={headerItem.align}
                                                            sx={{fontSize: '13px'}}
                                                            key={`case-management-table-row${itemIndex}-${headerIndex}`} 
                                                        > 
                                                            { result }
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default MTPCaseListTable
