import { TableCell, TableRow } from "@mui/material"
import { TCase } from "../../../../Types/Store/StoreCaseManagement"

type TCaseExternalTreatmentPlanIdProps = {
    caseToView: TCase
}

const CaseExternalTreatmentPlanId = (props:TCaseExternalTreatmentPlanIdProps) =>{
    
    const baseLinkUrl = window['REACT_APP_SMILECHECK_URL'] ? window['REACT_APP_SMILECHECK_URL'] : process.env.REACT_APP_SMILECHECK_URL

    const { caseToView } = props

    return(
        <TableRow
            hover
            key={'specialField-ExternalTreatmentPlanId'}
            sx={{
                maxWidth : '100%',
            }}
        >
            <TableCell
                align="right"
                sx={{ border: 'unset'}}
            >
                <strong>External Treatment Plan ID</strong>
            </TableCell>
            <TableCell
                align="left"
                sx={{ border: 'unset'}}
            >
                { 
                    caseToView.externalTreatmentPlanId&&
                    <a 
                        target="_blank"
                        rel="noreferrer" 
                        href={`${baseLinkUrl}${caseToView.externalTreatmentPlanId}`}
                    >
                        { caseToView.externalTreatmentPlanId }
                    </a>
                }
            </TableCell>
        </TableRow>
    )
}

export default CaseExternalTreatmentPlanId
