import { ITableHeaderDescription } from "../../../../Types/Components/table"
import { enumSortingField } from "../../../../Types/General"


const userTableHeaderDescription: ITableHeaderDescription[] = [
    {
        id: enumSortingField.LOGIN,
        numeric: false,
        label: 'Login',
        isSortable: true,
    },
    {
        id: enumSortingField.CREATED_AT,
        numeric: false,
        label: 'Created Date',
    },
    {
        id: enumSortingField.UPDATED_AT,
        numeric: false,
        label: 'Updated Date',
        isSortable: true,
    },
    {
        id: enumSortingField.VERSION,
        numeric: true,
        label: 'Version',
    },
]



const userGroupsTableHeaderDescription: ITableHeaderDescription[] = [
    

    {
        id: enumSortingField.GROUP_NAME,
        numeric: false,
        label: 'Group Name',
        isSortable: true,
    },
    {
        id: enumSortingField.GROUP_ID,
        numeric: false,
        label: 'Group Id',
    },
    {
        id: enumSortingField.VERSION,
        numeric: true,
        label: 'Version',
    },
    {
        id: enumSortingField.DESCRIPTION,
        numeric: false,
        label: 'Description',
    },

    {
        id: enumSortingField.CREATED_AT,
        numeric: false,
        label: 'Created Date',
    },
    {
        id: enumSortingField.UPDATED_AT,
        numeric: false,
        label: 'Updated Date',
    },
]

const applicationTableHeaderDescription: ITableHeaderDescription[] = [
    {
        id: enumSortingField.APPLICATION,
        numeric: false,
        label: 'Application',
        isSortable: true,
    },
    {
        id: enumSortingField.VERSION,
        numeric: true,
        label: 'Version',
    },
    {
        id: enumSortingField.ACTIONS,
        numeric: true,
        label: 'Actions',
    },
]

export {
    userTableHeaderDescription, 
    userGroupsTableHeaderDescription,
    applicationTableHeaderDescription,
}
