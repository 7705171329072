import { useState } from 'react'
import { Box } from "@mui/material"
import getCasesExportCSV, { TApiGetCasesExportCSVParams } from "../../../Api/CaseManagement/v2/get-cases-export-csv"
import { saveAs } from 'file-saver'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { buttonOutlinedStyle } from '../../../Utils/colors'

type TFilterPanelCSVExporterProps = TApiGetCasesExportCSVParams

const FilterPanelCSVExporter = (props: TFilterPanelCSVExporterProps) =>{

    const [ isDownloading, setDownloading ] = useState(false)

    const onClickHandler = ()=>{
        setDownloading(true)
        getCasesExportCSV({
            ...props
        })
        .then((response)=>{
            saveAs(
                new Blob(
                    [response.data],
                    {type: 'application/json'}
                ),
                `cases.csv`
            )
        })
        .catch((e)=>{
            console.log('CANT GET Export-CSV FROM API', JSON.stringify(e))
        })
        .finally(()=>{
            setDownloading(false)
        })
    }

    return(
        <Box
            onClick={onClickHandler}
            sx={{
                margin: '16px 0 0 10px',
            }}
        >
            <LoadingButton
                loading = { isDownloading }
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="outlined"
                sx     = { buttonOutlinedStyle }
            >
                Export to CSV
            </LoadingButton>
            
        </Box>
    )

}

export default FilterPanelCSVExporter

