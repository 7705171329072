import { AxiosResponse } from "axios"
import API from '../api-base'
import { THistoryRecord } from "../../../Types/CaseManagement"
import { enumApiParameter, enumSORT } from "../../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Events%20Logging%20API/getCaseLogs

export type TApiGetCaseHistoryParams = {
    [enumApiParameter.CASE_ID          ]  : string
    [enumApiParameter.DIRECTION        ]? : enumSORT
    [enumApiParameter.PAGE_NUMBER      ]? : number
    [enumApiParameter.PAGE_SIZE        ]? : number
}

export type TApiGetCaseHistoryResponse = THistoryRecord[]

const getCaseHistory = async ( requestParams: TApiGetCaseHistoryParams ): Promise<AxiosResponse<TApiGetCaseHistoryResponse>>  =>{
    
    const { caseId } = requestParams

    return await API.get(
        `/v2/cases/${caseId}/logs`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getCaseHistory
