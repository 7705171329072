import { AxiosResponse } from "axios"
import { enumApiParameter }            from "../../../Types/General"
import API from '../api-base'


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Application%20configuration%20API/delete_2

export type TApiDeleteApplicationConfigParams = {
    [enumApiParameter.APPLICATION  ]  : string
    [enumApiParameter.VERSION      ]  : string
}

const deleteApplicationConfig = async ( params: TApiDeleteApplicationConfigParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/v2/configurations/applications/${ String(params[ enumApiParameter.APPLICATION ]).toLowerCase() }/${params[ enumApiParameter.VERSION ]}`
    )
}

export default deleteApplicationConfig
