import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseFileLink } from "../../../Types/CaseManagement"
import { enumApiParameter } from "../../../Types/General"

export type TApiGetFileListByCaseIdParams = {
    [enumApiParameter.ID    ] : string
}

const getFileLinksByCaseId = async ( params: TApiGetFileListByCaseIdParams ): Promise<AxiosResponse<TCaseFileLink[]>>  =>{
    return await API.get(
        `/v2/cases/${params[enumApiParameter.ID]}/file-links`,
        {
            params:{
                [enumApiParameter.PAGE_SIZE]: 100,
            }
        }
    )
}

export default getFileLinksByCaseId
