import { FunctionComponent, useState, useEffect } from 'react'
import Layout from './Components/Layout'

import Groups               from './Pages/Groups'
import GroupItem            from './Pages/Groups/GroupItem'
import DesktopConfigurations from './Pages/DesktopConfigurations'

import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from './Security/okta-config'
import Error from './Pages/Error'
import { useAction } from './Store/Hook/use-action'
import getUserInfo from './Security/check-user'
import checkLocalToken from './Security/check-local-token'
import getTokensWithRedirect from './Security/get-tokens-with-redirect'
import getSavedUrlFromLocalStorage from './Utils/get-saved-url-from-local-storage'
import saveUrlToLocalStorage from './Utils/save-url-to-local-storage'
import clearLocalStorage from './Security/clear-local-storage'
import {
    useLocation, useNavigate,
} from "react-router-dom"

export const authClient = new OktaAuth(oktaAuthConfig)
authClient.start()

const  App:FunctionComponent = () => {

    const [               , setIsAuthenticated ] = useState( false )
    const [ errorDetected,  setErrorDetected   ] = useState( false )
    const [ errorCode,      setErrorCode       ] = useState( ''    )
    const [ errorSummary,   setErrorSummary    ] = useState( ''    )
    const { setBaseUserInfoFromAPI } = useAction()
    // const [ isNeedBackAction, setNeedBackAction] = useState( false )

    useEffect(()=>{

        if (authClient.isLoginRedirect()) {
            // Parse token from redirect url
            authClient.token.parseFromUrl()
            .then(data => {
                
                const { accessToken } = data.tokens
                // Store parsed token in Token Manager
                if(accessToken){
                    authClient.tokenManager.add('accessToken', accessToken)
                }

                const { idToken } = data.tokens
                // Store parsed token in Token Manager
                if(idToken){
                    authClient.tokenManager.add('idToken', idToken)
                    setBaseUserInfoFromAPI(idToken.claims.email ? idToken.claims.email : '')
                }

            }).catch((error)=>{
                console.log('PARSE_FROM_URL ERROR', JSON.stringify(error) )
                
                setErrorCode(error.errorCode)
                setErrorSummary(error.errorSummary)
            })

        } else {
            // Attempt to retrieve ID Token from Token Manager
            authClient.tokenManager.get('accessToken')
            .then(accessToken => {
                if (!accessToken) {
                    // You're not logged in, you need a sessionToken
                    authClient.token.getWithRedirect({
                        responseType: ['code','id_token'],
                    }).catch((error)=>{
                        console.log('GET TOKEN WITH REDIRECT ERROR', JSON.stringify(error))
                        setErrorCode(error.errorCode)
                        setErrorSummary(error.errorSummary)
                    })
                }
            })
            .catch(e=>{
                console.log('Access token was not retreived', e)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        
        if(errorCode!==''){
            checkLocalToken(setIsAuthenticated)
        }
        // Check access token on start
        authClient.tokenManager.getTokens()
        .then((Tokens) => {
            const { accessToken } = Tokens
            let isAccessExpired = false

            if(accessToken){
                isAccessExpired = authClient.tokenManager.hasExpired(accessToken)
            }
            // update access and id token
            if(isAccessExpired === true){

                console.log('ACCESS TOKEN EXPIRED, logout for better times')
                clearLocalStorage()
                window.location.reload()

            }else{
                getUserInfo(
                    setBaseUserInfoFromAPI,
                    setErrorCode,
                    setErrorSummary
                )
            }
        })
        .catch((e)=>{
            console.log('GET LOCAL TOKENS ERROR', JSON.stringify(e))
            getTokensWithRedirect(
                authClient,
                setErrorCode,
                setErrorSummary
            )
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 

    const history = useNavigate()
    const location = useLocation()

    useEffect(()=>{
        if(location.pathname === '/logincallback' && errorCode===''){

            if(errorDetected === false){
                const isBackAction = Boolean(localStorage.getItem('backAction'))
                if(isBackAction === false){
                    const savedUrl = getSavedUrlFromLocalStorage()
                    history( savedUrl ? savedUrl : '/')
                }
            }

        }else{
            
            if(errorDetected === false && errorCode!==''){
                history('/error')
                setErrorDetected(true)
            }else{
                const isBackAction = Boolean(localStorage.getItem('backAction'))
                if(isBackAction === true){
                    localStorage.removeItem('backAction')
                }else{
                    saveUrlToLocalStorage( `${location.pathname}${location.search}`)
                }
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.pathname, location.search, errorDetected, errorCode]) 
    

    return ( <Layout/> )
}

export default App
