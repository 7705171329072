import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseStatusTransition } from "./get-transition-from-status"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/delete_2

const deleteStatusTransition = async ( status: string  ): Promise<AxiosResponse<TCaseStatusTransition>>  =>{
    return await API.delete(
        `/v2/case-status-transitions/${ status }`
    )
}

export default deleteStatusTransition
