
const getAlign = (key:string): 'right' | 'left' | 'justify' =>{
    let result: 'right' | 'left' | 'justify' = 'left'
    if(key === 'version'){
        result = 'right'
    }
    if(key === 'data'){
        result = 'justify'
    }
    return(result)
}

export default getAlign
