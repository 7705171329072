import { Box, CircularProgress} from "@mui/material"

const Preloader = () => {
    return(
        <Box 
            sx={{ 
                width: '100%',
                height: '400px',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress />
        </Box>
    )
}

export default Preloader
