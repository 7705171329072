import { Box, SxProps, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow, Theme } from "@mui/material"
import { TProcessVariableDescription } from "../../../../Types/Store/StoreCaseManagement"
import VariableValueViewer from "./VariableValueViewer"
import { useState } from "react"

type TProcessVariablesProps = {
    level: number
    variables: TProcessVariableDescription[]
}

const tableCellStyle:React.CSSProperties = {
    padding: '2px 8px',
    border: '1px solid #888',
    height: '26px',
    backgroundColor: '#fff'
}

const tableBorderColor:SxProps<Theme> | undefined = {
    border: '1px solid #888',
}

const StyledTableCell = (props: TableCellProps) =>{
    
    return(
        <TableCell
            { ...props }
            sx={{
                ...tableBorderColor
            }}
        >
            { props.children }
        </TableCell>
    )
}

const ProcessVariables = (props:TProcessVariablesProps) => {
    
    const {
        variables,
        // level,
    } = props

    const [ isViewerOpened, setViewerOpened ] = useState(false)
    const [ viewerValue   , setViewerValue  ] = useState<string|undefined>(undefined)  

    const openViewerHandle = (value:string) =>{
        setViewerValue(value)
        setViewerOpened(true)
    }

    return(
        variables.length === 0 ?
            <></>
        :
            <Box
                sx={{
                    width: '100%',
                    padding: '0 80px 0 0px'
                }}
            >
                
                <VariableValueViewer
                    isViewerOpened  = {  isViewerOpened  }
                    setViewerOpened = {  setViewerOpened }
                    viewerValue     = {  viewerValue     }
                    setViewerValue  = {  setViewerValue  }
                />

                <h6
                    style={{
                        margin: '10px 0 0 0',
                        padding: '6px',
                    }}
                >
                    Variables
                </h6>
                
                <TableContainer
                    sx={{
                        border: 'none',
                        margin: '0 0 30px 0'
                    }}
                >
                    <Table size="small">
                        <TableHead
                            sx={{
                                backgroundColor: '#fff9d9',
                                color: '#fff',
                            }}
                        >
                            <TableRow>
                                <StyledTableCell
                                    align="left"
                                >
                                    Name
                                </StyledTableCell>

                                <StyledTableCell align="left">Type</StyledTableCell>
                                <StyledTableCell align="left">Value</StyledTableCell>
                            </TableRow>
                        </TableHead>


                        <TableBody>
                            {
                                variables.map((variableItem,itemIndex) => {

                                    let valueFullString = String(variableItem.value) 
                                    if(typeof(variableItem.value) === 'object'){
                                        valueFullString = JSON.stringify(variableItem.value,null, 2)
                                    }


                                    let valueString =  valueFullString
                                    let isLongString = false

                                    if(valueFullString.length > 120){
                                        isLongString = true
                                        valueString = valueFullString.slice(0,120)+ '...'
                                    }

                                    return(
                                        <TableRow
                                            key={ `variable-row-${variableItem.id}-${itemIndex}`}
                                        >
                                            <TableCell align="left"
                                                style={{ 
                                                    ...tableCellStyle,
                                                }}
                                            >
                                                <span>
                                                    { variableItem.variableName }
                                                </span>
                                                
                                            </TableCell>
                                            <TableCell align="left"
                                                style={ tableCellStyle }
                                            >
                                                { variableItem.variableType }
                                            </TableCell>
                                            <TableCell align="left"
                                                style={ tableCellStyle }
                                            >
                                                <span>
                                                    { valueString }
                                                </span>
                                                
                                                {
                                                    isLongString &&
                                                    <span
                                                        style={{
                                                            margin: '0 0 0 10px',
                                                            padding: '2px 8px',
                                                            color: 'blueviolet',
                                                            borderRadius: '8px',
                                                            backgroundColor: '#eee',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={()=>{
                                                            openViewerHandle(valueFullString)
                                                        }}
                                                    >
                                                        show
                                                    </span>

                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

    )
}

export default ProcessVariables
