import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../Store/Hook/use-typed-selector'
import { 
    FormControl, 
    InputLabel, 
    Select, 
    SelectChangeEvent,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    FormHelperText,
} from '@mui/material'
import useValidation, { enumFormValidationRule } from '../formValidator'
import { useAction } from '../../Store/Hook/use-action'
import getGroupsByName from '../../Api/UserGroups/get-groups-by-name'
import { enumApiParameter, enumSORT } from '../../Types/General'
import deleteUserFromGroup from '../../Api/UserGroups/delete-user-from-group'
import postAddUserToGroup from '../../Api/UserGroups/post-add-user-to-group'
import colorLibrary from '../../Utils/colors'


export type TReassignUserFormProps = {
    groupId?: string
    login?: string
}

export default function ReassignUserForm(props:TReassignUserFormProps) {
    
    const [ open                 , setOpen                     ] = useState(false)
    const [ groupListIndex       , setGroupListIndex           ] = useState( -1 )
    const [ isNeedToSendRequest  , setIsNeedToSendRequest      ] = useState(false)


    const [
        groupId,
        isGroupIdValid,
        groupIdValidationError,
        onGroupIdChange
    ] = useValidation([enumFormValidationRule.REQUIRED]) 

    const { groupsList, needUpdateFromAPI       } = useTypedSelector(store => store.desktopConfigurations.groups)
    const { 

        SetGroupsDesktopGroupsListFromAPI,
        setNeedUpdateUsersFromAPI,
        SetError,
        SetErrorNeedToShow,
    } = useAction()



    useEffect(()=>{
        if(needUpdateFromAPI===true){
            // Internal request for groupList dropdown from create/uddate form
            getGroupsByName({
                [enumApiParameter.DIRECTION]  : enumSORT.ASC,
                [enumApiParameter.PAGE_NUMBER]: 0,
                [enumApiParameter.PAGE_SIZE]  : 64535,
            }).then((groupListResponse)=>{
                SetGroupsDesktopGroupsListFromAPI(groupListResponse.data.content.map(item=>{
                    return({
                        groupId: item.id,
                        groupName: item.name,
                    })
                }))
            }).catch((error)=>{
                SetError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET WHOLE LIST OF GROUPS CONFIGS SORTED BY UPDATED AT'
                })
                SetErrorNeedToShow(true)
                console.log('CANT GET LIST OF GROUP CONFIGS SORTED BY UPDATED AT', JSON.stringify(error))
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateFromAPI])

    useEffect(()=>{
        if(isNeedToSendRequest === true){
            if(isGroupIdValid === true){
                
                deleteUserFromGroup({
                    groupId: props.groupId ? props.groupId : '',
                    login: props.login ? props.login : ''
                }).then(()=>{

                    postAddUserToGroup({
                        groupId: groupId,
                        login: props.login ? props.login : ''
                    }).then(()=>{

                        setNeedUpdateUsersFromAPI(true)
                    }).catch((e)=>{
                        console.log("CANT REASSIGN USER TO GROUP", e.response)
                        SetError({
                            status  : e.response.data.status ? String(e.response.data.status) : '',
                            path    : e.response.data.path   ? String(e.response.data.path)   : '',
                            message : e.response.data.message ? String(e.response.data.message) : "CANT REASSIGN USER TO GROUP"
                        })
                        SetErrorNeedToShow(true)
                    })
                }).catch((error:any)=>{
                    console.log("CAN'T DELETE USER FROM GROUP", error.response)
                    SetError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : "CAN'T DELETE USER FROM GROUP"
                    })
                    SetErrorNeedToShow(true)
                })
                handleClose()
            }
            setIsNeedToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    const handleClickOpen = () => {
        setOpen(true)
        setGroupListIndex(-1)
        onGroupIdChange('', true)
    }

    const handleClose = () => {
        setOpen(false)
        setGroupListIndex(-1)
        onGroupIdChange('', true)
    }

    const handleGroupSelectorChange = (event: SelectChangeEvent) => {
        for(let i = 0; i<groupsList.length; i++){
            if(groupsList[i].groupId === event.target.value as string){
                
                onGroupIdChange(groupsList[i].groupId) 
                setGroupListIndex(i)
                break
            }
        }
    }

    const handleReassign = () =>{
        if(groupListIndex === -1){
            onGroupIdChange('')
        }else{
            onGroupIdChange(groupId)
            setIsNeedToSendRequest(true)
        }
    }

    



    return (
        <div>
            <Button 
                variant="outlined" 
                onClick={handleClickOpen} 
                sx={{
                    margin: '0 10px 0 0',
                    borderColor: colorLibrary.backgroundColor,
                    color: colorLibrary.backgroundColor,
                }}
            >
                Reassign
            </Button>

            <Dialog 
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Reassign user( {props.login} ) to another group</DialogTitle>
                
                <DialogContent>
                    <DialogContentText sx={{margin: '0 0 10px 0'}}>
                        To reassign user from this group to another, please select new group here and click "Reassign" Button.
                    </DialogContentText>
                    
                    <FormControl 
                        fullWidth 
                        error = { !isGroupIdValid }
                    >
                        <InputLabel 
                            id="group-selector-label"
                        >
                            Group
                        </InputLabel>

                        <Select
                            labelId="group-selector-label"
                            id="group-selector"
                            value={ groupsList.length > 0 && groupListIndex>=0  ? String(groupsList[groupListIndex].groupId) : '' }
                            label="Group"
                            onChange={handleGroupSelectorChange}
                        >
                            {
                                groupsList.filter(
                                    (item)=>{ 
                                        return(item.groupId !== props.groupId)
                                    }
                                ).map((item, index)=>{
                                    return(
                                        <MenuItem value={ item.groupId } key = {`${item}-${index}`}>
                                            { item.groupName }
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <FormHelperText>
                            { groupIdValidationError }
                        </FormHelperText>

                    </FormControl>
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleReassign}>Reassign</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
