import { useState, useEffect } from 'react'
import { Box, Button, FormControl, FormHelperText, Modal, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { TWorkflowConfiguration } from "../../Types/Store/StoreWorkflow"
import useValidation, { enumFormValidationRule } from '../formValidator'
import putUpdateConfiguration from '../../Api/Workflow/v2/put-update-configuration'
import { TApiErrorDescription } from './ErrorModal'


type TEditWorkflowConfigurationProps = {
    itemToEdit: TWorkflowConfiguration
    needUpdateFromApi: ()=>void;
    setApiError: (params:TApiErrorDescription)=>void
}

const EditWorkflowConfiguration = (props:TEditWorkflowConfigurationProps) =>{

    const { itemToEdit, needUpdateFromApi, setApiError } = props
    const [ isOpen, setOpen ] = useState(false)
    const [ isNeedToSendRequest, setNeedSendRequest ] = useState(false)


    const [
        localValue,
        isLocalValueValid,
        localValueError,
        onLocalValueChange
    ] = useValidation(
        [enumFormValidationRule.REQUIRED], 
        itemToEdit.value ? itemToEdit.value : ''
    )
    
    const [
        localDescription,
        isLocalDescriptionValid,
        localDescriptionError,
        onLocalDescriptionChange
    ] = useValidation(
        [enumFormValidationRule.REQUIRED], 
        itemToEdit.value ? itemToEdit.value : ''
    )

    const handleOpen = () =>{
        setOpen(true)
        onLocalValueChange(itemToEdit.value ? itemToEdit.value : '', true)
        onLocalDescriptionChange(itemToEdit.description ? itemToEdit.description : '', true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const handleApply = () =>{
        setNeedSendRequest(true)
    }

    useEffect(()=>{
        if(isNeedToSendRequest === true && isLocalValueValid===true && isLocalDescriptionValid){
            setNeedSendRequest(false)

            putUpdateConfiguration({
                ...itemToEdit,
                value: localValue,
                description: localDescription
            })
            .then((response)=>{
            })
            .catch((error)=>{
                console.log('CANT DELETE WORKFLOW CONFIGURATION', JSON.stringify(error))
                setApiError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT DELETE WORKFLOW CONFIGURATION"
                })
            })
            .finally(()=>{
                handleClose()
                needUpdateFromApi()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    return(
        <>
            <Box
                sx={{
                    margin: '0px 8px',
                    transition: 'all .2s ease-out',
                    borderRadius: '6px',
                    '&:hover':{
                        cursor: 'pointer',
                        fill: '#5700ff',
                        backgroundColor: '#eee',
                    }
                }}
                component = { EditIcon   }
                onClick   = { handleOpen }
            />

            <Modal
                open={isOpen}
                onClose={handleClose}
                
            >
                <Box 
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'max-content',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2"
                        sx={{
                            margin: '0 0 32px 0'
                        }}
                    >
                        Edit property
                    </Typography>
                    
                    <Box sx={{ minWidth: 420, margin: '8px 0 28px 0' }}>
                        <TextField
                            size     = 'small'
                            type     =  "text"
                            variant  =  "outlined"
                            label    = { 'Key'   }
                            value    = { itemToEdit.key               }
                            fullWidth
                            disabled = {true}
                        />
                    </Box>

                    <Box sx={{ minWidth: 420, margin: '8px 0 28px 0' }}>
                        
                        <FormControl fullWidth>
                            <TextField
                                size     = 'small'
                                type     =  "text"
                                variant  =  "outlined"
                                label    = { 'Parameter Name            '   }
                                value    = { localDescription               }
                                error    = {!isLocalDescriptionValid        }
                                onChange = { 
                                    (e)=>{ onLocalDescriptionChange(e.target.value) } 
                                }
                                fullWidth
                            />
                        </FormControl>
                        <FormHelperText> { localDescriptionError } </FormHelperText>
                    </Box>

                    <Box sx={{ minWidth: 420, margin: '8px 0 0 0' }}>
                
                        <FormControl fullWidth>
                            <TextField
                                size     = 'small'
                                type     =  "text"
                                variant  =  "outlined"
                                label    = { 'Value'   }
                                value    = { localValue               }
                                error    = {!isLocalValueValid        }
                                onChange = { 
                                    (e)=>{ onLocalValueChange(e.target.value) } 
                                }
                                fullWidth
                            />
                        </FormControl>
                        <FormHelperText> { localValueError } </FormHelperText>
                    </Box>
                    
                    


                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '32px 0 0 0',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleApply}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default EditWorkflowConfiguration
