import { TreeItemProps } from "@mui/x-tree-view"
import { Box } from "@mui/material"
import getIconByFilenameExtension from "../../../Utils/getIconByFilenameExtension"
import { TMTPFileDescription } from "./getFileTree"
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'

import colorLibrary from "../../../Utils/colors"
import { downloadFileByUrlWithHref } from "../../CaseManagement/Case/CaseFiles/Utils/download-file"

type TMTPThreeViewFileProps = TreeItemProps & {
    rawFileName: string
    fileDescription?: TMTPFileDescription
}

const MTPThreeViewFile = (props:TMTPThreeViewFileProps) =>{
    
    const { label, rawFileName, fileDescription } = props

    return(
        <Box
            sx={{
                margin: '6px 0 0 30px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
            }}
        >
            
            {/* ICON */}
            <Box 
                component = { getIconByFilenameExtension( rawFileName ) } 
                sx={{ 
                    mr: 1, 
                    fill: colorLibrary.backgroundColor
                }} 
            />
            
            { label }

            {/* DOWNLOAD BUTTON */}
            {
                fileDescription ?
                    <Box
                        component={ DownloadForOfflineIcon }
                        sx={{
                            fill: colorLibrary.backgroundColor,
                            margin: '0 0 0 10px',
                            cursor: 'pointer'
                        }}
                        onClick = {()=>{
                            downloadFileByUrlWithHref( rawFileName, fileDescription.presignedUrl.url )
                        }}
                    >
                        
                    </Box>
                :null
            }
        </Box>
    )
}

export default MTPThreeViewFile
