import { AxiosResponse } from "axios"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/User%20configuration%20API/getByLogin_1

export type TGetUserConfigRequestParams = {
    application: string
    appVersion : string
    login      : string
}

export type TApiGetUserConfigResponse = {
    application: string
    appVersion : string
    createdAt  : string
    updatedAt  : string
    version    : number
    data       : Object
    login      : string
}

const getUserConfig = async ( params: TGetUserConfigRequestParams ): Promise<AxiosResponse<TApiGetUserConfigResponse>>  =>{
    const {
        application,
        appVersion,
        login
    } = params
    
    return await API.get(
        `/v2/configurations/users/${application}/${appVersion}/${login}`
    )
}

export default getUserConfig
