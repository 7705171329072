import getFileLinksByCaseId from "../../../../../Api/CaseManagement/v2/get-file-links-by-case-id"
import getFileListByCaseId from "../../../../../Api/CaseManagement/v2/get-filelist-by-case-id"
import { TCaseFileDescritpion } from "../../../../../Types/CaseManagement"
import { TFileFormatToType, TFileLink, TTCaseFileLinkState } from "../SmileInspector"

type TGetFileLinksArgs = {
    caseId: string | undefined
    caseFileLinksState: TTCaseFileLinkState
    setCaseFileLinkState: (value: React.SetStateAction<TTCaseFileLinkState>) => void
    filesToOpenInSMI: string[] ,
    onFinish? : ()=> void
}




const getFileLinks = async (
    args:TGetFileLinksArgs
) =>{

    const {
        caseId,
        caseFileLinksState,
        setCaseFileLinkState,
        filesToOpenInSMI,
        onFinish
    } = args


    if(caseId){
        try{
            const fileLinksByCaseId = await getFileLinksByCaseId({id:caseId})
            const fileListByCaseId  = await getFileListByCaseId({id:caseId})

            const avalableExtensions:TFileFormatToType[] =  []
            let extensionIndex = 0
            
            // serach for avalableExtesions
            for(let i = 0; i< caseFileLinksState.supportedExtensions.length; i++){
                fileLinksByCaseId.data.forEach((fileDataItem)=>{
                    if(String(fileDataItem.s3key).indexOf(caseFileLinksState.supportedExtensions[i][1])>-1){
                        let isElementInArray = false
                        avalableExtensions.forEach((item) => {
                            if(item[1] === caseFileLinksState.supportedExtensions[i][1]){
                                isElementInArray = true
                            }
                        })

                        if(isElementInArray === false){
                            avalableExtensions.push(caseFileLinksState.supportedExtensions[i])
                        }
                    }
                })
                
            }

            //  convert filenames from s3key-links
            let fileLinks:TCaseFileDescritpion[] = fileLinksByCaseId.data.map((dataItem)=>{
                const splittedLink = dataItem.s3key.split('/')
    

                return({
                    fileName : splittedLink.slice(1,splittedLink.length).join('/'),
                    fileLink : dataItem.fileLink,
                })
            })

            let linksToShow:TFileLink[] = [] 

            // filter links with avalableExtensions
            for(let i = 0; i<avalableExtensions.length; i++){
                const filteredLinks = fileLinksByCaseId.data
                .filter(item=>{
                    const splittedFilePath = String(item.s3key).split('/')
                    if(String(splittedFilePath[splittedFilePath.length-1]).indexOf(avalableExtensions[i][1]) > -1){
                        return(true)
                    }
                    return false
                })
                
                
                if(filteredLinks.length>0){ // serach for SWF/wrap.zip files to show in IInspector
                    
                    linksToShow = filteredLinks            
                    .map(item=>{
                        const splittedFilePath = String(item.s3key).split('/')
                        const filteredFileList = fileListByCaseId.data.filter(fileItem => fileItem.s3key === item.s3key)
                        return({
                            fileLink: item.fileLink,
                            fileName: splittedFilePath.slice(1,splittedFilePath.length).join('/'),
                            isVisible: true,
                            updatedAt: filteredFileList.length === 1 ? filteredFileList[0].updatedAt : undefined
                        })
                    })

                    if(avalableExtensions[i][1]==='_wrap.zip' || avalableExtensions[i][1]==='.swf'){
                        
                        linksToShow = linksToShow.sort((linkItem1, linkItem2)=>{
                            if(linkItem1.updatedAt && linkItem2.updatedAt){
                                if(Date.parse(linkItem1.updatedAt) > Date.parse(linkItem2.updatedAt)){
                                    return(1)
                                }else{
                                    return(-1)
                                }
                            }
                            return(0)
                        })

                        if(linksToShow.length > 1){
                            linksToShow = [linksToShow[linksToShow.length-1]]
                        }
                    }else{
                        linksToShow = []
                        continue
                    }
                    break 
                }
            }

            if(filesToOpenInSMI.length>0){
                const filteredLinsToOpen:TFileLink[] = fileLinksByCaseId.data.filter((fileLinkItem)=>{
                    let result = false
                    filesToOpenInSMI.forEach(fileToOpen => {
                        if(result === false){
                            const linkToOpen = fileToOpen.split('|')[1]
                            if(fileLinkItem.s3key.indexOf(linkToOpen)>-1){
                                result = true
                            }
                        }
                    })
                    return result
                })
                .map(fileLinkItem =>{
                    const splittedFilePath = String(fileLinkItem.s3key).split('/')
                    return({
                        ...fileLinkItem,
                        fileName: splittedFilePath.slice(1,splittedFilePath.length).join('/'),
                        isVisible: true
                    })
                })
                linksToShow = filteredLinsToOpen
            }

            setCaseFileLinkState((prev)=>{
                return({
                    ...prev,
                    isLoaded: true,
                    avalableExtensions,
                    extensionToShowIndex: extensionIndex !==-1 ? extensionIndex : -1,
                    fileLinks,
                    linksToShow,
                })
            })

        } catch (error){
            console.log('CANT GET FILES LINKS', error)
        }
        
        if(onFinish){
            onFinish()
        } 
    }
}

export default getFileLinks




