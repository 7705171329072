import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter, enumSORT } from "../../Types/General"
import { User } from "../../Types/Store/StoreGroups"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Users%20in%20groups%20API/list

export type TApiGetUsersInGroup = {
    [enumApiParameter.GROUP_ID ] : string
    [enumApiParameter.DIRECTION] : enumSORT
    [enumApiParameter.PAGE_NUMBER] : number
    [enumApiParameter.PAGE_SIZE]   : number
}

export type TApiGetUsersInGroupResponse = {
    content       : User[],
    totalElements : number,
    size          : number,
    number        : number,
}

const getUsersInGroup = async ( params: TApiGetUsersInGroup ): Promise<AxiosResponse<TApiGetUsersInGroupResponse>>  =>{
    return await API.get(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,

        { 
            params:{
                [enumApiParameter.DIRECTION  ]: params[enumApiParameter.DIRECTION],
                [enumApiParameter.PAGE_NUMBER]: params[enumApiParameter.PAGE_NUMBER],
                [enumApiParameter.PAGE_SIZE  ]: params[enumApiParameter.PAGE_SIZE]
            } 
        }
    )
}

export default getUsersInGroup
