
const createBoolArrayWithFixedLength = (length: number) =>{
    const result: boolean[] = []
    
    for(let i = 0; i<length; i++){
        result.push(false)
    }
    return result
}

export default createBoolArrayWithFixedLength
