import { Checkbox, TableCell } from "@mui/material"
import { ReactElement } from "react"
import { IDesktopUserConfig, IDesktopGroupConfig, TApplicationsDescription } from "../../../../Types/Store/StoreDesktopConfig"

import { 
    TableBody,
    TableRow,
} from "@mui/material"
import { ITableHeaderDescription } from "../../../../Types/Components/table"
import converter from "./Converter"
import getAlign from "./Aligner"
import { enumSortingField } from "../../../../Types/General"
import { capitalizeFirstLetter } from "../../../../Utils/capitalize-string"


export type TConfigTableBodyProps = {
    headerDescription?: ITableHeaderDescription[] 
    data: IDesktopUserConfig[] | IDesktopGroupConfig[] | TApplicationsDescription[]
    exclude?: string[]
    isCheckable?: boolean
    onRowCheck?: (rowIndex:number)=>void
    checkedRow?: boolean[]
}

const sortingFieldConverter = (val: enumSortingField):string =>{
    let result = val.toString()
    let splittedResult = result.split('_')
    result  = splittedResult.map((item,index)=>{
        return(
            index !==0 ?
                capitalizeFirstLetter(item.toLowerCase())
            :
                item.toLowerCase()
        )
    }).join('')
    if(result === 'configData'){ result = 'data' }
    return result
}


const ConfigTableBody = (
    props:TConfigTableBodyProps
) =>{
    
    const { 
        data,
        exclude,
        isCheckable,
        onRowCheck,
        checkedRow,
        headerDescription
    } = props

    const timeNow = Date.now().toString()



    let tableRows = data.map((rowDataItem, index)=>{
        const keys:string[] = Object.keys(rowDataItem)
        const values: string[] = Object.values(rowDataItem)
        
        
        
        const rowContent: ReactElement[] = []

        if(isCheckable && onRowCheck && checkedRow){

            rowContent.push(
                // CELL FOR CHECKBOX
                <TableCell
                        component="th"
                        id={'-1'}
                        scope="row"
                        padding="none"
                        key={`${timeNow}${index}--1`}
                        
                >
                    <Checkbox
                        color="primary"
                        checked = {checkedRow[index]}
                        onChange = { ()=>{
                            onRowCheck(index)
                        }}
                    />
                </TableCell>
            )
        }

        return(
            <TableRow
                key={ String(index) }
                sx={{
                    backgroundColor: checkedRow && checkedRow[index]===true ? '#f3edff' : undefined
                }}
            
            >
                {
                    isCheckable && onRowCheck && checkedRow ?
                        <TableCell
                            component="th"
                            id={'-1'}
                            scope="row"
                            padding="none"
                            key={`${timeNow}${index}--1`}
                                
                        >
                            <Checkbox
                                color="primary"
                                // value={}
                                checked = {checkedRow[index]}
                                onChange = { ()=>{
                                    onRowCheck(index)
                                }}
                            />
                        </TableCell>
                    : null
                }


                { 
                    headerDescription ? 
                        
                        headerDescription

                        .filter((item) => {
                            let result = true
                            if(exclude){
                                if(exclude.indexOf(item.id)!==-1){
                                    result = false
                                }
                            }
                            return result
                        })

                        .map((item, index)=>{
                            return(
                                <TableCell
                                    component="th"
                                    id={String(index)}
                                    scope="row"
                                    padding="normal"
                                    key={`${timeNow}${index}-${item.id}`} 
                                    align = { getAlign(sortingFieldConverter(item.id))  }
                                >
                                    { 
                                        converter(
                                            sortingFieldConverter(item.id),
                                            values[keys.indexOf(sortingFieldConverter(item.id))]
                                        )
                                    }
                                </TableCell>
                            )
                        }) 
                    : null 
                }
            </TableRow>
        )
    })


    return(
        <TableBody>
            { 
            tableRows.length !== 0 ?
                
                tableRows
            :
                <TableRow>
                    <TableCell>  Nothing to show </TableCell>
                </TableRow>
            }
        </TableBody>
    )
}

export default ConfigTableBody
