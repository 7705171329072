import { AxiosResponse } from "axios"
import API from '../api-base'

// https://adalisk.stoplight.io/docs/tp-api/3538a65882448-unlock-case

const deleteCaseLock = async ( caseId: string  ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/cases/${caseId}/locks/supervisor`
    )
}

export default deleteCaseLock
