
import { TSorting } from "../../Pages/DesktopConfigurations/TabsComponents/Tables/ConfigTableHeader"
import { enumSORT, enumSortingField } from "../General"

export enum enumStoreDesktopConfigurationsAction {
    SET_DC_NEED_UPDATE_APPLICATIONS_FROM_API  = 'SET_DC_NEED_UPDATE_APPLICATIONS_FROM_API',
    SET_DC_APPLICATIONS_DESCRIPTION   = 'SET_DC_APPLICATIONS_DESCRIPTION',


    
    SET_DC_ERROR_NEED_TO_SHOW         = 'SET_DC_ERROR_NEED_TO_SHOW',
    SET_DC_ERROR                      = 'SET_DC_ERROR',
    
    SET_DC_GROUPS_CONFIGS                              = 'SET_DC_GROUPS_CONFIGS',
    SET_DC_GROUPS_CONFIGS_SELECTED_APPLICATION_VERSION = 'SET_DC_GROUPS_CONFIGS_SELECTED_APPLICATION_VERSION',
    SET_DC_GROUPS_LIST                                 = 'SET_DC_GROUPS_LIST',
    SET_DC_NEED_UPDATE_GROUPS_FROM_API                 = 'SET_DC_NEED_UPDATE_GROUPS_FROM_API',
    SET_DC_GROUPS_PAGINATION                           = 'SET_DC_GROUPS_PAGINATION',
    SET_DC_GROUPS_PERIOD                               = 'SET_DC_GROUPS_PERIOD',
    SET_DC_GROUPS_SORTING_DIRECTION                    = 'SET_DC_GROUPS_SORTING_DIRECTION',
    
    SET_DC_USERS_CONFIGS                              = 'SET_DC_USERS_CONFIGS',
    SET_DC_USERS_CONFIGS_SELECTED_APPLICATION_VERSION = 'SET_DC_USERS_CONFIGS_SELECTED_APPLICATION_VERSION',
    SET_DC_NEED_UPDATE_USERS_FROM_API                 = 'SET_DC_NEED_UPDATE_USERS_FROM_API',
    SET_DC_USERS_SELECTED_APPLICATION_INDEX           = 'SET_DC_USERS_SELECTED_APPLICATION_INDEX',
    SET_DC_USERS_PAGINATION                           = 'SET_DC_USERS_PAGINATION',
    SET_DC_USERS_LOGIN_FILTER                         = 'SET_DC_USERS_LOGIN_FILTER',
    SET_DC_USERS_SORTING_DIRECTION                    = 'SET_DC_USERS_SORTING_DIRECTION',
    SET_DC_USERS_PERIOD                               = 'SET_DC_USERS_PERIOD',
}

export interface IDesktopApplicationConfig {
    application : string
    createdAt   : string // 2021-09-03T15:29:07.762Z
    updatedAt   : string // 2021-09-03T15:29:07.762Z
    version     : number
    data  : string
}

export interface IDesktopUserConfig extends IDesktopApplicationConfig {
    login       : string
}

export interface IDesktopGroupConfig extends IDesktopApplicationConfig {
    groupId   : string
    groupName : string
}

export interface IDesktopGroupListItem {
    groupId   : string
    groupName : string
}


export type TApplicationsDescription = {
    name: string
    versions: string[]
}

export type TApplicationVarsionSelected = {
    name: string
    version: string
}

export interface IStoreDesktopConfigurations {
    applicationsList: string[]

    applicationsDescription: TApplicationsDescription[]

    applications:{
        needUpdateFromAPI: boolean
        configs          : IDesktopApplicationConfig[]
        totalElements    : number
        number           : number
        size             : number
        sorting          : enumSORT
        current          : IDesktopApplicationConfig | undefined
 
    }

    groups:{
        needUpdateFromAPI        : boolean
        groupsList               : IDesktopGroupListItem[]
        configs                  : IDesktopGroupConfig[]
        totalElements            : number
        number                   : number
        size                     : number
        selectedApplicationVersion: TApplicationVarsionSelected | undefined
        selectedApplicationIndex : number
        sorting                  : enumSORT
        periodFrom               : null | string
        periodTo                 : null | string 
        current                  : IDesktopGroupConfig | undefined
    }

    users:{
        needUpdateFromAPI        : boolean
        configs                  : IDesktopUserConfig[]
        totalElements            : number
        number                   : number
        size                     : number
        selectedApplicationVersion: TApplicationVarsionSelected | undefined
        selectedApplicationIndex : number
        sortingDirection         : enumSORT
        sortingField             : enumSortingField
        periodFrom               : null | string
        periodTo                 : null | string
        loginFilter              : string | undefined
        current                  : IDesktopUserConfig | undefined
    },

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}


export type TDesktopConfigurationsStoreAction = TDesktopConfigurationsSetNeedUpdateApplicationsFromAPI |

                                                TDesktopConfigurationsSetNeedUpdateGroupsFromAPI |
                                                TDesktopConfigurationsSetGroupsConfigsFromAPI |
                                                TDesktopConfigurationsSetGroupsListFromAPI |
                                                TDesktopConfigurationSetGroupsPagination |
                                                TDesktopConfigurationSetGroupsPeriod |
                                                TDesktopConfigurationSetGroupsSortingDirection |

                                                TDesktopConfigurationSetUsersConfigs |
                                                TDesktopConfigurationSetNeedUpdateUsersConfigurationsFromAPI |
                                                TDesktopConfigurationSetUsersPagination |
                                                TDesktopConfigurationSetUsersSelectedApplicationIndex |
                                                TDesktopConfigurationSetUsersLoginFilter |
                                                TDesktopConfigurationSetUsersSortingDirection |
                                                TDesktopConfigurationSetUsersPeriod |
                                                
                                                TDesktopConfigurationSetError |
                                                TDesktopConfigurationSetErrorNeedToShow |
                                                TDesktopConfigurationsSetApplicationsDescriptionFromAPI |
                                                TDesktopConfigurationsSetGroupsConfigsApplictionVersion |
                                                TDesktopConfigurationsSetUsersConfigsApplictionVersion

export type TDesktopConfigurationsSetApplicationsDescriptionFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_APPLICATIONS_DESCRIPTION
    payload: TApplicationsDescription[]
}

export type TDesktopConfigurationsSetUsersConfigsApplictionVersion = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS_SELECTED_APPLICATION_VERSION
    payload: TApplicationVarsionSelected
}

export type TDesktopConfigurationsSetGroupsConfigsApplictionVersion = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS_SELECTED_APPLICATION_VERSION
    payload: TApplicationVarsionSelected | undefined
}
                                                
// =================================================
//  APPLICATIONS CONFIGS SECTION
// =================================================

export type TDesktopConfigurationsSetNeedUpdateApplicationsFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_APPLICATIONS_FROM_API
    payload: boolean
}


// =================================================
//  GROUPS CONFIGS SECTION
// =================================================

export type TDesktopConfigurationsSetNeedUpdateGroupsFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_GROUPS_FROM_API
    payload: boolean
}

export type TDesktopConfigurationsSetGroupsConfigsFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS
    payload: IDesktopGroupConfig[]
}

export type TDesktopConfigurationsSetGroupsListFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_LIST
    payload: IDesktopGroupListItem[]
}

export type TDesktopConfigurationSetGroupsPagination = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PAGINATION
    payload: TDesktopConfigurationsPaginationPayload
}
    export type TDesktopConfigurationsPaginationPayload = {
        number: number
        size: number
        totalElements: number
    }


export type TDesktopConfigurationSetGroupsPeriod = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PERIOD
    payload: TDesktopConfigurationsPeriodPayload
}
    export type TDesktopConfigurationsPeriodPayload = {
        periodFrom               : null | string
        periodTo                 : null | string 
    }

export type TDesktopConfigurationSetGroupsSortingDirection = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_SORTING_DIRECTION
    payload: TSorting
}

// =================================================
//  USERS CONFIGS SECTION
// =================================================

export type TDesktopConfigurationSetUsersConfigs = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS
    payload: IDesktopUserConfig[]
}

export type TDesktopConfigurationSetNeedUpdateUsersConfigurationsFromAPI = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_USERS_FROM_API
    payload: boolean
}

export type TDesktopConfigurationSetUsersPagination = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_PAGINATION
    payload: TDesktopConfigurationsPaginationPayload
}

export type TDesktopConfigurationSetUsersSelectedApplicationIndex = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_SELECTED_APPLICATION_INDEX
    payload: number
}

export type TDesktopConfigurationSetUsersLoginFilter = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_LOGIN_FILTER 
    payload: string
}


export type TDesktopConfigurationSetUsersSortingDirection = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_SORTING_DIRECTION 
    payload: TDesktopConfigurationSetUsersSortingDirectionPayload
}

    export type TDesktopConfigurationSetUsersSortingDirectionPayload = {
        sortingField: enumSortingField,
        sortingDirection: enumSORT
    } 

export type TDesktopConfigurationSetUsersPeriod = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_PERIOD 
    payload: TDesktopConfigurationsPeriodPayload
}





export type TDesktopConfigurationSetErrorNeedToShow = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_ERROR_NEED_TO_SHOW 
    payload: boolean
}

export type TDesktopConfigurationSetErrorPayload = {
    status    : string
    message   : string
    path      : string
}

export type TDesktopConfigurationSetError = {
    type: enumStoreDesktopConfigurationsAction.SET_DC_ERROR 
    payload: TDesktopConfigurationSetErrorPayload
}



