import { AxiosResponse } from "axios"
import API from './api-base'
import { enumSORT } from "../../Types/General"
import { TMtpCaseListItem } from "../../Types/Store/StoreMtp"

// https://tp-mtp-api.tp-qa.smiledirect.services/swagger-ui/index.html#/MobileTreatment%20Plan%20API/getMtpsWithCursor

export type TApiGetMtpCasesListWithCursorParams = {
    info             ? : string
    status           ? : string
    'period.from'    ? : string
    'period.to'      ? : string
    lastEvaluatedKey ? : string
    'page.size'      ? : number
    direction        ? : enumSORT
}

export type TApiGetMtpCasesListWithCursorResponse = {
    items:TMtpCaseListItem[]
    lastPage: boolean
    lastEvaluatedKey: string
}


const getMtpCasesListWithCursor = async ( params: TApiGetMtpCasesListWithCursorParams ): Promise<AxiosResponse<TApiGetMtpCasesListWithCursorResponse>>  =>{
    
    const paramsCopy:TApiGetMtpCasesListWithCursorParams = { ...params}

    if(paramsCopy["period.from"] === '') delete paramsCopy["period.from"]
    if(paramsCopy["period.to"  ] === '') delete paramsCopy["period.to"  ]

    return await API.get(
        
        `/v1/mtps`,
        {
            params:{
                ...paramsCopy
            }
        }
    )
}

export default getMtpCasesListWithCursor
