import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import getCaseStatusTransitions from "../../../Api/CaseManagement/v2/get-case-status-transitions"
import getTransitionFromStatus, { TCaseStatusTransition } from "../../../Api/CaseManagement/v2/get-transition-from-status"
import { TWorkflowSetErrorPayload } from "../../../Types/Store/StoreWorkflow"
import ErrorModal from "../../MobileTreatmentPlan/ErrorModal"
import { tableHeaderDescription } from "./tableHeaderDescription"
import TransitionsTable from "./TransitionsTable"

const Transitions = () =>{

    const [ isNeedUpdateFromAPI, setNeedUpdateFromAPI ] = useState(true)
    const [ statusFilter       , setStatusFilter      ] = useState('')
    const [ transitionsList    , setTransitionsList   ] = useState<TCaseStatusTransition[]>([])
    const [ avalableStatus     , setAvalableStatus    ] = useState<string[]>([])
    const [ apiError           , setApiError          ] = useState<TWorkflowSetErrorPayload>({
        needToShow: false,
        message: '',
        path: '',
        status: ''
    })    

    useEffect(()=>{

        if(isNeedUpdateFromAPI === true){

            if(statusFilter === ''){
                getCaseStatusTransitions()
                .then((response)=>{
                    
                    const newTransitionsList = response.data
                        .sort((a, b) => Number(b.initial)-Number(a.initial))

                    setTransitionsList(
                        newTransitionsList
                    )

                    setAvalableStatus( newTransitionsList.map(item => item.status))

                })
                .catch((error)=>{
                    
                    console.log('CANT GET CASE STATUS TRANSITIONS FROM API',JSON.stringify(error))
    
                    setApiError({
                        needToShow: true,
                        message: error.response.data.message,
                        path: error.response.data.path,
                        status: error.response.data.status
                    })
                })
            }else{

                getTransitionFromStatus(statusFilter)
                .then((response)=>{
                    setTransitionsList([response.data])
                })
                .catch((error)=>{
                    
                    console.log('CANT GET TRANSITIONS FROM STATUS FROM API', JSON.stringify(error))
    
                    setApiError({
                        needToShow: true,
                        message: error.response.data.message,
                        path: error.response.data.path,
                        status: error.response.data.status
                    })
                })
            }

            setNeedUpdateFromAPI(false)
        }

    },[isNeedUpdateFromAPI, statusFilter])



    return(
        <div>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0 0 30px'}}>
                <TransitionsTable
                    headerDescription = { tableHeaderDescription }
                    tableData         = { transitionsList        }
                    setStatusFilter   = { setStatusFilter        }
                    setNeedUpdateFromAPI = { setNeedUpdateFromAPI }
                    avalableStatus = { avalableStatus }
                    setApiError    = { setApiError } 
                />
            </Box>

            <ErrorModal
                header= { 'Status Transition Error' }
                error        = { apiError }
                errorControl = { (isVisible:boolean)=>{
                    setApiError({
                        needToShow: isVisible,
                        message: '',
                        path: '',
                        status: ''
                    })
                }} 
            />

        </div>
    )

}

export default Transitions
