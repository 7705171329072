import { AccessToken, Token, Tokens } from "@okta/okta-auth-js"
import axios, { InternalAxiosRequestConfig } from "axios"
import { authClient } from '../App'
import { oktaAuthConfig, OKTA_DOMAIN } from '../Security/okta-config'

const localAccessTokenChecker = async (config:InternalAxiosRequestConfig<any>) => {

    let oktaAccessToken: AccessToken | undefined
    
    try{
        const { accessToken } = await authClient.tokenManager.getTokens()
        if (!accessToken) {
            // You're not logged in, you need a sessionToken
            authClient.token.getWithRedirect({
                responseType: 'code'
            })
        }else{
            oktaAccessToken = accessToken
        }
    }
    catch(e){
        console.log("CAN'T FIND LOCALLY SAVED TOKENS", e)
    }
    
    
    if(typeof(oktaAccessToken)!=='undefined'){
        if(typeof(config)!=='undefined' && typeof(config.headers)!=='undefined'){
            config.headers.Authorization = `Bearer ${ oktaAccessToken.accessToken }`
        }
    }
    return config
}

const tokenError401Handler = (error: any)=>{

    if(typeof(error)!=='undefined'&& typeof(error.response)!=='undefined'){
        if(error.response.status === 401){

            const originalRequest = error.config
    
            if(error.config){
                axios.get(`https://${OKTA_DOMAIN}/api/v1/sessions/me`,{
                    withCredentials: true,
                })
                .then((response)=>{
                    
                    const responseData = response.data
                    console.log(response.data)
                    const sessionId = responseData['id']
                    authClient.token.getWithoutPrompt({
                        responseType: 'code', // or array of types
                        sessionToken: sessionId // optional if the user has an existing Okta session
                    })
                    .then(function(res) {
        
                        const { accessToken } = res.tokens as Tokens
                        if(accessToken){
                            authClient.tokenManager.add('code', accessToken)
                        }
                        const newAxiosInstance = axios.create(error.config)
    
                        return newAxiosInstance.request(originalRequest)
        
                    })
                    .catch(function(err) {
                        if(err.status === 401){
                            authClient.token.getWithRedirect({
                                responseType: 'code'
                            })
                        }else{
                            console.log(err.status, 'NOT AUTHORIZED')
                        }
                    })
                    
                })
                .catch((e)=>{
                    console.log(e.status, e , "CAN't get SESSION_TOKEN FROM AUTH_PROVIDER( OKTA )")
                })
            }
        }

        const errorConfig: any = error.config
        if (error.response.status === 401 && error.config && !errorConfig._isRetry  ) {
            const originalRequest = error.config
            try {
                
                authClient.tokenManager.getTokens()
                .then(({accessToken}) => { 
                    const tokenToRenew: Token = {
                        idToken      : 'code',
                        claims       : accessToken!.claims,
                        expiresAt    : accessToken!.expiresAt,
                        scopes       : accessToken!.scopes,
                        authorizeUrl : accessToken!.authorizeUrl,
                        
                        issuer       : oktaAuthConfig.issuer ? oktaAuthConfig.issuer : 'NO_ISSUER',
                        clientId     : oktaAuthConfig.clientId ? oktaAuthConfig.clientId : 'NO_CLIENT_ID',
                    }

                    if(tokenToRenew){
                        authClient.token.renew(tokenToRenew)
                        .then(function(freshToken) {
                            if(freshToken){
                                authClient.tokenManager.add('accessToken', freshToken)
                            }
                            const newConfig:any = error.config
                            newConfig._isRetry = true
                            const newAxiosInstance = axios.create(error.config)
                            return newAxiosInstance.request(originalRequest)
                        })
                        .catch(function(err) {
                            console.log('NOT AUTHORIZED, CANT UPDATE ACCESS TOKEN')
                        })
                    }
                    
                })

            } catch (e) {
                console.log('NOT AUTHORIZED')
            }
        }else{
            console.log('API REQUEST ERROR', error)
            throw error
        }
    }   
}

export { localAccessTokenChecker, tokenError401Handler }
