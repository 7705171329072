import { useState, useMemo } from "react"
import checkUuid from "../Utils/check-uuid"
import isEmail from 'validator/lib/isEmail'

export enum enumFormValidationRule {
    REQUIRED     = 'REQUIRED',
    EMAIL        = 'EMAIL',
    EMAIL_LIST   = 'EMAIL_LIST',
    UUID         = 'UUID',
    STRING_ARRAY = 'STRING_ARRAY',
    JSON_FORMAT  = 'JSON_FORMAT', 
}

export type TFormValidationResult = [
    string, //value
    boolean, // isValid
    string, //error
    (newValue:string, isInit?:boolean) => void, 
]


export type TCheckResult = {
    isValid : boolean,
    error   : string,
}


export const checkValueRequired = (value:string) => {

    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    if( !value.trim() ){
        result.isValid = false
        result.error = 'This field required'
    }
    return result
}

export const checkArrayValueRequired = (valueArray: string[])=>{
    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    
    valueArray.forEach((value)=>{
        if(result.isValid === true){
            if( !value.trim() ){
                result.isValid = false
                result.error = 'One of the values is empty or an extra comma is specified'
            }
        }
    })

    return result
}

const isSmileDirectClubEmail = ( email: string ) =>{
    let result = false
    const splittedEmail = email.split('@')
    if(splittedEmail.length === 2){
        const firstPartOfEmail = splittedEmail[0]
        const secondPartOfEmail = splittedEmail[1]
        if( firstPartOfEmail.length > 0 && secondPartOfEmail === 'smiledirectclub.com'){
            result = true
        }
    }
    return(result)
}

export const checkValueRequiredEmail = (value:string) => {
    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    if (!value.trim()) {
        result.isValid = false
        result.error = 'This field required'
    } else if( !isEmail(value.trim()) ){
        result.isValid = false
        result.error = 'Email address is invalid'
    } else if(isSmileDirectClubEmail(value.trim()) === false){
        result.isValid = false
        result.error = 'Email address is not from smiledirectclub'
    }

    return result
}

export const checkValueRequiredEmailList = (value:string) => {

    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    if ( !value.trim() ) {
        result.isValid = false
        result.error = 'This field required'
    }else{

        const delimeter = [ ',' , '\n' ]

        const delimeterTest = delimeter.map((testValue, index)=>{
            
            const testResult = value.split(testValue)

            return({
                index,                    // номер разделителя в исходном массиве
                delimeter: testValue,     // какой разделитель
                count: testResult.length, // количество разделений
                result: testResult
            })
        })

        let delimeterIndex = -1
        for(let i = 0; i< delimeterTest.length; i++){
            if(delimeterTest[i].count > 1){
                delimeterIndex = i
            }
        }

        if( delimeterIndex === -1){
            // may be single value in list
            if( !isEmail(value.trim()) ){
                result.error = 'Adresses doesn\'t valid'
                result.isValid = false
            }else if(isSmileDirectClubEmail(value.trim()) === false){
                result.isValid = false
                result.error = 'Email address is not from smiledirectclub'
            }
        }else{

            const resultArray = delimeterTest[delimeterIndex].result.filter(
                item => (isEmail(item.trim()) && isSmileDirectClubEmail(item.trim()))===false    
            )

            if(resultArray.length>0){
                result.isValid = false
                result.error = 'This adresses not valid: ' + resultArray.join(',') + '.'
            }
        }
    }
    return result
}

export const checkValueRequiredUUID = (value:string) => {
    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    
    if( !value.trim()){
        result.isValid = false
        result.error = 'This field required'
    } else if ( !checkUuid(value) ){
        result.isValid = false
        result.error = 'Wrong UUID'
    }

    return result
}

export const checkStringArray = ( value: string) =>{
    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }

    const splittedValue = value.split(',')
    
    splittedValue.forEach((item)=>{
        if(result.isValid === true){
            if(item.trim()===''){
                result.isValid = false
                result.error = 'One of the values is empty'
            }
        }
    })

    return result
} 

export const checkValueJsonFormat = (value:string) => {
    const result:TCheckResult = {
        isValid : true,
        error   : '',
    }
    
    if( !value.trim()){
        result.isValid = false
        result.error = 'This field required'
    }

    try{
        JSON.parse(value)

    } catch(e){
        result.isValid = false
        result.error = 'Wrong JSON-format'
    }
    return result
}

const useValidation = (rules:enumFormValidationRule[], defaultValue?:string ): TFormValidationResult => {
    
    const [value  , setValue  ] = useState( defaultValue ? defaultValue : '')
    const [isInit , setIsInit ] = useState(true)

    const onValueChange = (changedValue: string, isInit:boolean = false) =>{
        setIsInit(isInit)
        setValue(changedValue)
    }

    const validationResult = useMemo(()=>{
        let checkResult:TCheckResult = {
            isValid : true,
            error   : '',
        }

        if(isInit!==true){
            for(let i = 0; i < rules.length; i++){

                switch (rules[i]) {

                    case enumFormValidationRule.REQUIRED:
                        checkResult = checkValueRequired(value)
                        break
                    
                    case enumFormValidationRule.EMAIL:
                        checkResult = checkValueRequiredEmail(value)
                        break
        
                    case enumFormValidationRule.EMAIL_LIST:
                        checkResult = checkValueRequiredEmailList(value)
                        break
                    
                    case enumFormValidationRule.UUID:
                        checkResult = checkValueRequiredUUID(value)
                        break

                    case enumFormValidationRule.STRING_ARRAY:
                        checkResult = checkStringArray(value)
                        break

                    case enumFormValidationRule.JSON_FORMAT:
                        checkResult = checkValueJsonFormat(value)
                        break
                        
                    default:
                        break
                }
            }    
        }

        let result:TFormValidationResult = [
            value,
            checkResult.isValid,
            checkResult.error,
            onValueChange
        ]
        return(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[value, isInit])

    return(validationResult)
}

export default useValidation
