import { Box, FormControl } from "@mui/material"
import { useEffect, useRef, MutableRefObject } from 'react'
import getCommentsByCaseId from "../../../../Api/CaseManagement/v2/get-case-comments"
import formatDateToLocal from "../../../../Utils/format-date-to-local"
import { Button } from "@mui/joy"

import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import postCreateCaseComment from "../../../../Api/CaseManagement/v2/post-create-case-comment"
import useValidation, { enumFormValidationRule } from "../../../formValidator"
import { useAction } from "../../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import deleteCaseComment from "../../../../Api/CaseManagement/v2/delete-case-comment"
import putEditCaseComment from "../../../../Api/CaseManagement/v2/put-edit-case-comment"
import useComments, { defaultState } from "./useComments"
import { CircularProgress } from "@mui/material"

type TCommentsProps = {
    caseId: string | undefined
}

const Comments = (props:TCommentsProps) =>{
    
    const { caseId } = props
    const textAreaRef = useRef<MutableRefObject<HTMLTextAreaElement>>(null)

    const [
        comment,
        isCommentValid,
        ,
        onCommentChange
    ] = useValidation([enumFormValidationRule.REQUIRED], '')

    const { setCaseManagementError , setCaseManagementErrorNeedToShow } = useAction()
    const { email } = useTypedSelector(store=>store.base.userInfo)

    const {
        isLoaded,
        isNeedUpdateFromServer,
        isNeedSendToServer,
        isNeedToDelete,
        currenCommentId,
        comments,
        setNeedUpdateFromServer,
        setNeedSendToServer,
        setNeedDeleteComment,
        setComments,
        setCurrentComment,
        setLoaded,
    } = useComments(defaultState)

    const textPanelBottomRef = useRef<any>(null)

    useEffect(() => {
        if(textPanelBottomRef.current){
            textPanelBottomRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    },[comments])

    // DELETE COMMENT
    // user can delete only own comments
    useEffect(()=>{
        if(isNeedToDelete === true && caseId && currenCommentId){
            setLoaded(false)
            deleteCaseComment({
                caseId,
                commentId: currenCommentId
            })
            .then((resonse)=>{
                setNeedUpdateFromServer(true)
            })
            .catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT DELETE CASE COMMENT"
                })
                setCaseManagementErrorNeedToShow(true)
                console.log("CANT DELETE CASE COMMENT", error, error.request)
            })
            .finally(()=>{
                setLoaded(true)
            })
            setNeedDeleteComment(false, undefined)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToDelete])

    // EDIT COMMENT
    // user can edit only own comments
    useEffect(()=>{
        if(currenCommentId && isNeedToDelete === false){
            for(let i = 0; i<comments.length; i++){
                if(comments[i].id === currenCommentId){
                    onCommentChange(comments[i].content, true)
                    
                    if(textAreaRef.current){
                        const input = textAreaRef.current as any
                        input.focus()
                    }
                    break
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[currenCommentId])

    // SEND EDITED COMMENT
    useEffect(()=>{
        if(isNeedSendToServer === true && currenCommentId && caseId && comment){
            setLoaded(false)
            setNeedSendToServer(false)
            onCommentChange('', true)

            putEditCaseComment({
                caseId,
                commentId: currenCommentId,
                content: comment,
            })
            .then((response)=>{
                setNeedUpdateFromServer(true)
                onCommentChange('',true)
                setCurrentComment(undefined)
            })
            .catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT UPDATE CASE COMMENT"
                })
                setCaseManagementErrorNeedToShow(true)
                console.log("CANT UPDATE CASE COMMENT", error, error.request)
            })
            .finally(()=>{
                setLoaded(true)
            })

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedSendToServer])

    // SEND NEW COMMENT
    useEffect(()=>{
        if(isNeedSendToServer === true && !currenCommentId && caseId && comment){
            setLoaded(false)
            setNeedSendToServer(false)
            onCommentChange('', true)

            postCreateCaseComment({
                caseId,
                content: comment
            })
            .then((resonse)=>{
                setNeedUpdateFromServer(true)
            })
            .catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT SEND CASE COMMENTS"
                })
                setCaseManagementErrorNeedToShow(true)
                console.log("CANT SEND CASE COMMENTS", error, error.request)
            })
            .finally(()=>{
                setLoaded(true)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedSendToServer])

    // GET COMMENTS
    useEffect(()=>{
        if(caseId && isNeedUpdateFromServer === true){
            setLoaded(false)
            setNeedUpdateFromServer(false)

            getCommentsByCaseId({
                caseId
            })
            .then((response)=>{
                setComments(response.data.items)
            })
            .catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                })
                setCaseManagementErrorNeedToShow(true)
                console.log("CANT GET CASE COMMENTS", error, error.request)
            })
            .finally(()=>{
                setLoaded(true)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[caseId, isNeedUpdateFromServer])

    return(
        <Box
            sx={{
                width: '600px',
                minHeight: 'calc( 100vh - 280px)',
                border: '2px solid #ddd',
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between',
                fontSize: '11px',
            }}
        >
            <Box
                // text messages area
                sx={{
                    height: 'calc( 100vh - 380px)',
                    overflow: 'auto',
                    
                    '::-webkit-scrollbar':{
                        width: '10px',
                    },
                    '::-webkit-scrollbar-thumb':{
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                        backgroundColor: '#5700ff',
                        borderRadius: '5px'
                    },
                    '::-webkit-scrollbar-track': {
                        backgroundColor: '#ccc'
                    }
                }}
            >
                {
                    
                    
                    isLoaded === true ?
                        (
                            comments.length === 0 ?
                                <Box sx={{
                                    width: '100%',
                                    height: '300px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '12px',
                                }}>
                                    <Box>
                                        There are no comments
                                    </Box>
                                </Box>
                            :
                                <Box>
                                {
                                    comments.map((commentItem, commentIndex)=>{
                                        return(
                                            <Box
                                                key={
                                                    `comment-${commentIndex}-${commentItem.id}`
                                                }
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    margin: '2px 0 5px 0',
                                                    border: '2px solid #fff',
                                                    padding: '4px',
                                                    background: 'linear-gradient(to bottom right, #eee, #fff)',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    <Box sx={{
                                                        fontWeight: '700',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}>
                                                        <div>
                                                            { formatDateToLocal(commentItem.createdAt) }
                                                        </div>
                                                        
                                                        {
                                                            commentItem.createdBy === email &&
                                                            <Box 
                                                                sx={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        border: '1px solid #5700ff66',
                                                                        height: '16px',
                                                                        borderRadius: '4px',
                                                                        cursor: 'pointer',
                                                                        margin: '0 10px 0 0',
                                                                        ':hover':{
                                                                            backgroundColor: '#5700ff66',
                                                                        }
                                                                    }}
                                                                    onClick={()=>{
                                                                        setCurrentComment(commentItem.id)
                                                                    }}
                                                                >
                                                                    <EditIcon
                                                                        sx={{
                                                                            width: '14px',
                                                                            height: '14px',
                                                                            fill:'#5700ff'
                                                                        }}
                                                                    />
                                                                </Box>
                                                                
                                                                <Box
                                                                    sx={{
                                                                        border: '1px solid #5700ff66',
                                                                        height: '16px',
                                                                        borderRadius: '4px',
                                                                        cursor: 'pointer',
                                                                        ':hover':{
                                                                            backgroundColor: '#5700ff66',
                                                                        }
                                                                    }}
                                                                    onClick={()=>{
                                                                        setNeedDeleteComment(true, commentItem.id )
                                                                    }}
                                                                >
                                                                    <ClearIcon
                                                                        sx={{
                                                                            width: '14px',
                                                                            height: '14px',
                                                                            fill:'#5700ff'
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        }
                                                        
                                                    </Box>
                                                    <Box>
                                                        { commentItem.createdBy }
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    padding: '8px',
                                                }}>
                                                    { commentItem.content}
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                                <div ref={textPanelBottomRef} />
                            </Box>
                        )
                    :
                        <Box sx={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <CircularProgress />
                        </Box>
                }
            </Box>


            {/* MESSAGE EDITOR */}
            <Box
                sx={{
                    width: '100%',
                    height: '100px',
                }}
            >

                <FormControl
                    error={!isCommentValid}
                >
                    <TextField
                        inputRef = { textAreaRef }
                        placeholder="Type your comment here…"
                        multiline
                        minRows={2}
                        maxRows={2}
                        InputProps={{
                            endAdornment:(
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 'var(--Textarea-paddingBlock)',
                                        pt: 'var(--Textarea-paddingBlock)',
                                        justifyContent: 'flex-end',
                                    }}
                                >

                                    {
                                        currenCommentId &&
                                        <Button 
                                            sx={{ 
                                                margin: '0 10px 0 0',
                                                backgroundColor: '#eee',
                                                color: '#5700ff',
                                                ':hover':{
                                                    backgroundColor: '#fff'
                                                }
                                            }}

                                            onClick={()=>{
                                                setCurrentComment(undefined)
                                                onCommentChange('',true)
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                    
                                    <Button 
                                        disabled = { comment.trim().length === 0 }
                                        sx={{ 
                                            ml: 'auto', 
                                            backgroundColor: '#5700ff',
                                            ':hover':{
                                                backgroundColor: '#5700ff'
                                            }
                                        }}

                                        onClick={()=>{
                                            setNeedSendToServer(true)
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            )
                            
                        }}

                        sx={{
                            width: '600px',
                            fontSize: '10px',
                            border: 'none',
                            borderRadius: 'unset',
                            padding: '10px',
                            'div':{
                                'textarea':{
                                    fontSize: '12px'
                                }
                            }
                        }}
                        value={comment}
                        onChange={ (e)=>{ onCommentChange(e.target.value) } }
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

export default Comments
