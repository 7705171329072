const getIconColorByFilenameExtension = (rawFileName: string) =>{
    let result = '#5700ff'
    const filename = rawFileName.split('.')
    const imageTypes = ['png', 'jpg', 'jpeg','gif']
    const modelTypes = ['obj', 'stl']
    const zipTypes   = ['zip', 'rar', '7z']
    if( imageTypes.indexOf(filename[filename.length-1])>-1){
        result = '#88ff00'
    }

    if( modelTypes.indexOf(filename[filename.length-1])>-1){
        result = '#00cccc'
    }
    
    if( zipTypes.indexOf(filename[filename.length-1])>-1){
        result = '#cccc00'
    }
    return result
}

export default getIconColorByFilenameExtension
