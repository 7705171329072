import { TCaseFileDescritpion } from "../../../../../Types/CaseManagement"
import { TFileFormatToType } from "../SmileInspector"

const filterFileLinksBySupportedExtensions = ( fileLinks:TCaseFileDescritpion[], avalableExtensions:TFileFormatToType[] ) =>{
    let filteredFileLinks:TCaseFileDescritpion[] = []
    avalableExtensions.forEach((ext) =>{
        fileLinks.forEach((fileLink)=>{
            if(fileLink.fileName.indexOf(ext[1])>-1){
                filteredFileLinks.push(fileLink)
            }
        })
    })
    return(filteredFileLinks)
}

export default filterFileLinksBySupportedExtensions
