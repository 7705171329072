import { AxiosResponse } from "axios"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/lockCase

const postLockCase = async ( caseId: string ): Promise<AxiosResponse>  =>{
    return await API.post(
        `/v2/cases/${caseId}/locks`
    )
}

export default postLockCase
