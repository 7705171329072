import { AccessToken } from "@okta/okta-auth-js";
import { authClient } from "../App"

const getUserInfo = async (
    setBaseUserInfoFromAPI : (userInfo    : string) => void,
    setErrorCode           : (errorCode   : string) => void,
    setErrorSummary        : (errorSummary: string) => void,
) =>{

    const accessToken: AccessToken = await authClient.tokenManager.get('accessToken') as AccessToken
    await authClient.token.getUserInfo(accessToken)
    .then(idToken => {
        setBaseUserInfoFromAPI(idToken.email ? idToken.email : '')
    })
    .catch((e)=>{
        // ERROR DESCRIPTION:
        // {
        //     name:"AuthSdkError",
        //     errorCode:"INTERNAL",
        //     errorSummary:"getUserInfo requires an ID token object",
        //     errorLink:"INTERNAL",
        //     errorId:"INTERNAL",
        //     errorCauses:[]
        // }
        if(
            e.name === 'AuthSdkError' &&
            e.errorCode === 'INTERNAL'&&
            e.errorSummary === "getUserInfo requires an ID token object"
        ){
            // First visit or user was signed out
            authClient.token.getWithRedirect({
                responseType: ['code','id_token']
            }).catch((error)=>{
                console.log('GET TOKEN WITH REDIRECT ERROR', JSON.stringify(error))
                setErrorCode(error.errorCode)
                setErrorSummary(error.errorSummary)
            })
        }else{
            console.log('TOKEN MANAGER ERROR = ', e, JSON.stringify(e))
        }
        
    })
}

export default getUserInfo
