import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { useAction } from "../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { enumSORT } from "../../../Types/General"
import { TCaseManagementSortingBy } from "../../../Types/Store/StoreCaseManagement"
import colorLibrary from "../../../Utils/colors"
import { TCaseManagementTableHeaderId, TCaseManagementTableHederDescriptor } from "./caseManagementTableHeaderDescription"

export type TCaseManagementTableHeaderProps = {
    headerDescription: TCaseManagementTableHederDescriptor[]
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const CaseManagementTableHeader = (props:TCaseManagementTableHeaderProps) =>{
    const { headerDescription, setLoading } = props

    const { 
        setNeedUpdateCaseManagementFromAPI,
        setCaseManagementFilterData,
    } = useAction()

    const { filterData } = useTypedSelector(state => state.caseManagement)

    const getActiveHeader = (headerId:TCaseManagementTableHeaderId, sortBy?: TCaseManagementSortingBy) =>{
        let result = false
        switch(headerId){
            case 'createdBy':
                if(sortBy && sortBy === 'CREATED_BY' ){ result = true }
                break
            case 'createdAt':
                if(sortBy && sortBy === 'DATE_CREATED' ){ result = true }
                break
            case 'updatedAt':
                if(sortBy && sortBy === 'DATE_UPDATED' ){ result = true }
                break
            default:
                break
            
        }
        return result
    }
    const getSortBy = (headerId:TCaseManagementTableHeaderId):TCaseManagementSortingBy | undefined =>{
        let result
        switch(headerId){
            case 'createdBy':
                return 'CREATED_BY'

            case 'createdAt':
                return 'DATE_CREATED'
                
            case 'updatedAt':
                return 'DATE_UPDATED'
            default:
                break
        }
        return result
    }

    return(
        <TableHead
            sx={{
                backgroundColor: colorLibrary.backgroundColor,
            }}
        >
            <TableRow>
                {
                    headerDescription.map((item)=>{

                        return(
                            <TableCell
                                key={item.id}
                                align={ item.align }
                                padding='normal'
                            >
                                {
                                    item.isSortable ?
                                        <TableSortLabel
                                            active={ getActiveHeader(item.id, filterData.sortBy) }
                                            direction={ filterData.direction === enumSORT.ASC ? 'asc' : 'desc' }
                                            
                                            onClick={()=>{
                                            
                                                const isActive = getActiveHeader(item.id, filterData.sortBy)

                                                if( isActive ){
                                                    setCaseManagementFilterData({
                                                        ...filterData,
                                                        direction:  filterData.direction === enumSORT.ASC ?
                                                            enumSORT.DESC
                                                        :
                                                            enumSORT.ASC
                                                    })

                                                }else{
                                                    const newSort = getSortBy(item.id)
                                                    setCaseManagementFilterData({
                                                        ...filterData,
                                                        sortBy: newSort ? newSort : 'DATE_UPDATED' 
                                                    })
                                                }

                                                setNeedUpdateCaseManagementFromAPI(true)
                                                setLoading(true)
                                            }}

                                            sx={
                                                {
                                                    '&.MuiTableSortLabel-root': {
                                                        color: 'white',
                                                    },
                                                    '&.MuiTableSortLabel-root:hover': {
                                                        color: 'white',
                                                    },
                                                    '&.Mui-active': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiTableSortLabel-icon': {
                                                        color: 'white !important',
                                                    },
                                                    color: 'white',
                                                }
                                            }
                                        >
                                            { item.label }
                                            
                                        </TableSortLabel>
                                    :
                                        <Box sx={{color: 'white', cursor: 'default'}}>
                                            {
                                                item.label
                                            }
                                        </Box>
                                }
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        </TableHead>
    )
}

export default CaseManagementTableHeader
