import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { ITableHeaderDescription } from "../../../../Types/Components/table"
import { enumSORT, enumSortingField } from "../../../../Types/General"
import colorLibrary from "../../../../Utils/colors"

export type TSorting = {
    sortingField: enumSortingField
    sortingDirection: enumSORT
}

export type TConfigTableHeaderProps = {
    headerDescription : ITableHeaderDescription[]
    sorting: TSorting
    SetSortingDirection?: (sorting: TSorting) => void
    SetNeedUpdateFromAPI?: (isNeeded: boolean)=>void
    isCheckable?: boolean
}

const ConfigTableHeader = (props:TConfigTableHeaderProps) =>{
    
    const {
        headerDescription,
        sorting,
        SetSortingDirection,
        SetNeedUpdateFromAPI,
        isCheckable
    } = props

    const {
        sortingField,
    } =  sorting

    const avalableFields = headerDescription
        .filter(item => item.isSortable === true ? true : false)
        .map(item => item.id)

    return(
        <TableHead
            sx={{
                backgroundColor: colorLibrary.backgroundColor
            }}
        >
            <TableRow>
                {
                    isCheckable ?
                        <TableCell key={'key1'}></TableCell>
                    : null
                }
                
                {
                    headerDescription.map((item)=>{

                        return(

                            <TableCell
                                key     = {item.id}
                                align   = { item.numeric ? 'right' : 'left'}
                                padding = 'normal'
                                sx={{
                                    color: '#fff',
                                    cursor: 'default',
                                }}
                            >
                                {
                                    typeof(item.isSortable) !=='undefined' && item.isSortable === true ?
                                        <TableSortLabel
                                            active={ sorting.sortingField === item.id ? true : false }
                                            direction={ sorting.sortingDirection === enumSORT.ASC ? 'asc' : 'desc' }
                                            
                                            onClick={()=>{
                                                
                                                if(SetSortingDirection && SetNeedUpdateFromAPI ){
                                                    const indexOfSorting = avalableFields.indexOf(sorting.sortingField)
                                                    if( indexOfSorting > -1){
                                                        
                                                        if(sortingField === item.id){
                                                            SetSortingDirection({
                                                                sortingField: item.id,
                                                                sortingDirection: sorting.sortingDirection === enumSORT.ASC ? enumSORT.DESC : enumSORT.ASC
                                                            })
                                                        }else{
                                                            SetSortingDirection({
                                                                sortingField: avalableFields[indexOfSorting===0 ? 1 : 0],
                                                                sortingDirection: enumSORT.ASC
                                                            })
                                                        }
                                                    }
    
                                                    SetNeedUpdateFromAPI(true)
                                                }

                                            }}

                                            sx={
                                                {
                                                    
                                                    '&.MuiTableSortLabel-root': { color: 'white'},
                                                    '&.MuiTableSortLabel-root:hover': { color: 'white'},
                                                    '&.Mui-active': { color: 'white'},
                                                    '& .MuiTableSortLabel-icon': { color:   'white !important' },
                                                }
                                            }
                                        >
                                            { item.label }
                                            
                                        </TableSortLabel>
                                    :
                                        item.label
                                }
                                
                            </TableCell>
                        )
                    })

                }
            </TableRow>
        </TableHead>
    )
}

export default ConfigTableHeader
