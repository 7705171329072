const convertNodeIdToPath = (nodeId: string) =>{
    let result:string[] = []
    
    const splitNodeIdByType = nodeId.split('|')
    if(splitNodeIdByType.length === 2){
        const pathString = splitNodeIdByType[1]
        result = pathString.split('/').filter(item => item !== '')
    }
    return(result)
}

export default convertNodeIdToPath
