import { AxiosResponse } from "axios"
import { enumApiParameter } from "../../../Types/General"
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/update

export type TApiUpdateCaseStatusParams = {
    [enumApiParameter.CASE_ID           ] : string
    [enumApiParameter.STATUS            ] : string
    [enumApiParameter.SMO_VERSION       ] : string                      
}

const postUpdateCaseTransitionStatus = async ( params: TApiUpdateCaseStatusParams ): Promise<AxiosResponse<TCase>>  =>{
    return await API.post(
        `/v2/cases/${ params.caseId }/status`,
        {
            [ enumApiParameter.STATUS      ] : params[enumApiParameter.STATUS],
            [ enumApiParameter.SMO_VERSION ] : params[enumApiParameter.SMO_VERSION],
        }
    )
}

export default postUpdateCaseTransitionStatus
