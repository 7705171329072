import { Button } from "@mui/material"
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import getUrlForCaseFile from "../../../../Api/CaseManagement/v2/get-url-for-case-file"
import { downloadFileByUrlWithHref } from "./Utils/download-file"
import { toolbarButtonStyle } from "./FilesToolbar"

export type TFilesToolbarActionDownloadFileProps = {
    id       : string
    s3key    : string
    fileName : string
}

const FilesToolbarActionDownloadFile = (props:TFilesToolbarActionDownloadFileProps) => {

    const { id, s3key, fileName } = props

    return(
        <Button
            size="small"
            variant="outlined"
            sx={toolbarButtonStyle}
            startIcon={<DownloadForOfflineIcon />}
            onClick={()=>{
                getUrlForCaseFile({
                    id,
                    s3key,
                    attachment: true
                })
                .then((response)=>{
                    downloadFileByUrlWithHref(fileName ,response.data.fileLink)
                })
                .catch((error)=>{
                    console.log('CANT GET CASE FILE URL FROM API', JSON.stringify(error))
                })
            }}
        >
            Download
        </Button>
    )
}

export default FilesToolbarActionDownloadFile
