import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter                 } from "../../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20File%20API/getUploadLink

export type TApiPostCreateUrlForUploadingFileParams = {
    [enumApiParameter.ID           ]  : string
    [enumApiParameter.MIME_TYPE    ]? : string
    [enumApiParameter.PATH_IN_CASE ]? : string
    [enumApiParameter.FILE_NAME    ]? : string
}

export type TApiPostCreateUrlForUploadingFileResponse = {
    fileLink: string
    uuid: string
}

const postCreateUrlForUploadingFileToCase = async ( params: TApiPostCreateUrlForUploadingFileParams ): Promise<AxiosResponse<TApiPostCreateUrlForUploadingFileResponse>>  =>{
    return await API.post(
    
        `/v2/cases/${ params[enumApiParameter.ID    ] }/file-links`,
    
        { 
            [enumApiParameter.MIME_TYPE    ] : params[enumApiParameter.MIME_TYPE    ],
            [enumApiParameter.PATH_IN_CASE ] : params[enumApiParameter.PATH_IN_CASE ],
            [enumApiParameter.FILE_NAME    ] : params[enumApiParameter.FILE_NAME    ],
        }
    )
}

export default postCreateUrlForUploadingFileToCase
