import { useState, useEffect } from 'react'
import useInterval from './Utils/use-interval'
import getFileFromFileEntry from './Utils/get-file-from-fileEntry'
// for TypeScript typing (type guard function)
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards

export type FileDesc = {
    file: File
    path: string
}




const getFilesEntriesFormDirectory = (
    directoryEntry:FileSystemEntry, 
    setDroppedFilesEntrie:React.Dispatch<React.SetStateAction<FileSystemEntry[]>>, 
    level: number
) =>{
        
    const directoryEntries:FileSystemEntry[] = []
    const fileEntries:FileSystemEntry[] = []
    
    const directoryReader = (directoryEntry as FileSystemDirectoryEntry).createReader()

    // This thing have Promise behaviour, but it's not promise
    directoryReader.readEntries(
        (entries) => {
            entries.forEach((entry) => {
                    if(entry?.isFile === true){
                        fileEntries.push(entry)
                    }else if(entry?.isDirectory === true){
                        directoryEntries.push(entry)
                    }
                }
            )
            if(directoryEntries.length>0){
                directoryEntries.forEach(dirEntry=>{
                    getFilesEntriesFormDirectory(dirEntry,setDroppedFilesEntrie, level+1)
                })
            }
            setDroppedFilesEntrie(prev=>([...prev,...fileEntries]))
        },
        (error)=>{
            console.log('CANT READ FILE OR DIRECTORY', error)
        }
    )

}


const useDrag  = () =>{

    const [ isDragged, setDragged ] = useState(false)
    const [ droppedFilesDesc, setDroppedFilesDesc ] = useState<FileDesc[]>([])
    const [ droppedFilesEntrie, setDroppedFilesEntrie ] = useState<FileSystemEntry[]>([])

    const [ delayPause , setDelayPause  ] = useState<null|number>(null) // interval size

    const dragHandler = (e:React.DragEvent<HTMLLIElement|HTMLDivElement> ) =>{
        e.preventDefault()
        e.stopPropagation()
        setDragged(true)
    }

    const dragLeaveHandler = (e:React.DragEvent<HTMLLIElement|HTMLDivElement>) =>{
        e.preventDefault()
        e.stopPropagation()
        setDragged(false)
    }
    
    

    const dropHandler = (e:React.DragEvent<HTMLLIElement|HTMLDivElement>) =>{

        e.preventDefault()
        e.stopPropagation()
        setDragged(false)
        const supportsFileSystemAccessAPI = 'getAsFileSystemHandle' in DataTransferItem.prototype
        const supportsWebkitGetAsEntry = 'webkitGetAsEntry' in DataTransferItem.prototype
        if (!supportsFileSystemAccessAPI && !supportsWebkitGetAsEntry) {
            // Cannot handle directories.
            console.log('FileSystemAccessAPI and WebkitGetAsEntry is not supported')
            return
        }

        const fileEntries:FileSystemEntry[] = []
        for(let i = 0; i< e.dataTransfer.items.length; i++){
            const entryItem = e.dataTransfer.items[i].webkitGetAsEntry()
            if(entryItem?.isFile === true){
                fileEntries.push(entryItem)
            }
        }
        setDroppedFilesEntrie([...fileEntries])

        for(let i = 0; i< e.dataTransfer.items.length; i++){
            const entryItem = e.dataTransfer.items[i].webkitGetAsEntry()
            if(entryItem?.isDirectory === true){
                getFilesEntriesFormDirectory(entryItem,setDroppedFilesEntrie,0) 
            }
        }
    }
    
    const resetDroppedFilesList = () =>{
        setDroppedFilesDesc([])
    }
    
    useInterval(()=>{

        setDelayPause(null)

        const promissesArray = droppedFilesEntrie.map(fileEntrie=>{
            return(getFileFromFileEntry(fileEntrie))
        })
        /*
            FileEntry doesn't have file content and incorrectly processed by TypeScript
            So getFileFromFileEntry writed by JS
            File that we can get from FileEntry.file((resolve,reject)=>{...}) doesn't have full file path that we need for uploading
        */
        Promise.all(promissesArray).then(fileArray=>{
            
            const newFilesDescription:FileDesc[] = droppedFilesEntrie.map((fileEntrie, fileEntrieIndex)=>{
                return({
                    path: fileEntrie.fullPath,

                    file: fileArray[fileEntrieIndex]
                })
            })
            setDroppedFilesDesc(newFilesDescription)
        })
        .catch(error=>{
            console.log(' CANT GET FILES FROM FOLDERS', error)
        })
    }, delayPause)
    
    useEffect(()=>{ 
        if(droppedFilesEntrie.length!==0){
            setDelayPause(500)
        }
    },[droppedFilesEntrie])

    return({
        isDragged,
        droppedFilesDesc,
        dragHandler,
        dragLeaveHandler,
        dropHandler,
        resetDroppedFilesList,
        setDroppedFilesDesc,
    })
}

export default useDrag
