import * as BaseActionCreator                   from './base-actions'
import * as GroupsActionCreator                 from './groups-actions'
import * as DesktopConfigurationsActionCreator  from './desktop-configurations-actions'
import * as CaseManagementActionCreator         from './case-management-actions'
import * as CaseManagementUserHistoryActionCreator from './case-management-user-history-actions'
import * as MtpActionCreator                    from './mtp-actions'

const allActions = {
    ...BaseActionCreator,
    ...GroupsActionCreator,
    ...DesktopConfigurationsActionCreator,
    ...CaseManagementActionCreator,
    ...CaseManagementUserHistoryActionCreator,
    ...MtpActionCreator,
}
export default allActions
