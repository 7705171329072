import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter, enumSORT } from "../../../Types/General"
import { IDesktopUserConfig } from "../../../Types/Store/StoreDesktopConfig"

// https://adalisk.stoplight.io/docs/tp-api/b3A6Mzg5ODY2NDM-get-list-of-user-config-sort-by-udpdated-at

export type TApiGetListOfUserConfigSortByUpdatedAtParams = {

    [enumApiParameter.APPLICATION  ]?  : string
    [enumApiParameter.VERSION      ]?  : string
    [enumApiParameter.DIRECTION    ]? : enumSORT
    [enumApiParameter.PAGE_NUMBER  ]? : number
    [enumApiParameter.PAGE_SIZE    ]? : number
    [enumApiParameter.PERIOD_FROM  ]? : string
    [enumApiParameter.PERIOD_TO    ]? : string

}

export type TApiGetListOfUserConfigSortByUpdatedAtResponse = {
    content       : IDesktopUserConfig[]
    totalElements : number
    size          : number
    number        : number
}


const getListOfUserConfigSortByUpdatedAtV2 = async ( params: TApiGetListOfUserConfigSortByUpdatedAtParams ): Promise<AxiosResponse<TApiGetListOfUserConfigSortByUpdatedAtResponse>>  =>{
    const paramsCopy: TApiGetListOfUserConfigSortByUpdatedAtParams = {...params}
    
    delete paramsCopy[enumApiParameter.APPLICATION]
    delete paramsCopy[enumApiParameter.VERSION]

    for (const [key, value] of Object.entries(paramsCopy)){

        if(key === enumApiParameter.PAGE_NUMBER && typeof(value)==='undefined'){
            delete paramsCopy[enumApiParameter.PAGE_NUMBER]
        }
        if(key === enumApiParameter.DIRECTION && typeof(value)==='undefined'){
            delete paramsCopy[enumApiParameter.DIRECTION]
        }
        if(key === enumApiParameter.PAGE_SIZE && typeof(value)==='undefined'){
            delete paramsCopy[enumApiParameter.PAGE_SIZE]
        }
        if(key === enumApiParameter.PERIOD_FROM && typeof(value)==='undefined'){
            delete paramsCopy[enumApiParameter.PERIOD_FROM]
        }
        if(key === enumApiParameter.PERIOD_TO && typeof(value)==='undefined'){
            delete paramsCopy[enumApiParameter.PERIOD_TO]
        }
    }
    

    return await API.get(
        `/v2/configurations/users/${String(params.application).toLowerCase()}/${String(params.version).toLowerCase()}/by-updated`,
        { 
            params:{ ...paramsCopy} 
        }
    )
}

export default getListOfUserConfigSortByUpdatedAtV2
