import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseStatusTransition } from "./get-transition-from-status"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/getAllStatuses

const getCaseStatusTransitions = async (  ): Promise<AxiosResponse<TCaseStatusTransition[]>>  => {
    return await API.get(
        `/v2/case-status-transitions`
    )
}

export default getCaseStatusTransitions
