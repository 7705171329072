import axios from 'axios'

import { localAccessTokenChecker, tokenError401Handler } from '../interceptors'

export const CASEMANAGEMENT_API_URL = window['REACT_APP_CASEMANAGEMENT_API_URL'] ? window['REACT_APP_CASEMANAGEMENT_API_URL'] : process.env.REACT_APP_CASEMANAGEMENT_API_URL

const defaultOptions = {
    baseURL: CASEMANAGEMENT_API_URL,
    headers: {'Content-Type': 'application/json'}
}

const apiBase = axios.create(defaultOptions)
    
apiBase.interceptors.request.use(
    localAccessTokenChecker,
    tokenError401Handler
)

export default apiBase
