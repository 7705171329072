import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseStatusTransition } from "./get-transition-from-status"
import { enumApiParameter } from "../../../Types/General"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/create

export type TApiPostCreateStatusTransitionParams = {
    [enumApiParameter.STATUS          ]  : string
    initial                              : boolean
    transitions                          : string[]
}

const postCreateStatusTransition = async ( params: TApiPostCreateStatusTransitionParams ): Promise<AxiosResponse<TCaseStatusTransition>>  =>{
    return await API.post(
    
        `/v2/case-status-transitions`,
    
        { 
            ...params
        }
    )
}

export default postCreateStatusTransition
