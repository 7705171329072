import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from "react-router-dom"
import getMtpCaseByCaseId from '../../../Api/MobileTreatmentPlan/get-mtp-case-by-caseid'
import { useTypedSelector } from '../../../Store/Hook/use-typed-selector'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import MTPCaseView from './MTPCaseView'
import CircularProgress from '@mui/material/CircularProgress'
import { useAction } from '../../../Store/Hook/use-action'
import { showMtpError } from '../../../Store/Action/mtp-actions'
import MTPThreeView from './MTPThreeView'
import colorLibrary from '../../../Utils/colors'
const MTPCase = () =>{

    const { caseId } = useParams()

    const { caseDescription } = useTypedSelector(state => state.mtp)
    const { setMtpCase } = useAction()

    useEffect(()=>{
        getMtpCaseByCaseId({ caseId: caseId ? caseId : '' })
        .then((response)=>{
            setMtpCase( response.data )
        })
        .catch((error)=>{
            console.log('CANT GET MTP Case Data', JSON.stringify(error))
            showMtpError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT GET MTP Case Data"
            })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])



    return(
        <div>

            {/* ICON + HEADER */}
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                margin: '6px 0 0 10px'
            }} >
                <Box
                    sx={{
                        display: 'flex',

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <PhoneIphoneIcon
                            sx={{
                                height: 42,
                                width: 42,
                                fill: colorLibrary.backgroundColor,
                                margin: '0 16px 0 0',
                            }}
                        />
                        <h2> MTP Case  </h2>
                    </Box>
                </Box>

                {/* CASE META INFO */}
                <Box sx={{
                    width: '100%',
                    margin: '20px 0 0 0',
                }}>
                    {
                        caseDescription.mtpCase ?

                            <MTPCaseView mtpCase={ caseDescription.mtpCase }/>
                        
                            :
                            (
                                caseId ?

                                    <Box sx={{
                                        width: '100%', 
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                :
                                    null
                            )
                    }
                </Box>


                {/* FILE TREE */}
                <Box
                    sx={{
                        width: '100%',
                        margin: '20px 0 0 0',
                    }}
                >
                    <MTPThreeView  files={ caseDescription.mtpCase ? caseDescription.mtpCase.files : [] } />
                </Box>

            </Box>
        </div>
    )
}

export default MTPCase
