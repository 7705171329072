import InfoIcon from '@mui/icons-material/Info'
import { Box, SxProps, Theme, Modal, Typography, Button } from '@mui/material'
import { useMemo, useState } from 'react'
import FileTreeNodeActionInfoView from './FilesTreeNodeActionInfoView'
import { TTreeFileDescription } from './Utils/getFilesTree'
import { getIconByFiledescritpion } from './Utils/getIconByFiledescritpion'
import { getColorByStatus } from './Utils/getColorByStatus'

export type TCaseFileTreeActionProps = {
    fileDescription: TTreeFileDescription
    updatefiletree?: () => void
}

const iconStyle:SxProps<Theme> = {
    margin: '0 5px 0 0',
    height: '24px',
}
const CaseFileTreeActionInfo = (props:TCaseFileTreeActionProps) => {

    const { fileDescription } = props
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const fileIcon = useMemo(()=>{
        return(getIconByFiledescritpion(fileDescription))
    },[ fileDescription ])

    const headerColor = getColorByStatus(fileDescription)

    return(
        <>
            <Box onClick={ handleOpen } sx={iconStyle}>
                <InfoIcon
                    sx={{
                        fill: headerColor ? headerColor : '#08f',
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                onContextMenu = {(e)=>{
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'max-content',
                        bgcolor: '#fff',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
 
                >
                    <Typography  variant="h6" component="h2">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                component={fileIcon}
                                sx={{
                                    fill: headerColor ? headerColor : '#08f',
                                    margin: '0 20px 0 0',
                                    width: '64px',
                                    height: '64px',
                                }}
                            />
                            <Box sx={{
                                color: headerColor ? headerColor : '#08f',
                                fontSize: '24px',
                            }}>
                                { fileDescription.name }
                            </Box>
                        </Box>
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        <FileTreeNodeActionInfoView
                            extra={ Object.entries(fileDescription && fileDescription.fileDescription ? fileDescription.fileDescription : fileDescription) } 
                            exclude={['fileName','hash','mimeType','s3key']}
                        />
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button variant='outlined' onClick={handleClose}>
                            Close
                        </Button>
                        
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CaseFileTreeActionInfo
