import { Box } from "@mui/material"
import UserConfigsTable from "./Tables/UserConfigsTable"
import UserListFilters from "./ToolBar/UserListFilters"
import ListTools from "./ToolBar/ListTools"
import { enumConfigType } from "./Dialogs/CreateConfig"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import Warning from "./Dialogs/Warning"

const UserConfigs = () => {

    const { selectedApplicationVersion } = useTypedSelector(state => state.desktopConfigurations.users)

    return(
        <Box 
            sx={{
                margin: '0 10px 0 10px',
            }}
        >
            <UserListFilters />
            {
                selectedApplicationVersion && selectedApplicationVersion.name ?
                    <>
                        <ListTools configType={ enumConfigType.USER } />
                        <UserConfigsTable/>
                    </>
                :
                    <Warning
                        isEnabled = { true }
                    />
            }
            
        </Box>
    )
}

export default UserConfigs
