const clearLocalStorage = () =>{
    
    [
        'okta-cache-storage',
        'okta-shared-transaction-storage',
        'okta-token-storage',
    ].forEach((item)=>{
        localStorage.removeItem(item)
    })
}

export default clearLocalStorage
