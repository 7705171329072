import { useEffect, useMemo } from "react"
import { useParams, Link } from "react-router-dom"
import getGroup from "../../Api/UserGroups/get-group"
import { useAction } from "../../Store/Hook/use-action"
import { useTypedSelector } from "../../Store/Hook/use-typed-selector"
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import getUsersInGroup from "../../Api/UserGroups/get-users-in-group"
import { enumApiParameter } from "../../Types/General"
import deleteUserFromGroup from "../../Api/UserGroups/delete-user-from-group"
import AddUserToGroupDialog from './GroupAddUserDialog'
import GroupEdit from './GroupAddNew'
import deleteGroup from "../../Api/UserGroups/delete-group"
import ErrorModal from "./ErrorModal"
import AssignMultiply from './AssignMultiply'

import ReassignUserForm from './ReassignUser'
import formatDateToLocal from "../../Utils/format-date-to-local"
import colorLibrary from "../../Utils/colors"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: colorLibrary.backgroundColor , //theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))


const GroupItem = () =>{
    const { uuid } = useParams()
    const keySalt = Date.now()
    const { 
        setCurrentGroupFromAPI, 
        setCurrentUsersFromAPI, 
        setCurrentUsersPagination,
        setNeedUpdateUsersFromAPI,
        setNeedUpdateGroupsFromAPI,
    } = useAction()

    const {
        group,
        users,
        usersPagination,
        needUpdateGroupFromAPI
    } = useTypedSelector( state => state.groups.current)

    const { number, needUpdateDataFromAPI } = usersPagination

    useEffect(()=>{
        if(typeof(uuid)!=='undefined'){
            setCurrentGroupFromAPI(undefined) // erase previous group data
            getGroup({groupId: uuid})
            .then((response)=>{
                setCurrentGroupFromAPI(response.data)
            })
            .catch((error)=>{
                console.log('GET GROUP ERROR', error, error.response)
            })

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateGroupFromAPI])

    useEffect(()=>{
        if(typeof(uuid)!=='undefined'){
            if(needUpdateDataFromAPI === true){
                getUsersInGroup({
                    groupId: uuid,
                    [enumApiParameter.DIRECTION  ]: usersPagination.userSorting,
                    [enumApiParameter.PAGE_NUMBER]:                 number,
                    [enumApiParameter.PAGE_SIZE  ]: usersPagination.size,
                })
                .then((response)=>{
                    setCurrentUsersFromAPI({
                        users: response.data.content,
                        usersPagination: {
                            needUpdateDataFromAPI: false,
                            userSorting: usersPagination.userSorting,
                            number: response.data.number,
                            size: response.data.size,
                            totalElements: response.data.totalElements,
                        }
                    })
                })
                .catch((error)=>{
                    console.log('GET GROUP ERROR', error, error.response)
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[number, needUpdateDataFromAPI])

    const onDeleteGroup = () =>{
        deleteGroup({groupId: uuid ? uuid : ''})
        .then((response)=>{
            setNeedUpdateGroupsFromAPI(true)
        }).catch((error)=>{
            console.log(`CANT REMOVE GROUP WITH UUID = ${uuid}`, error.response, error)
        })
    }


    const groupContent = useMemo (()=>{
        
        const noContent = null
        const lastElementsCount = usersPagination.totalElements % usersPagination.size

        return(
            <>
                <Box>
                    {
                        typeof(group)!=='undefined' ?
                            <>
                                <h2>Group: {group.name} </h2>
                                <Box sx={{ maxWidth: 700, display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button component={Link} to="/groups"  size="small" variant="outlined" color="success"
                                            onClick={()=>{ setNeedUpdateGroupsFromAPI(true) }}
                                        >
                                            Back to group list
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <GroupEdit editMode={true} uuid={ group.id } groupName={ group.name } groupDescription={group.description}/>

                                        {/* <Button  size="small" variant="outlined" color="success">Edit</Button> */}
                                        <Button  
                                            component = {Link} 
                                            to={"/groups"} 
                                            size="small" 
                                            variant="outlined" 
                                            color="warning"
                                            onClick={ onDeleteGroup }
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        :
                            null
                    }
                </Box>
                <Box sx={{ display: 'flex' }}>
                    {
                        
                        typeof(group)!=='undefined' ?
                        
                            
                            <Box sx={{ maxWidth: 700, border: `2px solid ${colorLibrary.backgroundColor}33`, marginTop: 2 }}>
                                

                                <Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        
                                            <TableRow
                                                key={`${group.name}-${keySalt}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Name
                                                </TableCell>

                                                <TableCell align="left">
                                                    { group.name }
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                key={`${group.id}-${keySalt}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Group Id
                                                </TableCell>

                                                <TableCell align="left">
                                                    { group.id }
                                                </TableCell>
                                            </TableRow>
                    
                                            <TableRow
                                                key={group.version}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Version
                                                </TableCell>

                                                <TableCell align="left">
                                                    { group.version }
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                key={group.description}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Description
                                                </TableCell>

                                                <TableCell align="left">
                                                    { group.description }
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                key={'1' + group.createdAt}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Created Date
                                                </TableCell>

                                                <TableCell align="left">
                                                    { formatDateToLocal(group.createdAt) }
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                key={'2' + group.updatedAt}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Updated Date
                                                </TableCell>

                                                <TableCell align="left">
                                                    { formatDateToLocal(group.updatedAt) }
                                                </TableCell>
                                            </TableRow>

                                    </TableBody>
                                </Table>
                            </Box>

                        :
                            noContent
                    }
                    
                </Box>

                <Box>
                    
                    <h2>Group users</h2>
                    
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                
                                <Pagination 
                                    variant="outlined" 
                                    shape="rounded"
                                    color="secondary"
                                    // sx={{ border: '2px solid red'}}
                                    page={usersPagination.number+1} 
                                    count={(usersPagination.totalElements - lastElementsCount)/usersPagination.size + ( lastElementsCount === 0 ? 0 : 1  )} 
                                    onChange={
                                        (e:any, clickedPageNumber:number)=>{ 
                                            setCurrentUsersPagination(clickedPageNumber-1)
                                        }
                                    }
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <AssignMultiply       groupId={ uuid }/>
                                <AddUserToGroupDialog groupId={ uuid } />
                            </Box>
                            
                        </Box>
                        <Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell > Login </StyledTableCell>
                                    <StyledTableCell> Added by </StyledTableCell>
                                    <StyledTableCell> Added at </StyledTableCell>
                                    <StyledTableCell> Actions </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                
                                    {
                                        users.map((item, index)=>{
                                            return(
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        { item.login }
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        { item.addedBy }
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        { formatDateToLocal(item.addedAt) }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Box sx={{display:'flex'}}>
                                                            <ReassignUserForm 
                                                                groupId = { uuid }
                                                                login   = { item.login}
                                                            />
                                                            <Button
                                                                size="small" 
                                                                variant="outlined" 
                                                                color="warning"
                                                                onClick = {()=>{
                                                                    deleteUserFromGroup({
                                                                        groupId: uuid ? uuid : '',
                                                                        login: item.login
                                                                    }).then(()=>{
                                                                        setNeedUpdateUsersFromAPI(true)
                                                                    }).catch((e)=>{
                                                                        console.log("CAN'T DELETE USER FROM GROUP", e.response, e)
                                                                    })
                                                                }}
                                                            >
                                                                Delete from group
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                            </TableBody>
                        </Table>
                    </Box>
                </Box>

            </>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[group, users, usersPagination])

    return(
        <div>
            { groupContent }
            <ErrorModal/>
        </div>
    )
}

export default GroupItem
