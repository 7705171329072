import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter, enumSORT } from "../../../Types/General"
import { IDesktopGroupConfig } from "../../../Types/Store/StoreDesktopConfig"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Group%20configuration%20API/list

export type TApiGetListOfGroupConfigByApplicationSortByUpdatedAtParams = {
    [enumApiParameter.APPLICATION  ]? : string
    [enumApiParameter.VERSION      ]? : string 
    [enumApiParameter.DIRECTION    ]? : enumSORT
    [enumApiParameter.PAGE_NUMBER  ]? : number
    [enumApiParameter.PAGE_SIZE    ]? : number
    [enumApiParameter.PERIOD_FROM  ]? : string
    [enumApiParameter.PERIOD_TO    ]? : string
}

export type TApiGetListOfGroupConfigByApplicationSortByUpdatedAtResponse = {
    content       : IDesktopGroupConfig[]
    totalElements : number
    size          : number
    number        : number
}

const getListOfGroupConfigSortByUpdatedAt = async ( params: TApiGetListOfGroupConfigByApplicationSortByUpdatedAtParams ): Promise<AxiosResponse<TApiGetListOfGroupConfigByApplicationSortByUpdatedAtResponse>>  =>{
    const requestParams: TApiGetListOfGroupConfigByApplicationSortByUpdatedAtParams= {...params}

    delete requestParams[enumApiParameter.APPLICATION]
    delete requestParams[enumApiParameter.VERSION]

    for (const [key, value] of Object.entries(requestParams)){
        if(key === enumApiParameter.DIRECTION && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.DIRECTION]
        }
        if(key === enumApiParameter.PAGE_NUMBER && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_NUMBER]
        }
        if(key === enumApiParameter.PAGE_SIZE && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_SIZE]
        }
        if(key === enumApiParameter.PERIOD_FROM && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_FROM]
        }
        if(key === enumApiParameter.PERIOD_TO && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_TO]
        }
    }

    return await API.get(
        `/v2/configurations/groups/${String(params[enumApiParameter.APPLICATION]).toLowerCase()}/${String(params[enumApiParameter.VERSION]).toLowerCase()}/by-updated`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getListOfGroupConfigSortByUpdatedAt
