import { AxiosResponse } from "axios"
import { enumApiParameter } from "../../../Types/General"
import API from '../api-base'

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/User%20configuration%20API/delete

export type TApiDeleteUserConfigParams = {
    [enumApiParameter.APPLICATION  ]  : string
    [enumApiParameter.VERSION      ]  : string
    [enumApiParameter.LOGIN        ]  : string
}


const deleteUserConfig = async ( params: TApiDeleteUserConfigParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/configurations/users/${String(params[ enumApiParameter.APPLICATION]).toLowerCase()}/${params[ enumApiParameter.VERSION]}/${params[ enumApiParameter.LOGIN]}`
    )
}

export default deleteUserConfig