import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import postReceiveProcessInstances from "../../../../Api/Workflow/Automation/post-receive-process-instances"
import { TProcessInstanceDescription } from "../../../../Types/Store/StoreCaseManagement"
import ProcessInstance from "./ProcessInstance"

type TAutomationHistoryProps = {
    caseId: string | undefined
}


const AutomationHistory = (props:TAutomationHistoryProps) => {
    const { caseId } = props
    const [ processInstancesDesc, setProcessInstancesDesc ] = useState<TProcessInstanceDescription[]>([])
    const [ isMiledCaseLoaded, setMildCaseLoaded ] = useState(false)

    useEffect(()=>{
        if(caseId){
            postReceiveProcessInstances({caseId})
            .then((response)=>{
                setMildCaseLoaded(true)
                setProcessInstancesDesc(response.data)
            })
            .catch((error)=>{
                console.log(error)
            })
        }

    },[caseId])

    return(
        <>
            {
                isMiledCaseLoaded === false && processInstancesDesc.length === 0 &&
                <Box sx={{
                    width: 'calc( 100% - 350px)', 
                    height: '426px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                }}>
                    <CircularProgress/>
                </Box>
            }

            {
                isMiledCaseLoaded === true && processInstancesDesc.length === 0 &&
                <Box sx={{
                    width: 'calc( 100% - 350px)', 
                    height: '426px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                }}>
                    Mild case info was not found
                </Box>
            }

            {
                isMiledCaseLoaded === true && processInstancesDesc.length > 0 &&
                <Box
                    sx={{
                        margin: '32px 0 0 0',
                        padding: '0 16px 0 16px'
                    }}
                >
                    {
                        processInstancesDesc.map((processDescItem, processDescItemIndex)=>{
                            return(
                                <ProcessInstance 
                                    key={`processDescItem-${processDescItemIndex}`}
                                    processDesc={processDescItem}
                                />
                            )
                        })
                    }
                </Box>
            }
        </>
    )
}

export default AutomationHistory
