import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTypedSelector } from '../../../../Store/Hook/use-typed-selector'
import getFileContents from '../Tables/get-file-content'
import useValidation, { enumFormValidationRule } from '../../../formValidator'
import { buttonContainedStyle } from '../../../../Utils/colors'
import { TApiPostCreateUpdateApplicationConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-application-config'
import { TApiPostCreateUpdateGroupConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-group-config'
import { TApiPostCreateUpdateUserConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-user-config'

type TCreateConfigurationDialog = {
    configType  : enumConfigType
    application : string
    appVersion  : string
    // configType  : enumConfigType
    // login       : string
    onCreateConfig(props:TApiPostCreateUpdateUserConfigParams | TApiPostCreateUpdateGroupConfigParams | TApiPostCreateUpdateApplicationConfigParams) : void
}

export enum enumDialogType {
    CREATE = 'ASSIGN',
    UPDATE = 'UPDATE',
}

export enum enumConfigType {
    APPLICATION = 'APPLICATION',
    GROUP = 'GROUP',
    USER = 'USER'
}

const CreateConfigurationDialog = (props: TCreateConfigurationDialog) => {

    const [ open                 , setOpen                     ] = useState(false)
    const [ isNeedToSendRequest  , setIsNeedToSendRequest      ] = useState(false)
    const [ fileName             , setFileName                 ] = useState<string>('')
    const [ groupListIndex       , setGroupListIndex           ] = useState( -1 )
    
    const [
        application,
        isApplicationValid,
        applicationValidationError,
        onApplicationChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        version,
        isVersionValid,
        ,
        onVersionChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        groupId,
        isGroupIdValid,
        groupIdValidationError,
        onGroupIdChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        userLogin,
        isUserLoginValid,
        userLoginValidationError,
        onUserLoginChange
    ] = useValidation([enumFormValidationRule.EMAIL])

    const [
        fileContent,
        isFileContentValid,
        fileContentValidationError,
        onFileContentChange

    ] = useValidation([enumFormValidationRule.REQUIRED])

    const { applicationsDescription } = useTypedSelector(store => store.desktopConfigurations)
    const { groupsList       } = useTypedSelector(store => store.desktopConfigurations.groups)

    const handleClose = () => {
        setOpen(false)
        onApplicationChange('',true)
        onVersionChange('', true)
        setFileName('')
        onFileContentChange('',true)
    }


    const handleClickOpen = () => {
        setOpen(true)

        onApplicationChange(props.application, true)
        onVersionChange(props.appVersion, true)
        onUserLoginChange('',true)
        onFileContentChange('',true)
    }

    const handleChange = (event: SelectChangeEvent) => {
        onApplicationChange(event.target.value as string)
    }

    function handleInputChange(e: any) {
        let file = e.target.files[0]
        if (file) {
            setFileName(file.name)
            getFileContents(file).then((gettedFileContent)=>{
                onFileContentChange(String(gettedFileContent))
            })
        }
    }
    
    const handleGroupSelectorChange = (event: SelectChangeEvent) => {
        for(let i = 0; i<groupsList.length; i++){
            if(groupsList[i].groupId === event.target.value as string){
                onGroupIdChange(groupsList[i].groupId) 
                setGroupListIndex(i)
                break
            }
        }
    }

    const performCreateAction = () =>{

        if( props.configType === enumConfigType.APPLICATION ){

            onApplicationChange(application)
            onFileContentChange(fileContent)

        }

        if( props.configType === enumConfigType.GROUP ){

            onApplicationChange(application)
            onGroupIdChange(groupId)
            setGroupListIndex(-1)
            onFileContentChange(fileContent)
            onVersionChange(version)
        }

        if( props.configType === enumConfigType.USER ){

            onApplicationChange(application)
            onVersionChange(version)
            onUserLoginChange(userLogin)
            onFileContentChange(fileContent)

        }


        setIsNeedToSendRequest(true)
    }

    

    useEffect(()=>{
        if(isNeedToSendRequest === true){

            if( props.configType === enumConfigType.USER ){

                if(isApplicationValid && isUserLoginValid && isVersionValid && isFileContentValid){
                    handleClose()
                    props.onCreateConfig({
                        application,
                        version,
                        login: userLogin,
                        data: fileContent
                    })
                }
            }


            if( props.configType === enumConfigType.GROUP ){
                if(isApplicationValid && isGroupIdValid && isVersionValid &&  isFileContentValid){
                    handleClose()
                    props.onCreateConfig({
                        application,
                        version,
                        groupId,
                        data: fileContent
                    })
                }
            }

            if( props.configType === enumConfigType.APPLICATION ){
                if(isApplicationValid && isFileContentValid && isVersionValid ){
                    handleClose()
                    setFileName('')
                    props.onCreateConfig({
                        application,
                        version,
                        data: fileContent
                    })
                }
            }

            setIsNeedToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    return(
        <>
            <Button
                variant = { "contained"     }
                size    = { "large"         }
                onClick = { handleClickOpen }
                sx      = { buttonContainedStyle }
            >
                { enumDialogType.CREATE }
            </Button>


            <Dialog open={open} onClose={handleClose}>
                
                <DialogTitle>
                    Assign configuration from JSON-file
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {
                            'Select type of software and JSON-file for  new config creation'
                        }
                    </DialogContentText>
                </DialogContent>

                <Box sx={{ margin: '20px 0 20px 0' }}>

                    <FormControl fullWidth error = { !isApplicationValid }
                        sx={{ padding: '20px'}}
                    >

            
                        
                        { 

                            <>
                                <InputLabel 
                                    id="software-selector-label"
                                    sx={{margin: '20px 0 0 20px'}}
                                >
                                        Software
                                </InputLabel>
                                <Select
                                    autoFocus
                                    labelId  = "software-selector-label"
                                    id       = "software-selector"
                                    value    = { String(application) }
                                    label    = "Software"
                                    onChange = { handleChange }
                                >
                                    {
                                        props.configType === enumConfigType.APPLICATION ?

                                            ['SmileOS', 'KoolShape', 'KoolMerge'].map((item, index)=>{
                                                return(
                                                    <MenuItem value={ item } key = {`${item}-${index}`}>
                                                        { item }
                                                    </MenuItem>
                                                )
                                            })
                                        :
                                            applicationsDescription.map((item, index)=>{
                                                return(
                                                    <MenuItem value={ item.name } key = {`${item.name}-${index}`}>
                                                        { item.name }
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </>
                        }

                        
                        <FormHelperText>
                            { applicationValidationError }
                        </FormHelperText>

                    </FormControl>

                    <FormControl fullWidth error={!isVersionValid}
                        sx={{ padding: '0 20px 20px 20px'}}
                    >
                        <TextField
                            margin   = "none"
                            id       = "appVersion"
                            sx       = {{margin: 0 }}
                            label    = { 'Version'  }
                            type     = "text"
                            variant  = "outlined"
                            value    = { version }
                            error    = {!isVersionValid}
                            onChange = {(e)=>{
                                onVersionChange(e.target.value) 
                            }}
                        />
                        <FormHelperText> { userLoginValidationError } </FormHelperText>
                    </FormControl>

                    {
                        
                        props.configType === enumConfigType.GROUP ?
                            <FormControl fullWidth error = { !isGroupIdValid }
                                sx={{ padding: '0 20px 20px 20px'}}
                            >
                                <InputLabel
                                    sx={{margin: '0 0 0 20px'}} 
                                    id="group-selector-label"
                                >
                                    Group
                                </InputLabel>

                                <Select
                                    labelId="group-selector-label"
                                    id="group-selector"
                                    value={ groupsList.length > 0 && groupListIndex>=0  ? String(groupsList[groupListIndex].groupId) : '' }
                                    label="Group"
                                    onChange={handleGroupSelectorChange}
                                    disabled={ 
                                        props.configType === enumConfigType.GROUP ? false : true
                                    }
                                >
                                    {
                                        groupsList.map((item, index)=>{
                                            return(
                                                <MenuItem value={ item.groupId } key = {`${item}-${index}`}>
                                                    { item.groupName }
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>

                                <FormHelperText>
                                    { groupIdValidationError }
                                </FormHelperText>
                            </FormControl>
                        : null
                    }

                    {
                        props.configType === enumConfigType.USER ?
                            <FormControl fullWidth error={!isUserLoginValid}
                                sx={{ padding: '0 20px 20px 20px'}}
                            >
                                <TextField
                                    margin   = "none"
                                    id       = "userLogin"
                                    sx       = {{margin: 0 }}
                                    label    =  { 'User Login' }
                                    type     = "text"
                                    variant  = "outlined"
                                    value    = { userLogin }
                                    error    = {!isUserLoginValid}
                                    onChange = {(e)=>{
                                        onUserLoginChange(e.target.value) 
                                    }}
                                />
                                <FormHelperText> { userLoginValidationError } </FormHelperText>
                            </FormControl>
                        : null
                    }

                    <FormControl fullWidth error = { !isFileContentValid }
                        sx={{ padding: '0 20px 20px 20px'}}
                    >
                        <Box 
                            component="span"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: !isFileContentValid ? '1px solid red' : '1px solid #ccc',
                                borderRadius: 1,
                                padding: '4px',
                                margin: '0'
                            }}
                        >
                        
                            <div style={{
                                margin: '0 20px 0 20px',
                                color: !isFileContentValid ? 'red' : undefined, 
                            }}>
                                { fileName !== '' ? fileName : 'File not loaded' }
                            </div>
                        

                            <Button
                                variant="contained"
                                component="label"
                                size="large"
                                sx = { buttonContainedStyle }
                            >
                                Upload JSON
                                <input
                                    type="file"
                                    hidden
                                    onChange={ handleInputChange } 
                                    accept="text/json"
                                />
                            </Button>
                        </Box>
                        <FormHelperText> { fileContentValidationError } </FormHelperText>
                    </FormControl>


                </Box>

                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>

                    <Button onClick={performCreateAction}>
                        {
                            enumDialogType.CREATE
                        }
                    </Button>

                </DialogActions>

            </Dialog>
        </>
    )
}

export default CreateConfigurationDialog
