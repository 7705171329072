export type TCaseManagementStatusTransitionTableHeaderId = 'status' | 'transitions' | 'version' | 'initial'
export type TCaseManagementStatusTransitionTableHederDescriptor = {

    id        : TCaseManagementStatusTransitionTableHeaderId
    label     : string
    numeric   : boolean
    align?    : 'left' | 'right' | 'center' | 'justify'

}


export const tableHeaderDescription:TCaseManagementStatusTransitionTableHederDescriptor[] = [
    
    {
        id         : 'status',
        label      : 'Status',
        numeric    : false,
        align      : 'left',
    },
    {
        id: 'initial',
        label: 'Initial',
        align: 'center',
        numeric: false,
    },
    {
        id        : 'transitions',
        label     : 'Transitions',
        numeric   : false,
        align     : 'left',
    },

]
