import { useState, useEffect, SyntheticEvent } from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AppsIcon from '@mui/icons-material/Apps'
import ErrorModal from './Forms/ErrorModal'
import getAvalableApplicationsAndVersions from '../../Api/DesktopConfiguration/v2/get-avalable-applications-and-versions'
import UsersConfigs from './TabsComponents/UsersConfigs'
import { useAction } from '../../Store/Hook/use-action'
import GroupConfigs from './TabsComponents/GroupConfigs'
import ApplicationConfigs from './TabsComponents/ApplicationConfigs'


export type TDesktopConfigurationsProps = {

}

const DesktopConfigurations = (props: TDesktopConfigurationsProps) => {
    const [tabValue             , setTabValue         ] = useState(0)
    const [isNeedToUpdateFromApi, setNeedUpdateFromApi] = useState(true)
    
    const {
        SetApplicationsDescription
    } = useAction()


    useEffect(()=>{

        if(isNeedToUpdateFromApi === true){
            getAvalableApplicationsAndVersions()
            .then((response)=>{
                SetApplicationsDescription(response.data)
            })
            .catch((error)=>{
                console.log('CANT GET APPLICATIONS VERSIONS FROM API', JSON.stringify(error))
            })

            setNeedUpdateFromApi(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToUpdateFromApi])

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }
    
    return (
        <>
            <Tabs value={tabValue} onChange={handleChange} aria-label="icon label tabs example">
                <Tab icon={<AppsIcon       />} label="Applications" />
                <Tab icon={<PeopleAltIcon  />} label="Groups"       />
                <Tab icon={<AccountBoxIcon />} label="Users"        />
            </Tabs>

                {
                    tabValue === 0 ?
                        <>
                            <Typography variant="h5" component="div" sx={{ margin: '20px 0 0 10px', fontWeight: 'bold'}} >
                                List of configurations for specific applications
                            </Typography>
                            <ApplicationConfigs />
                        </>
                    :null
            
                }

                
            
                {
                    tabValue === 1 ?
                        <>
                            <Typography variant="h5" component="div" sx={{ margin: '20px 0 0 10px', fontWeight: 'bold'}} >
                                List of configurations for specific groups of users
                            </Typography>
                            <GroupConfigs />
                        </>
                    :null
                    
                }
            
                {
                    tabValue === 2 ?
                        <>
                            <Typography variant="h5" component="div" sx={{ margin: '20px 0 0 10px', fontWeight: 'bold'}} >
                                List of configurations for specific users
                            </Typography>
                            <UsersConfigs />
                        </>
                    :null
                    
                }

            <ErrorModal/>
        </>
    )
}

export default DesktopConfigurations
