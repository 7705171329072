import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Dispatch, SetStateAction } from "react"

export type TTransitionListProps = {
    transitionList: string[]
    selectedTransitions: string[]
    setSelectedTransitions: Dispatch<SetStateAction<string[]>>
}   

const TransitionList = ( props: TTransitionListProps ) => {
    const { 
        transitionList,
        selectedTransitions,
        setSelectedTransitions,
    } = props    


    const handleToggle = (transitionItem: string) => {
        setSelectedTransitions((prevSelectedTransitions)=>{
            
            let result = [...prevSelectedTransitions]
            if(prevSelectedTransitions.indexOf(transitionItem)>-1){
                result = result.filter(item=> item!==transitionItem)
            }else{
                result.push(transitionItem)
            }
            return(result)
        })
    }
    
    return (
        <List sx={{ width: '100%', maxWidth: 360 }}>
            {
                transitionList.map( (transitionItem , transitionIndex ) =>{
                    const labelId = `checkbox-transition-list-label-${transitionIndex}`
                    return (
                        <ListItem
                            key={labelId}
                            disablePadding
                        >
                            <ListItemButton 
                                role={undefined} 
                                onClick={()=>{
                                    handleToggle(transitionItem)
                                }}
                                dense
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={ selectedTransitions.indexOf(transitionItem)>-1 }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={ transitionItem } />
                            </ListItemButton>
                        </ListItem>
                    )
                })
                
            }
        </List>
    )
}

export default TransitionList
