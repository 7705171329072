import { Dispatch } from "redux"
import { enumSORT } from "../../Types/General"
import { 
    enumStoreMtpAction,
    TMtpActionHideError,
    TMtpActionSetCase, 
    TMtpActionSetEvaluatedKeys, 
    TMtpActionSetFilterData, 
    TMtpActionSetList, 
    TMtpActionSetListDirection, 
    TMtpActionSetListPageNumber, 
    TMtpActionSetListPageSize, 
    TMtpActionSetNeedUpdateCasesListFromAPI,
    TMtpActionShowError,
    TMtpCase,
    TStoreError,
    TStoreMTPFilterData
} from "../../Types/Store/StoreMtp"
import { TApiGetMtpCasesListWithCursorResponse } from "../../Api/MobileTreatmentPlan/get-mtp-cases-list-with-cursor"

export const setMtpCase = ( newCase: TMtpCase ) => {
    return((dispatch: Dispatch<TMtpActionSetCase>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_CASE,
            payload: newCase
        })
    })
}

export const showMtpError = ( error: TStoreError ) => {
    return((dispatch: Dispatch<TMtpActionShowError>) =>{
        dispatch({
            type: enumStoreMtpAction.SHOW_MTP_ERROR,
            payload: error
        })
    })
}

export const hideMtpError = ( ) => {
    return((dispatch: Dispatch<TMtpActionHideError>) =>{
        dispatch({
            type: enumStoreMtpAction.HIDE_MTP_ERROR
        })
    })
}

export const setMtpNeedUpdateCasesListFromAPI = (isNeedUpdate: boolean) => {
    return((dispatch: Dispatch<TMtpActionSetNeedUpdateCasesListFromAPI>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_NEED_UPDATE_CASES_LIST_FROM_API,
            payload : isNeedUpdate
        })
    })
}


export const setMtpList = (newListResponse: TApiGetMtpCasesListWithCursorResponse) => {
    return((dispatch: Dispatch<TMtpActionSetList>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST,
            payload : newListResponse
        })
    })
}

export const setMtpListPageNumber = (pageNumber: number) => {
    return((dispatch: Dispatch<TMtpActionSetListPageNumber>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST_PAGE_NUMBER,
            payload : pageNumber
        })
    })
}

export const setMtpListPageSize = (pageSize: number) => {
    return((dispatch: Dispatch<TMtpActionSetListPageSize>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST_PAGE_SIZE,
            payload : pageSize
        })
    })
}

export const setMtpListPageDirection = (direction: enumSORT) => {
    return((dispatch: Dispatch<TMtpActionSetListDirection>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST_DIRECTION,
            payload : direction
        })
    })
}

export const setMtpFilterData = (filterData: TStoreMTPFilterData) => {
    return((dispatch: Dispatch<TMtpActionSetFilterData>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST_FILTER_DATA,
            payload : filterData
        })
    })
}


export const setMtpListLastEvaluatedKeys = ( newEvaluatedKeys: string[] ) =>{
    return((dispatch: Dispatch<TMtpActionSetEvaluatedKeys>) =>{
        dispatch({
            type: enumStoreMtpAction.SET_MTP_LIST_EVALUATED_KEYS,
            payload : {
                lastEvaluatedKeys: newEvaluatedKeys
            }
        })
    })
}
