import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { useEffect, useState } from 'react'
import { useAction } from "../../../Store/Hook/use-action"
import BookOnlineIcon from '@mui/icons-material/BookOnline'
import { Box, CircularProgress } from "@mui/material"
import Paginaton from "../../CaseManagement/IndexPage/CaseManagementPaginaton"
import MTPCaseListTable from "./MTPCaseListTable"
import MtpFilterPanel from "./MtpFilterPanel"
import ErrorModal from "../ErrorModal"
import colorLibrary from "../../../Utils/colors"
import getMtpCasesListWithCursor from "../../../Api/MobileTreatmentPlan/get-mtp-cases-list-with-cursor"
import { TStoreMTPFilterData } from "../../../Types/Store/StoreMtp"
import { useSearchParams } from "react-router-dom"

const warningStyle = {
    width: 'calc( 100% - 350px)', 
    height: '426px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #ddd',
    borderRadius: '4px',
}

const MTPCaseList = () => {
    const { 
        needUpdateFromApi,
        list,
        lastPage,
        lastEvaluatedKey,
        filterData,
    } = useTypedSelector(state => state.mtp.mtpCasesList)

    const { error } = useTypedSelector(state => state.mtp)

    const { 
        setMtpNeedUpdateCasesListFromAPI,
        setMtpList,
        setMtpListPageNumber,
        setMtpListPageSize,
        showMtpError,
        hideMtpError,
        setMtpListLastEvaluatedKeys,
        setMtpFilterData,
    } = useAction()
    
    const [ isLoading      , setLoading         ] = useState(true)
    const [ isDataReceived , setDataReceived    ] = useState(false)
    const [ casesListLength, setCasesListLength ] = useState(list.length)
    
    let [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{ // browser "back" action fix
        if(list.length>0){
            setLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    const setAndShowRequestError = ( error: any, message: string ) =>{
        showMtpError({
            status  : error.response.data.status ? String(error.response.data.status) : '',
            path    : error.response.data.path   ? String(error.response.data.path)   : '',
            message : error.response.data.message ? String(error.response.data.message) : message
        })
        setMtpNeedUpdateCasesListFromAPI(true)
    }

        // STARTUP INITIALIZATION from URL
        useEffect(()=>{

            const searchfields = ['status','info','created_date','page_number','page_size','lastEvaluatedKey']
            const isSearchParamsInsideURL = searchfields.filter(field => searchParams.has(field)).length > 0 
            if( isSearchParamsInsideURL === true ){
    
                setMtpFilterData({
                    ...filterData,
                    status        : searchParams.get('status'      ) !== null ? String(searchParams.get('status'      ))                   : '',
                    info          : searchParams.get('info'        ) !== null ? String(searchParams.get('info'      ))                     : '',
                    'period.from' : searchParams.get('created_date') !== null ? String(searchParams.get('created_date')).split('|')[0]     : '',
                    'period.to'   : searchParams.get('created_date') !== null ? String(searchParams.get('created_date')).split('|')[1]     : '',
                    "page.number" : searchParams.get('page_number' ) !== null ? Number(searchParams.get('page_number' ))                   : 0,
                    "page.size"   : searchParams.get('page_size'   ) !== null ? Number(searchParams.get('page_size'   ))                   : filterData['page.size'],
                })
    
                setMtpListLastEvaluatedKeys(
                    searchParams.get('lastEvaluatedKey'   ) !== null ? [String(searchParams.get('lastEvaluatedKey'))] : [] 
                )
    
                setMtpNeedUpdateCasesListFromAPI(true)
                setLoading(true)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
        },[])

    useEffect(()=>{
        if(needUpdateFromApi === true){

            setDataReceived(false)

            setMtpNeedUpdateCasesListFromAPI(false)

            const requestParams:Partial<TStoreMTPFilterData> = {
                ...filterData
            }
            delete requestParams["page.number"]

            getMtpCasesListWithCursor({
                ...requestParams,
                lastEvaluatedKey: lastEvaluatedKey.length === 0 ? '' : lastEvaluatedKey[lastEvaluatedKey.length-1],
            })
            .then((response)=>{
                setMtpList(response.data)
                setMtpListLastEvaluatedKeys([...lastEvaluatedKey, response.data.lastEvaluatedKey])
                setCasesListLength(response.data.items.length)
                setDataReceived(true)
            })
            .catch((error)=>{
                setAndShowRequestError(error,  "CANT GET CASES BY FILTER")
            })
            .finally(()=>{
                setLoading(false)
            })

             // Add search params to URL inside browser adress-bar
            const newUrlParams: any = {}
            if(filterData.status !== ''){
                newUrlParams.status = filterData.status
            }

            if(filterData.info !== ''){
                newUrlParams.info = filterData.info
            }

            if(
                filterData["period.from"] !== '' &&
                filterData["period.to"  ] !== ''
            ){
                newUrlParams.created_date = `${filterData["period.from"]}|${filterData["period.to"]}`
            }

            newUrlParams['page.size'] = String(filterData['page.size'])
            newUrlParams['direction'] = String(filterData['direction'])
            newUrlParams.lastEvaluatedKey = lastEvaluatedKey.length === 0 ? '' : lastEvaluatedKey[lastEvaluatedKey.length-1]

            setSearchParams(newUrlParams)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateFromApi])

    return(
        <div>

            {/* ICON + HEADER */}
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                margin: '6px 0 0 0'
            }} >
                <Box
                    sx={{
                        display: 'flex',

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <BookOnlineIcon
                            sx={{
                                height: 42,
                                width: 42,
                                fill: colorLibrary.backgroundColor,
                                margin: '0 16px 0 0',
                            }}
                        />
                        <h2> MTP Cases  </h2>
                    </Box>
                </Box>
            </Box>

            <Paginaton 
                setPageNumber={ ( pageNumber:number ) => {

                    const pageDelta = filterData['page.number']-pageNumber
                    if(pageDelta === 1 ){
                        setMtpListLastEvaluatedKeys([...lastEvaluatedKey.slice(0,lastEvaluatedKey.length-2)])
                    }else if(pageDelta > 1){
                        setMtpListLastEvaluatedKeys([])
                    }

                    
                    setMtpListPageNumber(pageNumber)
                    setMtpNeedUpdateCasesListFromAPI(true) 
                    setLoading(true)
                }}
                setPageSize  ={ (pageSize:number)=> {
                    setMtpListPageSize(pageSize)
                    setMtpNeedUpdateCasesListFromAPI(true)
                    setLoading(true)
                }}
                currentPageNumber       = { filterData['page.number']!==undefined ? filterData['page.number'] : 0  }
                currentPageSize         = { filterData['page.size'  ]!==undefined ? filterData['page.size'  ] : 20 }
                lastRequestRecordsCount = { lastPage===true ? filterData['page.size'  ]-1 : casesListLength }
            />
            <Box
                sx={{display: 'flex'}}
            >
                <MtpFilterPanel 
                    setLoading={ setLoading }
                />
                {
                    isLoading === true &&
                    <Box sx={warningStyle}>
                        <CircularProgress/>
                    </Box>
                }

                {
                    isLoading === false && isDataReceived === true && casesListLength > 0 &&
                    <MTPCaseListTable
                        setLoading={ setLoading }
                    />
                }

                {
                    isLoading === false && isDataReceived === true && casesListLength === 0 &&
                    <Box sx={ warningStyle }>
                        Query result is empty
                    </Box>
                }

                {
                    isLoading === false && isDataReceived === false && casesListLength === 0 &&
                    <Box sx={ warningStyle }>
                        Query result was not received
                    </Box>
                }

            </Box>

            <ErrorModal
                header= { 'MTP Cases List Error' }
                error        = { error }
                errorControl = { hideMtpError } 
            />

        </div>
    )
}

export default MTPCaseList
