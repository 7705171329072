import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"

import FirstPageIcon from '@mui/icons-material/FirstPage'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import colorLibrary from "../../../Utils/colors"

export type TCaseManagementPaginatonProps = {
    setPageNumber: (pageNumber: number) => void
    setPageSize  : (pageSize  : number) => void
    currentPageNumber: number
    currentPageSize  : number
    lastRequestRecordsCount : number
}

export const paginationButtonStyle = {
    border: '1px solid #ddd',
    width: '32px',
    height: '32px',
    margin: '2px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    borderRadius: '4px', 
    cursor: 'pointer',
    transition: 'all .2s ease-out',
    '&:hover':{
        borderColor: colorLibrary.backgroundColor,
        backgroundColor: `${colorLibrary.backgroundColor}11`,
    }
}

const paginationButtonDisabledStyle = {
    ...paginationButtonStyle,
    '&:hover':undefined,
    fill: '#ddd',
    cursor: 'default',
}
const CaseManagementPaginaton = (props:TCaseManagementPaginatonProps) =>{
    
    const { currentPageNumber, currentPageSize,setPageSize, setPageNumber, lastRequestRecordsCount } = props
    const pageSizeVariant = [ 10, 20, 50, 100 ]

    return(
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 0 18px 8px'
            }}
        >

            <Box
                component={ FirstPageIcon}
                sx={ currentPageNumber !== 0 ? paginationButtonStyle : paginationButtonDisabledStyle }
                onClick={()=>{ if(currentPageNumber > 0 ) setPageNumber(0) }}
            />
            
            <Box
                component={ NavigateBeforeIcon}
                sx={ currentPageNumber !== 0 ? paginationButtonStyle : paginationButtonDisabledStyle }
                onClick={()=>{
                    if(currentPageNumber > 0 ) setPageNumber(currentPageNumber - 1)
                }}
            />
            
            <Box
                sx={{
                    ...paginationButtonStyle,
                    cursor: 'default',
                    '&:hover': undefined
                }}
            >
                {currentPageNumber + 1}
            </Box>
            
            <Box
                component={ NavigateNextIcon }
                sx={ lastRequestRecordsCount === currentPageSize ? paginationButtonStyle : paginationButtonDisabledStyle }
                
                onClick={()=>{
                    if(lastRequestRecordsCount === currentPageSize){
                        setPageNumber(currentPageNumber + 1)
                    }
                }}
            />

            <FormControl 
                sx={{ 
                    m: 0, minWidth: 80 }} 
                size="small"
            >
                <InputLabel 
                    id="set-page-size"
                    sx={{
                        fontSize: '12px',
                    }}
                >
                    PageSize
                </InputLabel>

                <Select
                    labelId="set-page-size"
                    value={currentPageSize}
                    label="Page Size"
                    onChange={(e)=>{ setPageSize(Number(e.target.value)) }}
                    sx={{
                        fontSize: '12px',
                    }}
                >
                    {
                        pageSizeVariant.map((sizeVariant, sizeVariantIndex)=>{
                            return(
                                <MenuItem 
                                    key={`page-size-variant-${sizeVariantIndex}`}
                                    value={sizeVariant}
                                >
                                    {
                                        sizeVariant
                                    }
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

export default CaseManagementPaginaton
