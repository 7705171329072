import { TUploadTreeNode, TUploadTreeProgressIndicator } from "../FilesTreeNodeDragAndDropUploader"

const convertUploadTreeProgressIndicatorToUploadNodesTree = (uploadIndicators:TUploadTreeProgressIndicator[], idPrefix?:string):TUploadTreeNode[] =>{
    const nodesTree:TUploadTreeNode[] = []
    
    uploadIndicators
    .forEach((uploadIndicator)=>{
        let newNode:TUploadTreeNode | undefined = undefined

        if(uploadIndicator.filePath.length === 1){ // FILE NODE WITHOUT CHILDS
            newNode = {
                nodId   : `FILE|${idPrefix ? idPrefix : ''}/${uploadIndicator.filePath[0]}`,
                name    : uploadIndicator.filePath[0],
                childs  : [],
                progress: uploadIndicator.progress,
                size    : uploadIndicator.size
            }

        } else if(uploadIndicator.filePath.length > 1){ // FOLDER WITH CHILDS

            const newPrefix:string = uploadIndicator.filePath[0]
            
            const nextLevel:TUploadTreeProgressIndicator[] = [...uploadIndicators]
            .filter((uploadInd)=>{
                return( uploadInd.filePath[0] === newPrefix )
            })
            .map((uploadIndicatorItem)=>{
                const newPath = [...uploadIndicatorItem.filePath]
                newPath.shift()
                return({
                    ...uploadIndicatorItem,
                    filePath: newPath,
                })
            })

            newNode = {
                nodId: `FOLDER|${idPrefix ? idPrefix : ''}/${newPrefix}`,
                name: newPrefix,
                childs: [...convertUploadTreeProgressIndicatorToUploadNodesTree(nextLevel,`${idPrefix ? idPrefix : ''}/${newPrefix}`)] ,
                progress: uploadIndicator.progress,
                size    : uploadIndicator.size
            }

        }

        let isNodeAlreadyExist = false
            
        nodesTree.forEach((nodeItem)=>{
            if(isNodeAlreadyExist === false){
                if( newNode && nodeItem.nodId === newNode.nodId){
                    isNodeAlreadyExist = true
                }
            }
        })

        if(newNode && isNodeAlreadyExist === false){
            nodesTree.push(newNode)
        }

    })

    return([
        ...nodesTree
            .filter(nodeItem=>nodeItem.nodId.indexOf('FOLDER|')>-1)
            .sort((a,b)=>(
                a.name.localeCompare(b.name)
            )),
        ...nodesTree
            .filter(nodeItem=>nodeItem.nodId.indexOf('FILE|')>-1)
            .sort((a,b)=>(
                a.name.localeCompare(b.name)
            ))
    ])
}

export default convertUploadTreeProgressIndicatorToUploadNodesTree
