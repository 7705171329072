import { Box, Typography, Modal, Button } from '@mui/material'
import { useMemo, useState } from 'react'
import deleteCaseFileByS3Key from '../../../../Api/CaseManagement/v2/delete-case-file-by-s3-key'
import { useAction } from '../../../../Store/Hook/use-action'
import colorLibrary from '../../../../Utils/colors'
import { TCaseFileDescription } from '../../../../Types/CaseManagement'
import getIconByFilenameExtension from '../../../../Utils/getIconByFilenameExtension'
import { toolbarButtonStyle } from './FilesToolbar'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import convertS3keyToPath from './Utils/convertS3keyToPath'

type TFilesToolbarActionDeleteProps = {
    fileDescription: TCaseFileDescription[] | undefined
    setNeedUpdateFileTree: ()=>void
    selectedNodes: string[]
}

type TDeleteCaseFilesParams = {
    fileDescription:  TCaseFileDescription[]
}

export const deleteCaseFiles = async (params:TDeleteCaseFilesParams) =>{
    const { fileDescription } = params
    const promissesArray = fileDescription.map((fileItem)=>{
        return(
            deleteCaseFileByS3Key({
                id: fileItem.caseId,
                s3key: fileItem.s3key,
            })
        )
    })
    return(
        await Promise.all(promissesArray)
    )
}

const FilesToolbarActionDelete = (props: TFilesToolbarActionDeleteProps) => {
    
    const { fileDescription, setNeedUpdateFileTree, selectedNodes } = props
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDelete = () =>{
        if(fileDescription){

            try{
                deleteCaseFiles({
                    fileDescription: 
                        fileDescription
                        .filter((fileItem)=>{
                            const filePath = convertS3keyToPath(fileItem.s3key).join('/')
                            let isFileSelected = selectedNodes.filter(item => item.indexOf(filePath) > -1).length > 0
                            return isFileSelected
                        }) 
                })
                setTimeout(()=>{
                    handleClose()
                    if(setNeedUpdateFileTree){
                        setNeedUpdateFileTree()
                    }
                },1500)
            } catch (error:any) {
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : `CANT DELETE FILE WITH S3Key FROM Case`
                })
                setCaseManagementErrorNeedToShow(true)
            }
        }
    }

    const filesListContent = useMemo(()=>{
        return(
            fileDescription ?
                fileDescription
                .filter((fileItem)=>{
                    const filePath = convertS3keyToPath(fileItem.s3key).join('/')
                    let isFileSelected = selectedNodes.filter(item => item.indexOf(filePath) > -1).length > 0
                    return isFileSelected
                })
                .map((fileItem)=>{
                    const fileIcon = getIconByFilenameExtension(fileItem.fileName)
                    return(
                        <Box
                            key={`delete-file-list-content-${fileItem.s3key}`}
                            sx={{display: 'flex', alignItems: 'center'}}
                        >
                            <Box
                                component={fileIcon}
                                sx={{
                                    fill: colorLibrary.backgroundColor,
                                    margin: '0 0 0 0',
                                    width: '24px',
                                    height: '24px',
                                }}
                            />
                            <Box sx={{
                                color: '#000',
                                fontSize: '12px',
                            }}>
                                { fileItem.fileName }
                            </Box>
                        </Box>
                    )
                })
            :
                <></>
        )
    },[fileDescription, selectedNodes])

    return(
        <>

            <Button
                size="small"
                variant="outlined"
                sx={toolbarButtonStyle} 
                startIcon={<DeleteForeverIcon />}
                onClick={ handleOpen }
            >
                Delete
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'max-content',
                    bgcolor: '#fff',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    

                    <Typography sx={{ margin : '0px 0 10px 0' }}>
                        Do you really want to delete this file{`${selectedNodes.length === 1 ? '' : 's' }`} ?
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            margin : '10px 0 20px 0',
                        }}
                    >
                        { filesListContent }
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                    
                </Box>
            </Modal>
        </>

    )
}

export default FilesToolbarActionDelete
