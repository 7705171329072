import { Box } from "@mui/material"
import { TProcessInstanceActivityDescription } from "../../../../../Types/Store/StoreCaseManagement"
import convertMsToHM from "../../../../../Utils/convertMillisecondsToHMS"
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { stillInProgress } from "../ProcessInstance"

type TActivityHeaderProps = {
    activityDescription: TProcessInstanceActivityDescription
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
    isExpanded: boolean
    level     : number
}

const ActivityHeader = (props:TActivityHeaderProps) =>{
    const { 
        activityDescription,
        isExpanded,
        setExpanded,
        // level
    } = props

    return(
        <Box
            sx={{
                display: 'flex',
                padding: '0 0 0 10px',
                color: '#666',
                width: '100%',
                '&:hover':{
                    cursor: 'pointer',
                },
            }}
            onClick={()=>{setExpanded((prev=>!prev))}}
        >
            
                <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: '14px'
                }}>
                    
                        {`${activityDescription.activityName}`} 
                        <Box sx={{
                            backgroundColor: '#ffff0066',
                            padding: '2px 4px',
                            margin: '0 8px 0 8px',
                            borderRadius: '10px',
                            fontWeight: 'normal',
                        }}>
                            {activityDescription.activityType} 
                        </Box>
                        {`${activityDescription.durationInMillis ? convertMsToHM(activityDescription.durationInMillis) : stillInProgress }`} 
                    
                </Box>
                <Box
                    component={ isExpanded=== false ? ControlPointIcon: RemoveCircleOutlineIcon}
                    sx={{
                        margin: '4px 8px',
                    }}
                />
        </Box>
    )
}

export default ActivityHeader
