import { useState, } from 'react'


export type TComment = {
    id        : string
    content   : string
    createdAt : string
    createdBy : string
    updatedAt : string
}

export type TCommentsState = {
    isLoaded               : boolean
    isNeedUpdateFromServer : boolean
    isNeedSendToServer     : boolean
    isNeedToDelete         : boolean
    isNeedToEdit           : boolean
    currenCommentId        : string | undefined
    comments               : TComment[]
}

export const defaultState:TCommentsState = {

    isLoaded              : false,
    isNeedUpdateFromServer: true,
    isNeedToDelete        : false,
    isNeedToEdit          : false,
    isNeedSendToServer    : false,
    currenCommentId       : undefined,
    comments              :[]
} 


const useComments = ( defaultState:TCommentsState) =>{
    const [state, setState] = useState<TCommentsState>(defaultState)

    const setNeedUpdateFromServer = (isNeedUpdate: boolean) =>{
        setState(prev=>({
            ...prev,
            isNeedUpdateFromServer:isNeedUpdate
        }))
    }
    const setNeedSendToServer = ( isNeedSendToServer: boolean ) =>{
        setState(prev=>({
            ...prev,
            isNeedSendToServer: isNeedSendToServer
        }))
    }
    const setComments = (newComments:TComment[]) =>{
        setState(prev=>({...prev, comments: newComments}))
    }

    const setNeedDeleteComment = ( isNeedToDelete: boolean ,commentId: string | undefined )=>{
        setState(prev=>({
            ...prev,
            isNeedToDelete,
            currenCommentId: isNeedToDelete === true ? commentId : undefined
        }))
    }

    const setCurrentComment = ( commentId: string | undefined) =>{
        setState(prev=>({
            ...prev,
            currenCommentId: commentId
        }))
    }

    const setLoaded = (isLoaded: boolean) =>{
        setState(prev=>({
            ...prev,
            isLoaded: isLoaded
        }))
    }

    return({
        isLoaded               : state.isLoaded,
        isNeedUpdateFromServer : state.isNeedUpdateFromServer,
        isNeedSendToServer     : state.isNeedSendToServer,
        isNeedToDelete         : state.isNeedToDelete,
        comments               : state.comments,
        currenCommentId        : state.currenCommentId,
        setNeedUpdateFromServer,
        setNeedSendToServer,
        setNeedDeleteComment,
        setComments,
        setCurrentComment,
        setLoaded,
    })
}

export default useComments
