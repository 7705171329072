import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Groups%20API/delete

export type TApiGetGroupParams = {
    [enumApiParameter.GROUP_ID ] : string 
}

const deleteGroup = async ( params: TApiGetGroupParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}`
    
    )
}

export default deleteGroup
