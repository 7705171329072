import ImageIcon from '@mui/icons-material/Image'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import ArticleIcon from '@mui/icons-material/Article'

const getIconByFilenameExtension = (rawFileName: string) =>{
    let iconComponent = ArticleIcon
    const filename = rawFileName.split('.')
    const imageTypes = ['png', 'jpg', 'jpeg','gif']
    const modelTypes = ['obj', 'stl']
    const zipTypes   = ['zip', 'rar', '7z']
    if( imageTypes.indexOf(filename[filename.length-1])>-1){
        iconComponent = ImageIcon
    }

    if( modelTypes.indexOf(filename[filename.length-1])>-1){
        iconComponent = ViewInArIcon
    }
    
    if( zipTypes.indexOf(filename[filename.length-1])>-1){
        iconComponent = FolderZipIcon
    }
    return iconComponent
}

export default getIconByFilenameExtension
