import { useEffect, useState } from "react"
import { Box, Button, FormControl, FormHelperText, Modal, TextField, Typography } from "@mui/material"
import AddBoxIcon from '@mui/icons-material/AddBox'
import useValidation, { enumFormValidationRule } from "../formValidator"
import postCreateConfiguration from "../../Api/Workflow/v2/post-create-configuration"
import { TApiErrorDescription } from "./ErrorModal"

type TCreateWorkflowConfigurationProps = {
    needUpdateFromApi: ()=>void
    setApiError: (params:TApiErrorDescription)=>void
}


const CreateWorkflowConfiguration = (props:TCreateWorkflowConfigurationProps) =>{

    const { needUpdateFromApi, setApiError } = props
    const [ isOpen, setOpen ] = useState(false)
    const [ isNeedToSendRequest, setNeedSendRequest ] = useState(false)

    const [
        localKey,
        isLocalKeyValid,
        localKeyError,
        onLocalKeyChange
    ] = useValidation([enumFormValidationRule.REQUIRED],'')

    const [
        localValue,
        isLocalValueValid,
        localValueError,
        onLocalValueChange
    ] = useValidation([enumFormValidationRule.REQUIRED],'')
    
    const [
        localDescription,
        isLocalDescriptionValid,
        localDescriptionError,
        onLocalDescriptionChange
    ] = useValidation([enumFormValidationRule.REQUIRED],'')

    const handleOpen = () =>{
        setOpen(true)
        onLocalValueChange('', true)
        onLocalDescriptionChange('', true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const handleApply = () =>{
        setNeedSendRequest(true)
    }

    useEffect(()=>{
        if(isNeedToSendRequest === true && 
            isLocalValueValid===true && 
            isLocalDescriptionValid===true && 
            isLocalKeyValid===true
        ){
            setNeedSendRequest(false)

            postCreateConfiguration({
                key: localKey,
                value: localValue,
                description: localDescription
            }).then((response)=>{
                
            })
            .catch((error)=>{
                console.log('CANT CREATE WORKFLOW CONFIGURATION', error)
                setApiError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT CREATE WORKFLOW CONFIGURATION"
                })
            })
            .finally(()=>{
                handleClose()
                needUpdateFromApi()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                borderTop: '1px solid #888',
                margin: '4px 8px',
                padding: '8px',
                fontWeight: 'bold',
            }}
        >
            <Button 
                startIcon={<AddBoxIcon />}
                variant="contained"
                onClick={handleOpen}
            >
                Add
            </Button>
            
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'max-content',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2"
                        sx={{
                            margin: '0 0 32px 0'
                        }}
                    >
                        Add new property
                    </Typography>
                    
                    <Box sx={{ minWidth: 420, margin: '8px 0 28px 0' }}>
                        
                        <FormControl fullWidth>
                            <TextField
                                size     = 'small'
                                type     =  "text"
                                variant  =  "outlined"
                                label    = { 'Key (unique)'   }
                                value    = { localKey               }
                                error    = {!isLocalKeyValid        }
                                onChange = { 
                                    (e)=>{ onLocalKeyChange(e.target.value) } 
                                }
                                fullWidth
                            />
                        </FormControl>
                        <FormHelperText> { localKeyError } </FormHelperText>
                    </Box>

                    <Box sx={{ minWidth: 420, margin: '8px 0 28px 0' }}>
                        
                        <FormControl fullWidth>
                            <TextField
                                size     = 'small'
                                type     =  "text"
                                variant  =  "outlined"
                                label    = { 'Property name'   }
                                value    = { localDescription               }
                                error    = {!isLocalDescriptionValid        }
                                onChange = { 
                                    (e)=>{ onLocalDescriptionChange(e.target.value) } 
                                }
                                fullWidth
                            />
                        </FormControl>
                        <FormHelperText> { localDescriptionError } </FormHelperText>
                    </Box>

                    <Box sx={{ minWidth: 420, margin: '8px 0 0 0' }}>
                
                        <FormControl fullWidth>
                            <TextField
                                size     = 'small'
                                type     =  "text"
                                variant  =  "outlined"
                                label    = { 'Value' }
                                value    = { localValue               }
                                error    = {!isLocalValueValid        }
                                onChange = { 
                                    (e)=>{ onLocalValueChange(e.target.value) } 
                                }
                                fullWidth
                            />
                        </FormControl>
                        <FormHelperText> { localValueError } </FormHelperText>
                    </Box>
                    
                    


                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '32px 0 0 0',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleApply}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </Box>
    )
}

export default CreateWorkflowConfiguration
