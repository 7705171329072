import { Box, Button, Menu, MenuItem } from "@mui/material"
import { AccountCircle } from '@mui/icons-material'
import { useState } from "react"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import getAccessTokenAnd from "../../Security/get-access-token-and"
import BoxCopy from "./BoxCopy"

type TUserMenuProps = {
    email: string | undefined
}

const menuItemStyle = {
    fontSize: '14px',
}



const UserMenu = (props:TUserMenuProps) =>{

    const { email } = props
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null)
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    return(
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'max-content',
                border: '1px solid #777',
                borderRadius: '8px',
                padding: '5px',
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    margin: '0 8px 0 0',
                }}
            >
                
                <Box
                    component={AccountCircle}
                />
                {
                    email
                }
            </Box>

            <Button
                variant="outlined"
                color="inherit"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={(e)=>{ 
                    setOpen(prev=>!prev)
                    handleMenu(e) 
                }}
            >
                <ArrowDropDownIcon />
            </Button>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                sx={{
                    fontSize: '12px',
                }}
            >

                <MenuItem
                    sx={menuItemStyle}
                    onClick={()=>{
                        navigator.clipboard.writeText(email ? email : '')
                        handleClose()
                    }}
                >
                    {
                        email
                    }
                    <BoxCopy />
                </MenuItem>

                <MenuItem 
                    sx={menuItemStyle}
                    onClick={()=>{
                        getAccessTokenAnd((tokenString: string)=>{ navigator.clipboard.writeText(tokenString ? tokenString : '') })
                        handleClose()
                    }}
                >
                    Access Token
                    <BoxCopy />
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu
