import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/Group%20configuration%20API/create_1

export type TApiPostCreateUpdateGroupConfigParams = {
    [enumApiParameter.APPLICATION  ]  : string
    [enumApiParameter.VERSION      ]  : string
    [enumApiParameter.GROUP_ID     ]  : string
    [enumApiParameter.DATA         ]  : string
}

const postCreateGroupConfig = async ( params: TApiPostCreateUpdateGroupConfigParams ): Promise<AxiosResponse>  =>{
    return await API.post(

        `/v2/configurations/groups/${String(params[enumApiParameter.APPLICATION])}/${params[enumApiParameter.VERSION]}/${params[enumApiParameter.GROUP_ID]}`,
        
        params.data
        
    )
}

export default postCreateGroupConfig
