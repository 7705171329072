import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/updateCase

export type TApiPatchUpdateCaseTagsParams = {
    [enumApiParameter.CASE_ID           ] : string
    [enumApiParameter.TAGS              ] : string[]
}

const patchUpdateCaseTags = async ( params:TApiPatchUpdateCaseTagsParams  ): Promise<AxiosResponse>  =>{
    return await API.patch(
        `/v2/cases/${params.caseId}`,
        { 
            tags: params.tags
        }
    )
}

export default patchUpdateCaseTags
