import { REDIRECTION_URL_KEY } from "./save-url-to-local-storage"

const getSavedUrlFromLocalStorage = ():string =>{

    const url = localStorage.getItem( REDIRECTION_URL_KEY )
    if(url){ localStorage.removeItem( REDIRECTION_URL_KEY ) }
    return(  url ? url : '' )

}

export default getSavedUrlFromLocalStorage
