
const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

const formatBytes = (bytes:number):string => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    // eslint-disable-next-line
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(i=== 0 ? 0 : 2) + " " + sufixes[i]
}

export default formatBytes
