import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl, 
    FormHelperText, 
    TextField,
} from "@mui/material"

type TEditValueDialogProps = {
    handleCloseDialog: ()=>void
    handleClickApply : ()=>void

    open : boolean
    title: string
    id   : string
    label: string

    value: string
    isValidValue: boolean
    valueError : string
    onChangeValue: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
}

const EditValueDialog = (props:TEditValueDialogProps) =>{

    const {
        open,
        handleCloseDialog,
        handleClickApply,
        title,
        id,
        value,
        label,
        isValidValue,
        valueError,
        onChangeValue,
    } = props

    return(
        <Dialog
            open={open}
            onClose={handleCloseDialog}
        >
            <DialogTitle>
                { title }
            </DialogTitle>

            <DialogContent>
                <Box sx={{ minWidth: 420, margin: '8px 0 0 0' }}>
                
                    <FormControl fullWidth>
                        <TextField
                            size     = 'small'
                            type     =  "text"
                            variant  =  "outlined"

                            id       = { id            }
                            label    = { label         }
                            value    = { value         }
                            error    = {!isValidValue  }
                            onChange = { onChangeValue }
                            fullWidth
                        />
                    </FormControl>
                    <FormHelperText> { valueError } </FormHelperText>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClickApply}>
                    Apply
                </Button>
                <Button onClick={handleCloseDialog} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditValueDialog
