import { Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const BoxCopy = () =>{
    return(
        <Box
            sx={{
                width: '20px',
                height: '20px',
                margin: '0 0 0 8px'
            }}
            component={ContentCopyIcon}
        />
    )
}

export default BoxCopy
