import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"
import { TMtpCase } from "../../Types/Store/StoreMtp"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=TP%20MTP%20service%20v1#/MobileTreatment%20Plan%20API/getById

type TApiGetMtpCaseByIdParams  = {
    [enumApiParameter.CASE_ID    ] : string
}

const getMtpCaseByCaseId = async ( params: TApiGetMtpCaseByIdParams ): Promise<AxiosResponse<TMtpCase>>  =>{
    return await API.get(
        
        `/mtps/${ params.caseId }`
    )
}

export default getMtpCaseByCaseId
