import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export type TApiErrorDescription = {
    status  : string
    path    : string
    message : string
}

type TErrorModalProps = {
    isNeedToShow: boolean
    setNeedToHide: ()=>void
    status    : string
    path      : string
    message   : string
}

const ErrorModal = (props:TErrorModalProps) => {

    const { 
        isNeedToShow, 
        message, 
        path, 
        setNeedToHide, 
        status 
    } = props

    const handleClose = () =>{
        setNeedToHide()
    } 

    return (
        isNeedToShow === true ? 
            <div>
                <Modal
                    open={isNeedToShow}
                    onClose={handleClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="error-modal-title" variant="h6" component="h2">
                            Error { ` ${ status }`}
                        </Typography>
                        
                        {
                            path ?
                                <Typography id="error-modal-description" sx={{ mt: 2 }}>
                                    { `Path: ${ path } `}
                                </Typography>
                            :
                                null
                        }

                        <Typography id="error-modal-description" sx={{ mt: 2 }}>
                            { `Message: ${ message }`}
                        </Typography>
                    </Box>
                </Modal>
            </div>
        : 
            null        
    )
}

export default ErrorModal
