import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.sass'
import { Provider as StoreProvider } from 'react-redux'
import { store } from './App/Store'
import { RouterProvider } from "react-router-dom"
import router from './App/Router'

const env = window['REACT_APP_ENV'] ? window['REACT_APP_ENV'] : process.env.REACT_APP_ENV
document.title = `${ env ? `[${env}] ` : '' }TP-Console`

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <StoreProvider store = { store } >
            <RouterProvider router={router} />
        </StoreProvider>
    </React.StrictMode>
)
