import { Box } from "@mui/material"
import { TProcessInstanceActivityDescription } from "../../../../../Types/Store/StoreCaseManagement"
import formatDateToLocal from "../../../../../Utils/format-date-to-local"
import convertMsToHM from "../../../../../Utils/convertMillisecondsToHMS"
import { stillInProgress } from "../ProcessInstance"

type TActivityInfoProps = {
    activityDescription: TProcessInstanceActivityDescription
}

const activitiInfoItemStyle = {
    display: 'flex',
    fontSize: '13px',
}

const itemHeaderStyle = {
    fontWeight: 'bold',
    padding: '0 8px 0 0',
}

const ActivityInfo = (props:TActivityInfoProps) =>{

    const { activityDescription } = props



    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                padding: '6px',
                borderRadius: '6px'
            }}
        >
            <Box
                sx={activitiInfoItemStyle}
            >
                <Box sx={itemHeaderStyle}>
                    Activity
                </Box>
                <Box>
                    { activityDescription.activityId }
                </Box>
            </Box>

            <Box
                sx={activitiInfoItemStyle}
            >
                <Box sx={itemHeaderStyle} >
                    Activity Type
                </Box>
                <Box>
                    { activityDescription.activityType }
                </Box>
            </Box>

            <Box
                sx={activitiInfoItemStyle}
            >
                <Box sx={itemHeaderStyle} >
                    Start Time
                </Box>
                <Box>
                    { formatDateToLocal(activityDescription.startTime) }
                </Box>
            </Box>

            <Box
                sx={activitiInfoItemStyle}
            >
                <Box sx={itemHeaderStyle} >
                    End Time
                </Box>
                <Box>
                    { formatDateToLocal(activityDescription.startTime) }
                </Box>
            </Box>

            <Box
                sx={activitiInfoItemStyle}
            >
                <Box sx={itemHeaderStyle} >
                    Duration
                </Box>
                <Box>
                    { activityDescription.durationInMillis ? convertMsToHM(activityDescription.durationInMillis) : stillInProgress }
                </Box>
            </Box>
        </Box>
    )
}

export default ActivityInfo
