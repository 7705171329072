import { Tab, Tabs } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState, SyntheticEvent } from "react"
import { useNavigate, useParams, useLocation, useMatch } from "react-router-dom"
import getCaseByCaseId from "../../../Api/CaseManagement/v2/get-case-by-case-id"
import { useAction } from "../../../Store/Hook/use-action"
import { enumApiParameter } from "../../../Types/General"
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import ErrorModal from "../ErrorModal"
import CaseView from "./CaseInfo/CaseView"
import CaseHistoryView from "./CaseHistoryView"
import checkUuid from "../../../Utils/check-uuid"
import SmileInspector from "./SmileInspector/SmileInspector"
import CasePropertiesView from "./CasePropertiesView"
import CaseFiles from "./CaseFiles/CaseFiles"
import Comments from "./Comments"
import AutomationHistory from "./AutomationHistory/AutomationHistory"
import postReceiveProcessInstances from "../../../Api/Workflow/Automation/post-receive-process-instances"

type TActiveTab = 'INFO'| 'HISTORY' | 'SMILE_INSPECTOR' | 'MILD_CASE_AUTOMATION' | 'COMMENTS'
export type TCasePropertyForUpdate = '' | 'all' | 'locked' | 'status'
export type TCaseProps = {
    activeTab?: TActiveTab
}

const tabHeightInPX = '26px'

const tabStyle = { 
    textTransform: 'none',
    border: '2px solid #f8f8f8',
    borderBottom: 'none',
    borderRight: 'none',
    ':last-child':{
        borderRight: '2px solid #f8f8f8',
    },
    height: tabHeightInPX,

}

const activeTabToIndex = (tabName?: TActiveTab): number => {
    switch(tabName) {
        case 'HISTORY'              : return 1
        case 'SMILE_INSPECTOR'      : return 2
        case 'MILD_CASE_AUTOMATION' : return 3
        case 'COMMENTS'             : return 4
        default: return 0
    }
}

const Case = (props:TCaseProps) => {
    

    const { activeTab } = props

    const pathMatchHistory    = useMatch( "case-management/case/:caseId/history"    )
    const pathMatchInspector  = useMatch( "case-management/case/:caseId/inspector"  )
    const pathMatchAutomation = useMatch( "case-management/case/:caseId/automation" )
    const pathMatchComments = useMatch( "case-management/case/:caseId/comments" )
    
    const defineTabFromUrl = ():TActiveTab  =>{
        if( pathMatchHistory    !== null ){ return 'HISTORY'              }
        if( pathMatchInspector  !== null ){ return 'SMILE_INSPECTOR'      }
        if( pathMatchAutomation !== null ){ return 'MILD_CASE_AUTOMATION' }
        if( pathMatchComments   !== null ){ return 'COMMENTS'             }
        return 'INFO'
    }

    const location = useLocation()

    const { caseId } = useParams()
    const { setCaseManagementError , setCaseManagementErrorNeedToShow } = useAction()
    const [ isNeedUpdateProperty   , setNeedUpdateProperty            ] = useState<TCasePropertyForUpdate>('')
    const [ currentCase            , setCurrentCase                   ] = useState<TCase | null>(null)
    const [ tabIndexValue          , setTabIndexValue                 ] = useState(activeTabToIndex(activeTab))
    const [ filesToOpenInSMI       , setFilesToOpenInSMI              ] = useState<string[]>([])
    const [ isAutomationStillActive, setAutomationStillActive         ] = useState(false)
    
    const history = useNavigate()
    
    const onTabClick = (event: SyntheticEvent, newValue: number) => {
        switch(newValue){
            case 0:
                history(`/case-management/case/${caseId}`, {replace:true })
                break
            case 1:
                history(`/case-management/case/${caseId}/history`, {replace:false })
                break
            case 2:
                history(`/case-management/case/${caseId}/inspector`, {replace:false })
                break
            case 3:
                history(`/case-management/case/${caseId}/automation`, {replace:false })
                break
            case 4:
                history(`/case-management/case/${caseId}/comments`, {replace:false })
                break
            default:
                history(`/case-management/case/${caseId}`, {replace:true })
        }
    }
    useEffect(()=>{
        if(filesToOpenInSMI.length > 0){
            history(`/case-management/case/${caseId}/inspector`, {replace:false })
        }
    },[ filesToOpenInSMI ])

    useEffect(()=>{
        if(checkUuid(caseId)){
            if (!currentCase) {
                getCaseByCaseId({
                    [enumApiParameter.CASE_ID]: caseId ? caseId : '' 
                }).then((response)=>{

                    setCurrentCase(response.data)

                    postReceiveProcessInstances({caseId})
                    .then((response)=>{
                        const activeProcessInstances = response.data.filter(item=>{
                            let result = false
                            if(item.state==="ACTIVE" && item.endTime === null){
                                result = true
                            }
                            return(result)
                        })
                        if(activeProcessInstances.length>0){
                            setAutomationStillActive(true)
                        }
                    })
                    .catch((error)=>{
                        setCaseManagementError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                        })
                        setCaseManagementErrorNeedToShow(true)
                        console.log("CANT GET CASE AUTOMATION HISTORY", error, error.request)
                    })

                }).catch((error)=>{
                    setCaseManagementError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                    })
                    setCaseManagementErrorNeedToShow(true)
                    console.log("CANT GET CASE BY CASE_ID", error, error.request)
                })
            }else{

                if(isNeedUpdateProperty !== '' || currentCase.id !== caseId){

                    getCaseByCaseId({
                        [enumApiParameter.CASE_ID]: caseId ? caseId : '' 
                    }).then((response)=>{
                        setCurrentCase(response.data)
                    }).catch((error)=>{
                        setCaseManagementError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                        })
                        setCaseManagementErrorNeedToShow(true)
                        console.log("CANT GET CASE BY CASE_ID", error, error.request)
                    })
                    .finally(()=>{
                        setNeedUpdateProperty('')
                    })
                }

            }
        }else{
            setCaseManagementError({
                status  : '',
                path    : '',
                message : `Invalid CaseId = ${ caseId }`
            })
            setCaseManagementErrorNeedToShow(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[caseId, currentCase, isNeedUpdateProperty])


    useEffect(()=>{
        switch(defineTabFromUrl()){
            case 'INFO': setTabIndexValue(0)
                break
            case 'HISTORY': setTabIndexValue(1)
                break
            case 'SMILE_INSPECTOR': setTabIndexValue(2)
                break
            case 'MILD_CASE_AUTOMATION': setTabIndexValue(3)
                break
            case 'COMMENTS': setTabIndexValue(4)
                break
            default:
                setTabIndexValue(0)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tabIndexValue,location.pathname])

    return(

        <div> 
            
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
            }} >
                
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <CasePropertiesView
                        currentCase           = { currentCase       }
                        isNeedUpdateProperty  = { isNeedUpdateProperty  }
                        setNeedUpdateProperty = { setNeedUpdateProperty }
                    />
                </Box>

                <Tabs
                    value    = { tabIndexValue          }
                    onChange = { onTabClick             }
                    sx={{
                        minHeight: tabHeightInPX,
                        'div':{
                            height: tabHeightInPX,
                            'button': {
                                minHeight: tabHeightInPX,
                                'span':{
                                    height: tabHeightInPX,
                                }
                            }
                        },
                    }}
                >
                    <Tab
                        label="Info"      
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 0 ? '#eee' : 'unset',
                        }}
                    />

                    <Tab 
                        label="History"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 1 ? '#eee' : 'unset',
                        }}       
                    />

                    <Tab 
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContents: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                Smile Inspector
                            </Box>}
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 2 ? '#eee' : 'unset',
                        }}      
                    />
                    <Tab 
                        label={
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                
                                Automation History
                                
                                {
                                    isAutomationStillActive && 
                                    <div style={{
                                        width: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        backgroundColor: '#f00',
                                        margin: '0 0 0 8px',
                                        animation: `blinking linear 1s infinite`
                                    }}/>
                                }
                            </Box>
                        }
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 3 ? '#eee' : 'unset',
                        }}      
                    />
                    <Tab 
                        label="Comments"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 4 ? '#eee' : 'unset',
                        }}      
                    />

                </Tabs>
                
            </Box>



            {   // ======================================
                // tab INFO + FILES
                // ======================================

                tabIndexValue === 0 ?
                <>

                        <Box
                            sx={{
                                borderTop: '2px solid #eee',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            {
                                currentCase !== null ?
                                    <CaseView 
                                        caseToView= {{...currentCase}}
                                        setNeedUpdateProperty={ setNeedUpdateProperty } 
                                    />
                                : 
                                    null
                            }

                                <CaseFiles
                                    caseId = {caseId}
                                    setFilesToOpenInSMI = { (filesToOpen:string[], isReset?:boolean)=>{
                                        setFilesToOpenInSMI(filesToOpen)
                                        if(isReset !== true){
                                            setTabIndexValue(2)
                                        }
                                    }}
                                />
                        </Box>


                    
                </>

                :null
            }


            {   // ======================================
                // tab HISTORY
                // ======================================
                tabIndexValue === 1 ?
                <Box
                    sx={{
                        borderTop: '2px solid #eee',
                    }}
                >

                    <CaseHistoryView
                        caseId = {caseId}
                    />

                </Box>
                :null
            }

            {   // ======================================
                // tab SMILE INSPECTOR
                // ======================================
                tabIndexValue === 2 ?
                <Box
                    sx={{
                        borderTop: '2px solid #eee',
                        padding: 0,
                        minHeight: 'calc( 100vh - 148px)',
                        display: 'flex',
                        backgroundColor: '#fff',

                        // position: isSMIFullScreen ? 'fixed' : undefined,
                        // top: isSMIFullScreen ? '64px' : undefined,
                        // left: isSMIFullScreen ? '0' : undefined,
                        // minHeight: isSMIFullScreen ? 'calc( 100vh - 64px)' : 'calc( 100vh - 148px)',
                        // width: '100vw',
                        //zIndex: '100',
                    }}
                >

                    <SmileInspector
                        caseId           = { caseId           }
                        filesToOpenInSMI = { filesToOpenInSMI }
                    />

                </Box>
                :null
            }

            {   // ======================================
                // tab Mild Case Automation
                // ======================================
                tabIndexValue === 3 &&
                <AutomationHistory
                    caseId = { caseId }
                />
                
            }

            {   // ======================================
                // tab COMMENTS
                // ======================================
                tabIndexValue === 4 &&
                <Comments
                    caseId = { caseId }
                />
                
            }

            <ErrorModal/>
        </div>
    )
}

export default Case
