import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter, enumSORT } from "../../Types/General"
import { UserGroup } from "../../Types/Store/StoreGroups"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Groups%20API/list_1

export type TApiGetGroupsByName = {

    [enumApiParameter.DIRECTION  ] : enumSORT
    [enumApiParameter.PAGE_NUMBER] : number
    [enumApiParameter.PAGE_SIZE  ] : number
}

export type TApiGetGroupsByNameResponce = {
    content: UserGroup[],
    totalElements: number,
    size: number,
    number: number,
}

const getGroupsByName = async ( params: TApiGetGroupsByName ): Promise<AxiosResponse<TApiGetGroupsByNameResponce>>  =>{
    return await API.get(
        
        '/groups/by-name',
        
        { 
            params 
        }
    )
}

export default getGroupsByName
