import { useMemo } from 'react' 
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { TNode } from "./CaseFiles"
import colorLibrary from '../../../../Utils/colors'
import CaseFileTreeActionInfo from './FilesTreeNodeActionInfo'
import formatBytes from '../../../../Utils/format-bytes'
import { getColorByStatus } from './Utils/getColorByStatus'
import { getIconByFiledescritpion } from './Utils/getIconByFiledescritpion'
import { TreeItem,treeItemClasses } from '@mui/x-tree-view'
import useDrag from './FilesUploader/useDrag'
import FilesTreeNodeDragAndDropUploader from './FilesUploader/FilesTreeNodeDragAndDropUploader'

type TFilesTreeNodeProps = {
    caseId             : string
    node               : TNode
    needUpdateFileTree : ()=>void
}

export const nodeStyle = {
    [`.${treeItemClasses.content}.Mui-selected`]:{
        backgroundColor: '#0000ff33',
    },
    [`.${treeItemClasses.content}.Mui-selected.Mui-focused`]:{
        backgroundColor: '#0000ff33',
    },
    border: `2px solid #fff`
}

const FilesTreeNode = (props:TFilesTreeNodeProps) =>{
    const { caseId, node, needUpdateFileTree } = props

    const { 
        isDragged,
        droppedFilesDesc,
        dragHandler,
        dragLeaveHandler,
        dropHandler,
        resetDroppedFilesList,
    } = useDrag()

    

    const fileIcon = useMemo(()=>{

        let fillColor  = getColorByStatus(node)
        if(node.child.length === 0 && node.id.indexOf('FOLDER|')>-1){
            fillColor = '#f00'
        }
        return(
            <Box 
                component = { getIconByFiledescritpion(node) } 
                sx={{ 
                    mr: 1, 
                    fill: fillColor ? fillColor : colorLibrary.backgroundColor
                }} 
            />
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[node])

    const fileActions = useMemo(()=>{
        const { fileDescription } = node 
        
        const wrapperStyle:SxProps<Theme> = {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 0 20px',
        }

        if(fileDescription){
            if(fileDescription.status === 'PENDING' || fileDescription.status === 'UPLOAD_FAILED'){ // 'UPLOADED' / 'PENDING'
                return(
                    <Box sx={wrapperStyle}>
                        <CaseFileTreeActionInfo 
                            fileDescription={node}
                        />
                    </Box>
                )
            } 
        }

        return(
            <Box sx={wrapperStyle}>
                <CaseFileTreeActionInfo fileDescription={node}/>
                {
                    node.fileDescription &&
                    <Box sx={{ fontSize: '12px' }}>
                        { formatBytes( node.fileDescription.size) }
                    </Box>
                }
            </Box>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[node, node.fileDescription])

    

    return(
        node.child.length > 0 ?
        
            // FOLDER WITH CHILDS
            <>
                <TreeItem
                    nodeId = { node.id }
                    label  = {
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            { fileIcon }

                            <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, fontSize: '13px' }}>
                                {node.name}
                            </Typography>
                        </Box>
                    }
                    sx={{ 
                        ...nodeStyle,
                        border: `2px solid ${ isDragged ? '#f00' : '#fff'}`
                    }}
                    onDragStart = { dragHandler      }
                    onDragOver  = { dragHandler      }
                    onDragLeave = { dragLeaveHandler }
                    onDrop      = { dropHandler      }
                >
                    {
                        node.child.map((childNodeItem)=>{
                            return(
                                <FilesTreeNode
                                    caseId = { caseId           }
                                    key    = { childNodeItem.id }
                                    node   = { childNodeItem    }
                                    needUpdateFileTree={ needUpdateFileTree }
                                />
                            )
                        })
                    }
                </TreeItem>
                <FilesTreeNodeDragAndDropUploader
                    caseId            = { caseId                    }
                    key               = { `filesUploader-${node.id}`}
                    nodeId            = { node.id                   }
                    filesList         = { droppedFilesDesc          }
                    resetFilesList    = { resetDroppedFilesList     }
                    needUpdateFileTree= { needUpdateFileTree        }
                />
            </>

        :
            (
                // FILE
                node.fileDescription ? 
                    <TreeItem
                        nodeId = { node.id }
                        label  = {
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                { fileIcon }

                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 0 , fontSize: '13px'}}>
                                    {node.name}
                                </Typography>

                                { fileActions }
                            </Box>
                        }
                        sx={nodeStyle}
                        onDragStart = { dragHandler      }
                        onDragOver  = { dragHandler      }
                        onDragLeave = { dragLeaveHandler }
                        onDrop      = { dropHandler      }
                    >
                    </TreeItem>
                :
                    // EMPTY FOLDER (NEW)
                    <>
                        <TreeItem
                            nodeId = { node.id }
                            label  = {
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    { fileIcon }

                                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, fontSize: '13px' }}>
                                        {node.name}
                                    </Typography>
                                </Box>
                            }
                            sx={{ 
                                ...nodeStyle,
                                border: `2px solid ${ isDragged ? '#f00' : '#fff'}`
                            }}
                            onDragStart = { dragHandler      }
                            onDragOver  = { dragHandler      }
                            onDragLeave = { dragLeaveHandler }
                            onDrop      = { dropHandler      }
                        >
                        </TreeItem>
                        <FilesTreeNodeDragAndDropUploader
                            caseId            = { caseId                    }
                            key               = { `filesUploader-${node.id}`}
                            nodeId            = { node.id                   }
                            filesList         = { droppedFilesDesc          }
                            resetFilesList    = { resetDroppedFilesList     }
                            needUpdateFileTree= { needUpdateFileTree        }
                        />
                    </>
            )
            

        
    )
}

export default FilesTreeNode
