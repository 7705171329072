import { AxiosResponse } from "axios"
import API from '../api-base'


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Group%20configuration%20API/getByGroupId

export type TGetGroupConfigRequestParams = {
    application: string
    appVersion : string
    groupId    : string
}

export type TApiGetGroupConfigResponse = {
    application: string
    appVersion : string
    createdAt  : string
    updatedAt  : string
    version    : number
    data       : Object
    groupId: string
    groupName: string
}

const getGroupConfig = async ( params: TGetGroupConfigRequestParams ): Promise<AxiosResponse<TApiGetGroupConfigResponse>>  =>{
    const {
        application,
        appVersion,
        groupId
    } = params
    
    return await API.get(
        `/v2/configurations/groups/${application}/${appVersion}/${groupId}`
    )
}

export default getGroupConfig
