import { Dispatch } from 'redux'
import { TSorting } from '../../Pages/DesktopConfigurations/TabsComponents/Tables/ConfigTableHeader'
import { enumStoreGroupsAction, 
    TGroupActionSetNeedUpdateGroupsFromAPI, 
    TSetCurrentGroupFromAPIAction, 
    TSetCurrentUsersFromAPIAction, 
    TSetCurrenUserePaginationAction, 
    TSetGroupsFromAPIAction, 
    TSetGroupsFromAPIActionPayload, 
    TSetGroupsPaginationAction, 
    TSetGroupsSortingAction, 
    TSetNeedUpdateCurrentGroupFromApiAction, 
    TSetNeedUpdateUsersFromApiAction, 
    TStoreCurrentUsers, 
    TUserGroupsSetError, 
    TUserGroupsSetErrorNeedToShow, 
    TUserGroupsSetErrorPayload, 
    UserGroup 
} from '../../Types/Store/StoreGroups'

export const setGroupsFromAPI = ( actionPayload: TSetGroupsFromAPIActionPayload) =>{
    return((dispatch: Dispatch<TSetGroupsFromAPIAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_GROUPS_FROM_API,
            payload: actionPayload
        })
    })
}

export const setCurrentGroupFromAPI = (actionPayload: UserGroup | undefined) =>{
    return((dispatch: Dispatch<TSetCurrentGroupFromAPIAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_CURRENT_GROUP_FROM_API,
            payload: actionPayload
        })
    })
}

export const setCurrentUsersFromAPI = ( actionPayload: TStoreCurrentUsers ) =>{
    return((dispatch: Dispatch<TSetCurrentUsersFromAPIAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_CURRENT_USERS_FROM_API,
            payload: actionPayload
        })
    })
}

export const setGroupsSorting = (sorting: TSorting) =>{
    return((dispatch: Dispatch<TSetGroupsSortingAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_GROUPS_SORTING,
            payload: sorting
        })
    })
}

export const setCurrentUsersPagination = (newPageNumber: number) =>{
    return((dispatch: Dispatch<TSetCurrenUserePaginationAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_CURRENT_USERS_PAGINATION,
            payload: newPageNumber
        })
    })
}

export const setNeedUpdateUsersFromAPI = ( isNeedUpdateUsersFromAPI: boolean ) =>{
    return((dispatch: Dispatch<TSetNeedUpdateUsersFromApiAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_NEED_UPDATE_USERS_FROM_API,
            payload: isNeedUpdateUsersFromAPI
        })
    })
}

export const setGroupsPagination = (newPageNumber: number) =>{
    return((dispatch: Dispatch<TSetGroupsPaginationAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_GROUPS_PAGINATION,
            payload: newPageNumber
        })
    })
}

export const setNeedUpdateGroupsFromAPI = (isNeedUpdateGroupsFromAPI: boolean) =>{
    return((dispatch: Dispatch<TGroupActionSetNeedUpdateGroupsFromAPI>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_NEED_UPDATE_GROUPS_FROM_API,
            payload: isNeedUpdateGroupsFromAPI
        })
    })
}

export const SetNeedUpdateCurrentGroupFromApi= (isNeedUpdateGroupsFromAPI: boolean) =>{
    return((dispatch: Dispatch<TSetNeedUpdateCurrentGroupFromApiAction>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_CURRENT_GROUP__FROM_API,
            payload: isNeedUpdateGroupsFromAPI
        })
    })
}

export const SetUserGroupsErrorNeedToShow = ( isNeedToShowError: boolean ) =>{
    return((dispatch: Dispatch<TUserGroupsSetErrorNeedToShow>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_GR_ERROR_NEED_TO_SHOW  ,
            payload: isNeedToShowError
        })
    })
}

export const SetUserGroupsError = ( errorDescription: TUserGroupsSetErrorPayload ) =>{
    return((dispatch: Dispatch<TUserGroupsSetError>) =>{
        dispatch({
            type: enumStoreGroupsAction.SET_GR_ERROR ,
            payload: errorDescription
        })
    })
}

