import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import postCreateUrlForUploadingFileToCase, { TApiPostCreateUrlForUploadingFileParams, TApiPostCreateUrlForUploadingFileResponse } from '../../../../../Api/CaseManagement/v2/post-create-url-for-uploading-file'
import axios, { AxiosPromise } from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { FileDesc } from './useDrag'
import convertPathToStringArray from './Utils/convertPathToStringArray'
import convertUploadTreeProgressIndicatorToUploadNodesTree from './Utils/convertUploadTreeProgressIndicatorToUploadNodesTree'
import FileUploaderTree from './FileUploaderTree'
import encodeUriRFC5987 from './Utils/encodeUriRFC5987'

export type TFilesTreeNodeDragAndDropUploaderProps = {
    nodeId:string
    caseId:string
    filesList: FileDesc[] | undefined
    resetFilesList: () => void
    needUpdateFileTree : ()=>void
}

type TUploadFilesParams=  {
    caseId: string
    nodeId: string
    filesList: FileDesc[] | undefined
    setUploadProgress: (params:TUploadProgressIndicator)=> void
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>
    resetFilesList : ()=>void
    needUpdateFileTree : ()=>void
}

const  getFilesUrls = async (params:TApiPostCreateUrlForUploadingFileParams[]) =>{

    const promissesArray = params.map((paramsItem)=>{
        return(postCreateUrlForUploadingFileToCase(paramsItem))
    })

    return(await Promise.all(promissesArray))
}

type TUploadProgressIndicator = {
    fileName: string
    progress: number
    size    : number
}

export type TUploadTreeProgressIndicator = {
    filePath: string[]
    progress: number
    size    : number
}

export type TUploadTreeNode = {
    nodId: string
    childs: TUploadTreeNode[]
    
    name: string
    progress: number
    size    : number
}

type TSendFilesToS3Params = {
    urls: TApiPostCreateUrlForUploadingFileResponse[]
    filesList: FileDesc[]
    setUploadProgress: (params:TUploadProgressIndicator)=> void
}

const sendFilesToS3 = async (params:TSendFilesToS3Params) =>{
    const { filesList, urls, setUploadProgress } = params
    const promissesArray:AxiosPromise<any>[] = []
    urls.forEach((urlItem)=>{
        for(let i = 0; i<filesList.length; i++){


            if(urlItem.fileLink.indexOf(encodeUriRFC5987(filesList[i].file.name)) > -1){
                filesList[i].file.text()
                promissesArray.push(axios({
                    method: 'put',
                    url: urlItem.fileLink,
                    data: filesList[i].file,
                    headers:{
                        'Content-Type' : filesList[i].file.type ? filesList[i].file.type : 'text/plain',
                    },
                    onUploadProgress: (e) =>{
                        if(e.total){
                            setUploadProgress({
                                fileName: filesList[i].path, 
                                progress: Math.floor(100*e.loaded/e.total),
                                size: filesList[i].file.size
                            })
                        }
                    }
                }))
            }
        }
    })

    return(await Promise.all(promissesArray))
}

const uploadFiles = async (params:TUploadFilesParams) =>{

    const { 
        filesList, 
        nodeId, 
        caseId, 
        setUploadProgress, 
        setUploadStarted,
        resetFilesList,
        needUpdateFileTree,
    } = params
    const result = []
    
    if(filesList && filesList.length>0){
        setUploadStarted(true)
        let folderPath = nodeId.split('|')[1]
        if(folderPath.length > 0 && folderPath[0] === '/'){
            folderPath = folderPath.slice(1,folderPath.length)
        } 
        for(let i = 0; i<filesList.length; i++){

            const { path } = filesList[i]
            const pathWithoutName = path.slice( 0 ,path.indexOf(filesList[i].file.name))
            result.push({
                id: caseId ? caseId : '',
                fileName: filesList[i].file.name,
                mimeType: filesList[i].file.type ? filesList[i].file.type : 'text/plain',
                pathInCase: (folderPath?`/${folderPath}` : '') + pathWithoutName,
            })
        }
        
        const   getUrlsResponse = await getFilesUrls(result)

        if(getUrlsResponse.length>0){
            const urls = getUrlsResponse.map( item=> item.data )
            await sendFilesToS3({
                filesList,
                urls,
                setUploadProgress
            })

            setTimeout(()=>{
                needUpdateFileTree()
            },2000)

            setTimeout(()=>{ 
                resetFilesList()
                setUploadStarted(false)
            }, 3000 )
            
        }else{
            setUploadStarted(false)
            // raise error
        }
    }
}

const  FilesTreeNodeDragAndDropUploader= (props: TFilesTreeNodeDragAndDropUploaderProps) =>{

    const  { nodeId, caseId, filesList, resetFilesList, needUpdateFileTree } = props
    const [ uploadProgress , setUploadProgress ] = useState<TUploadProgressIndicator[]>([])
    const [ isUploadStarted, setUploadStarted  ] = useState(false)

    const setUploadProgressFromPromise = (params:TUploadProgressIndicator) =>{
        const { fileName, progress } = params
        setUploadProgress((prev) =>{
            const result:TUploadProgressIndicator[] = [...prev]
            
            result.forEach((item) =>{
                if(item.fileName === fileName){
                    item.progress = progress
                }
            })

            return(result)
        })
    }

    useEffect(()=>{
        
        const result = []
        if(filesList){
            for(let i = 0; i<filesList.length; i++){
                result.push({
                    fileName: filesList[i].path,
                    progress: 0,
                    size: filesList[i].file.size
                })
            }
        }
        setUploadProgress(result)

    },[filesList]) 

    const files = useMemo(()=>{

        const filesStrings = uploadProgress.map((item, itemIndex)=>{
            return(
                convertPathToStringArray(item.fileName)
            )
        })

        const filesStringsWithProgress:TUploadTreeProgressIndicator[] = filesStrings.map((item,itemIndex)=>{
            return({
                filePath:item,
                progress: uploadProgress[itemIndex].progress,
                size: uploadProgress[itemIndex].size,
            })
        })

        const nodesTree = convertUploadTreeProgressIndicatorToUploadNodesTree(filesStringsWithProgress)

        return( 
            <FileUploaderTree
                isUploadStarted = { isUploadStarted }
                nodesArray ={nodesTree}
            />
        )
    },[uploadProgress, isUploadStarted])



    const uploadHandler = () =>{
        uploadFiles({
            caseId,
            nodeId,
            filesList,
            setUploadProgress: setUploadProgressFromPromise,
            setUploadStarted,
            resetFilesList,
            needUpdateFileTree
        })
    }

    return(
        <Dialog
            open    = { Boolean(filesList && filesList.length > 0) }
            onClose = { resetFilesList }
            maxWidth='xl'
        >
            <DialogTitle id="upload-dialog-title">
                Upload files to { nodeId.split('|')[1] ? nodeId.split('|')[1] : '/' }
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection: 'column',
                        width: '800px'
                    }}    
                >
                    { files }
                </Box>
            </DialogContent>
            
            <DialogActions>
                
                <Button 
                    onClick={resetFilesList}
                >
                    Cancel
                </Button>
                
                <Button 
                    onClick={uploadHandler}
                    variant='contained'
                    autoFocus
                    endIcon={ isUploadStarted ? <CircularProgress size={20} color='inherit'/> : <Box sx={{width: '20px'}}/> }
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilesTreeNodeDragAndDropUploader
