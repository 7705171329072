import { useState, useEffect } from 'react'
import { TProcessInstanceActivityDescription, TProcessVariableDescription } from "../../../../Types/Store/StoreCaseManagement"
import postReceiveProcessInstanceActivities from '../../../../Api/Workflow/Automation/post-receive-process-instance-activities'
import { Box, CircularProgress } from "@mui/material"
import { processItemsInfoStyle } from './ProcessInstance'
import ProcessInstanceActivityItem from './ProcessInstanceActivityItem'

type TProcessInstanceActivitiesProps = {
    processInstanceId: string
    allAvalableVariables: TProcessVariableDescription[]
}

export const findChildren = (parentId:string, activities: TProcessInstanceActivityDescription[]) =>{
    return activities.filter(item => item.parentActivityInstanceId === parentId)
}

const ProcessInstanceActivities = (props:TProcessInstanceActivitiesProps) => {
    const  {
        processInstanceId,
        allAvalableVariables,
    } = props

    const [ isActivitiesLoaded, setActivitiesLoaded ] = useState(false)
    const [ activities, setActivities ] = useState<TProcessInstanceActivityDescription[]>([])

    useEffect(()=>{
        if(isActivitiesLoaded === false){

            postReceiveProcessInstanceActivities({
                processInstanceId: processInstanceId
            })
            .then((response)=>{
                setActivities(response.data)
            })
            .catch((error)=>{
                console.log(error)
            })
            .finally(()=>{
                setActivitiesLoaded(true)
            })

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isActivitiesLoaded])

    return(
        <Box
            sx={processItemsInfoStyle}
        >
            {
                isActivitiesLoaded === false && activities.length === 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px',
                    }}
                >
                    <CircularProgress size={20} sx={{margin: '0 10px 0 0'}}/> Loading activities ... 
                </Box>
            }

            {
                isActivitiesLoaded === true && activities.length === 0 &&
                <></>
            }

            {
                isActivitiesLoaded === true && activities.length > 0 &&
                <Box
                    sx={{
                        width: '90vw',
                        backgroundColor: '#ecf4ff',
                        padding: '10px 20px',
                    }}
                >
                    <h6
                        style={{
                            margin: '10px 0 0 4px',
                            width: '100%',
                            padding: '4px 8px',
                        }}
                    >
                        Activities
                    </h6>

                    {
                        activities
                        .filter(item => item.parentActivityInstanceId === item.processInstanceId ) // top-level activities
                        .map((activityItem, activityItemIndex)=>{
                            return(
                                <ProcessInstanceActivityItem
                                    key = { `processActivityDescItem-${activityItemIndex}-${activityItem.activityId}` }
                                    level                 = { 0                                        }
                                    activityDescription   = { activityItem                             }
                                    childrenActivities    = { findChildren(activityItem.id,activities) }
                                    allAvalableActivities = { activities                               }
                                    allAvalableVariables  = { allAvalableVariables                     }
                                />
                            )
                        })
                    }
                </Box>
            }

        </Box>
    )
}

export default ProcessInstanceActivities
