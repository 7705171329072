import { Box, Button, Modal, Typography } from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { toolbarButtonStyle } from "./FilesToolbar"
import { TCaseFileDescription } from '../../../../Types/CaseManagement'
import { useState } from "react"
import { deleteCaseFiles } from "./FilesToolbarActionDelete"
import { useAction } from "../../../../Store/Hook/use-action"

type TFilesToolbarActionDeleteFileAndFoldersProps = {
    fileDescription: TCaseFileDescription[] | undefined
    setNeedUpdateFileTree: ()=>void
    selectedNodes: string[]
}

const detectCountOfFilesAndFolders = (selectedNodes: string[])=>{
    return({
        filesCount: selectedNodes.filter((nodeId)=>{
                        return nodeId.indexOf('FILE|')>-1
                    }).length,
        foldersCount:selectedNodes.filter((nodeId)=>{
                        return nodeId.indexOf('FOLDER|')>-1
                    }).length
    })
}

const findFileDescByNodes = (fileDescription:TCaseFileDescription[],selectedNodes: string[]) =>{
    const selectedNodesPaths = selectedNodes.map(item => {
        let path = item.split('|')[1]
        return(path)
    })
    const catchedFilesArray:TCaseFileDescription[] = []

    selectedNodesPaths.forEach((pathItem)=>{
        const filesDescToCatch = fileDescription.filter(fileDesc=>{
            let result = false

            if(fileDesc.s3key.indexOf(pathItem)>-1){
                
                const alreadyCatchedFiles = catchedFilesArray.filter((catchedFileDesc)=>{
                    return(
                        catchedFileDesc.s3key === fileDesc.s3key
                    )
                })

                if(alreadyCatchedFiles.length === 0){
                    result = true
                }
            }
            return result 
        })
        if(filesDescToCatch.length>0){
            catchedFilesArray.push(...filesDescToCatch)
        }
    })
    return(catchedFilesArray)
}

const FilesToolbarActionDeleteFileAndFolders = (props:TFilesToolbarActionDeleteFileAndFoldersProps) =>{
    
    const { 
        selectedNodes,
        fileDescription,
        setNeedUpdateFileTree, 
    } = props
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const catchedFilesArray:TCaseFileDescription[] = fileDescription ? findFileDescByNodes(fileDescription,selectedNodes) : []

    const handleDelete = () =>{
        
        try{
            deleteCaseFiles({
                fileDescription: catchedFilesArray
            })
            setTimeout(()=>{
                handleClose()
                if(setNeedUpdateFileTree){
                    setNeedUpdateFileTree()
                }
            },1500)
        } catch (error:any) {
            setCaseManagementError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : `CANT DELETE FILE WITH S3Key FROM Case`
            })
            setCaseManagementErrorNeedToShow(true)
        }

    }

    const {
        filesCount,
        foldersCount,
    } = detectCountOfFilesAndFolders(selectedNodes)

    return(
        <>
            <Button
                size="small"
                variant="outlined"
                sx={toolbarButtonStyle} 
                startIcon={<DeleteForeverIcon />}
                onClick={handleOpen}
            >
                Delete
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'max-content',
                    bgcolor: '#fff',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    

                    <Typography sx={{ margin : '0px 0 10px 0' }}>
                        
                        Do you really want to delete 
                        
                        { foldersCount >0 && filesCount > 0 && ' these'    }
                        { foldersCount ===0 && filesCount ===1 && ' this'  }
                        { foldersCount ===0 && filesCount >  1 && ' these' }
                        { foldersCount ===1 && filesCount ===0 && ' this'  }
                        { foldersCount >  1 && filesCount ===0 && ' these' }

                        {`${
                                foldersCount > 0 ? ( foldersCount > 1 ? ' folders': ' folder') : ''
                            } ${
                                filesCount > 0 ? (filesCount> 1 ? `${foldersCount>0 && 'and'} files`: `${foldersCount>0 && 'and' } file`) : ''
                            }`
                        }?
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                    
                </Box>
            </Modal>
        </>
    )
}   

export default FilesToolbarActionDeleteFileAndFolders
