
// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=Workflow%20service%20v1#/Configuration%20API/create

import API from '../api-base'
import { AxiosResponse } from "axios";
import { TWorkflowConfiguration } from "../../../Types/Store/StoreWorkflow";

type TResonse = TWorkflowConfiguration

const putUpdateConfiguration = async (params:TWorkflowConfiguration): Promise<AxiosResponse<TResonse>> =>{
    
    const { key } = params
    
    return await API.put(
    
        `/api/configuration/${key}`,
    
        {
            value       : params.value,
            description : params.description,
        }
    )
}

export default putUpdateConfiguration
