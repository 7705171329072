import { Box } from "@mui/material"
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import { TCasePropertyForUpdate } from "./Case"
import CaseStatus from "./CaseStatus"
import CaseLock from "./Elements/CaseLock"
import formatDateToLocal from "../../../Utils/format-date-to-local"


type TCasePropertiesViewProps = {
    currentCase           : TCase | null
    isNeedUpdateProperty  : TCasePropertyForUpdate
    setNeedUpdateProperty : React.Dispatch<React.SetStateAction<TCasePropertyForUpdate>>
}

const descStyle =  {
    display: 'flex',
    fontSize: '0.875rem',
    alignItems: 'center',
    margin:  0,
    color: '#bbb'
}

const decsHeaderStyle = {
    margin: '6px',
    fontWeight: 'bold',
    color: '#eee',
}

const Delimiter = () =>{
    return(
    <div
        style={{
            margin: '0 6px',
            display: 'flex',
            alignItems: 'center',
            color: '#aaa'
        }}
    > 
        | 
    </div>)
}

const CasePropertiesView = (props: TCasePropertiesViewProps ) =>{

    const { 
        currentCase,
        isNeedUpdateProperty,
        setNeedUpdateProperty,
    } = props

    return(

        currentCase ?
            <Box sx={{
                display: 'flex',
                margin: 0,
                width: '100%',
                backgroundColor: '#444',
                justifyContent: 'space-between',
            }}>
                <Box sx={{
                    display: 'flex',
                    margin: 0,
                    width: '100%',
                    backgroundColor: '#444',
                }}>

                    <CaseLock 
                        caseDescription = { currentCase                             }
                        isNeedUpdate    = { isNeedUpdateProperty === 'locked'       } 
                        setNeedUpdate   = { ()=>{ setNeedUpdateProperty("locked") } }
                    />
                    
                    <Delimiter/>

                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            External ID
                        </Box>
                        <Box>
                            { currentCase.externalId }
                        </Box>
                    </Box>

                    <Delimiter/>
                    
                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            Source
                        </Box>
                        <Box>
                            { currentCase.source }
                        </Box>
                    </Box>

                    <Delimiter/>

                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            Created By
                        </Box>
                        <Box>
                            { currentCase.createdBy }
                        </Box>
                    </Box>

                    <Delimiter/>

                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            Updated
                        </Box>
                        <Box>
                            { formatDateToLocal(currentCase.updatedAt) }
                        </Box>
                    </Box>

                    <Delimiter/>

                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            Status
                        </Box>

                        <CaseStatus
                            caseId              = { currentCase.id                          }
                            statusValueExternal = { currentCase.status                      }
                            needUpdateCaseView  = { ()=>{ setNeedUpdateProperty("status") } }
                        />

                    </Box>

                    <Delimiter/>

                    <Box sx={descStyle}>
                        <Box sx={decsHeaderStyle}>
                            CMS ID
                        </Box>
                        <Box>
                            { currentCase.id }
                        </Box>
                    </Box>

                </Box>

                {
                    currentCase.automatedMildCase === true &&
                    <Box sx={descStyle}>
                        <Box sx={{
                            ...decsHeaderStyle,
                            backgroundColor: '#ff0',
                            color: '#000',
                            padding: '0 4px'
                        }}>
                            MCA
                        </Box>
                    </Box>
                }
            </Box>
        :
            <></>
    )
}

export default CasePropertiesView
