
import { enumSORT } from "../../Types/General"
import { enumStoreGroupsAction, IStoreGroups, TGroupStoreAction } from "../../Types/Store/StoreGroups"


const initialState:IStoreGroups = {
    needUpdateDataFromAPI: true,
    list: [],
    sorting: enumSORT.ASC,
    pagination:{
        totalElements: 0,
        size  : 100,
        number: 0,
    },
    current:{
        needUpdateGroupFromAPI: true,
        group: undefined,
        user : undefined,
        users: [],
        usersPagination:{
            needUpdateDataFromAPI: true,
            userSorting: enumSORT.ASC,
            totalElements: 0,
            size: 100,
            number: 0
        }
    },
    error:{
        needToShow: false,
        status    : '',
        path      : '',
        message   : '',
    }
}


export const groupsReducer = ( 

    state = initialState,
    action : TGroupStoreAction

) : IStoreGroups => {

    switch(action.type){

        case  enumStoreGroupsAction.SET_NEED_UPDATE_GROUPS_FROM_API :            
            return ({
                ...state,
                needUpdateDataFromAPI: action.payload,
            })

        case enumStoreGroupsAction.SET_GROUPS_FROM_API :
            return ({
                ...state,
                needUpdateDataFromAPI: false,
                list: action.payload.groups,
                pagination: action.payload.pagination
            })
        case enumStoreGroupsAction.SET_CURRENT_GROUP_FROM_API :
            return ({
                ...state,
                needUpdateDataFromAPI: false,
                current:{
                    ...state.current,
                    needUpdateGroupFromAPI: false,
                    group: action.payload
                }
            })

        case enumStoreGroupsAction.SET_GROUPS_SORTING :
            return ({
                ...state,
                sorting: action.payload.sortingDirection,
            })

        case enumStoreGroupsAction.SET_CURRENT_USERS_FROM_API:
            return({
                ...state,
                current:{
                    ...state.current,
                    ...action.payload,
                }
            })

        case enumStoreGroupsAction.SET_CURRENT_USERS_PAGINATION:
            return({
                ...state,
                current:{
                    ...state.current,
                    usersPagination:{
                        ...state.current.usersPagination,
                        needUpdateDataFromAPI: true,
                        number: action.payload
                    }
                }
            })

        case enumStoreGroupsAction.SET_NEED_UPDATE_USERS_FROM_API:
            return({
                ...state,
                current:{
                    ...state.current,
                    usersPagination:{
                        ...state.current.usersPagination,
                        needUpdateDataFromAPI: action.payload
                    }
                }
            })
    
        case enumStoreGroupsAction.SET_GROUPS_PAGINATION:
            return({
                ...state,
                needUpdateDataFromAPI: true,
                pagination: {
                    ...state.pagination,
                    number: action.payload
                }
            })
        case enumStoreGroupsAction.SET_CURRENT_GROUP__FROM_API:
            return({
                ...state,
                current:{
                    ...state.current,
                    needUpdateGroupFromAPI: action.payload,
                }
            })

        case enumStoreGroupsAction.SET_GR_ERROR_NEED_TO_SHOW:{
            return({
                ...state,
                error:{
                    ...state.error,
                    needToShow: action.payload
                }
            })
        }
        
        case enumStoreGroupsAction.SET_GR_ERROR:{
            return({
                ...state,
                error:{
                    ...state.error,
                    ...action.payload
                }
            })
        }

        default:
            return state
    }

}
