import API from '../api-base'
import { TCaseStatusTransition } from './get-transition-from-status'
import { enumApiParameter } from '../../../Types/General'


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20Status%20Transition%20API/update

export type TApiPostUpdateStatusTransition = {
    [enumApiParameter.STATUS          ]  : string
    initial                              : boolean
    transitions                          : string[]
    version                              : number
}

const putUpdateCaseStatusTransition = async ( params: TApiPostUpdateStatusTransition ): Promise<TCaseStatusTransition>  =>{
    return await API.put(
        `/v2/case-status-transitions`,
        { 
            ...params 
        }
    )
}

export default putUpdateCaseStatusTransition
