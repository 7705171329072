import { Dispatch } from 'redux'
import { TSorting } from '../../Pages/DesktopConfigurations/TabsComponents/Tables/ConfigTableHeader'
import { 
    enumStoreDesktopConfigurationsAction, 
    IDesktopGroupConfig, 
    IDesktopGroupListItem, 
    IDesktopUserConfig, 
    TApplicationsDescription, 
    TApplicationVarsionSelected, 
    TDesktopConfigurationSetError, 
    TDesktopConfigurationSetErrorNeedToShow, 
    TDesktopConfigurationSetErrorPayload, 
    TDesktopConfigurationSetGroupsPagination, 
    TDesktopConfigurationSetGroupsPeriod, 
    TDesktopConfigurationSetGroupsSortingDirection, 
    TDesktopConfigurationSetNeedUpdateUsersConfigurationsFromAPI, 
    TDesktopConfigurationSetUsersConfigs, 
    TDesktopConfigurationSetUsersLoginFilter, 
    TDesktopConfigurationSetUsersPagination, 
    TDesktopConfigurationSetUsersPeriod, 
    TDesktopConfigurationSetUsersSelectedApplicationIndex, 
    TDesktopConfigurationSetUsersSortingDirection, 
    TDesktopConfigurationSetUsersSortingDirectionPayload, 
    TDesktopConfigurationsPaginationPayload, 
    TDesktopConfigurationsPeriodPayload, 
    TDesktopConfigurationsSetApplicationsDescriptionFromAPI, 
    TDesktopConfigurationsSetGroupsConfigsApplictionVersion, 
    TDesktopConfigurationsSetGroupsConfigsFromAPI,
    TDesktopConfigurationsSetGroupsListFromAPI,
    TDesktopConfigurationsSetNeedUpdateApplicationsFromAPI,
    TDesktopConfigurationsSetNeedUpdateGroupsFromAPI,
    TDesktopConfigurationsSetUsersConfigsApplictionVersion
} from '../../Types/Store/StoreDesktopConfig'


export const SetNeedUpdateApplicationConfigsFromAPI = ( isNeedToUpdateApplications: boolean ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationsSetNeedUpdateApplicationsFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_APPLICATIONS_FROM_API,
            payload: isNeedToUpdateApplications
        })
    })
}
//=========================================================================================================================
//  USERS CONFIGS SECTION
//=========================================================================================================================


export const SetGroupsDesktopConfigsFromAPI = ( groupsConfigsArray: IDesktopGroupConfig[] ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationsSetGroupsConfigsFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS,
            payload: groupsConfigsArray
        })
    })
}

export const SetGroupsDesktopGroupsListFromAPI = ( groupsList: IDesktopGroupListItem[] ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationsSetGroupsListFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_LIST,
            payload: groupsList
        })
    })
}


export const SetNeedUpdateGroupConfigsFromAPI = ( isNeedToUpdateGroups: boolean ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationsSetNeedUpdateGroupsFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_GROUPS_FROM_API,
            payload: isNeedToUpdateGroups
        })
    })
}

export const SetGroupsPagination = ( pagination: TDesktopConfigurationsPaginationPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetGroupsPagination>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PAGINATION,
            payload: pagination
        })
    })
}


export const SetGroupsPeriod = ( period: TDesktopConfigurationsPeriodPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetGroupsPeriod>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_PERIOD,
            payload: period
        })
    })
}

export const SetGroupsSortingDirection = ( sorting: TSorting ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetGroupsSortingDirection>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_SORTING_DIRECTION,
            payload: sorting
        })
    })
}

//=========================================================================================================================
//  USERS CONFIGS SECTION
//=========================================================================================================================

export const SetUsersDesktopConfigurationsFromAPI = ( usersConfigsArray: IDesktopUserConfig[]  ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersConfigs>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS,
            payload: usersConfigsArray
        })
    })
}

export const SetNeedUpdateUsersConfigsFromAPI = ( isNeedUpdate: boolean  ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetNeedUpdateUsersConfigurationsFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_NEED_UPDATE_USERS_FROM_API,
            payload: isNeedUpdate
        })
    })
}

export const SetUsersPagination = ( pagination: TDesktopConfigurationsPaginationPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersPagination>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_PAGINATION,
            payload: pagination
        })
    })
}

export const SetUsersSelectedApplicationIndex = ( newIndex: number ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersSelectedApplicationIndex>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_SELECTED_APPLICATION_INDEX,
            payload: newIndex
        })
    })
}

export const SetUsersLoginFilter = ( loginFilter: string ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersLoginFilter>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_LOGIN_FILTER ,
            payload: loginFilter
        })
    })
}

export const SetUsersSortingDirection = ( sorting: TDesktopConfigurationSetUsersSortingDirectionPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersSortingDirection>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_SORTING_DIRECTION ,
            payload: sorting
        })
    })
}

export const SetUsersPeriod = ( period: TDesktopConfigurationsPeriodPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetUsersPeriod>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_PERIOD ,
            payload: period
        })
    })
}


export const SetErrorNeedToShow = ( isNeedToShowError: boolean ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetErrorNeedToShow>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_ERROR_NEED_TO_SHOW  ,
            payload: isNeedToShowError
        })
    })
}

export const SetError = ( errorDescription: TDesktopConfigurationSetErrorPayload ) =>{
    return((dispatch: Dispatch<TDesktopConfigurationSetError>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_ERROR ,
            payload: errorDescription
        })
    })
}

export const SetApplicationsDescription = ( applicationsDescription: TApplicationsDescription[] ) =>{

    return((dispatch: Dispatch<TDesktopConfigurationsSetApplicationsDescriptionFromAPI>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_APPLICATIONS_DESCRIPTION ,
            payload: applicationsDescription
        })
    })
}

export const SetUsersSelectedApplicationVersion = ( applicationsVersion: TApplicationVarsionSelected ) =>{

    return((dispatch: Dispatch<TDesktopConfigurationsSetUsersConfigsApplictionVersion>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_USERS_CONFIGS_SELECTED_APPLICATION_VERSION ,
            payload: applicationsVersion
        })
    })
}

export const SetGroupsSelectedApplicationVersion = ( applicationsVersion: TApplicationVarsionSelected | undefined ) =>{
    
    return((dispatch: Dispatch<TDesktopConfigurationsSetGroupsConfigsApplictionVersion>) =>{
        dispatch({
            type: enumStoreDesktopConfigurationsAction.SET_DC_GROUPS_CONFIGS_SELECTED_APPLICATION_VERSION ,
            payload: applicationsVersion
        })
    })
}
