import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Users%20in%20groups%20API/delete_1

export type TApiDeleteUserFromGroupParams = {
    [enumApiParameter.GROUP_ID ] : string
    [enumApiParameter.LOGIN    ] : string // email
}

const deleteUserFromGroup = async ( params: TApiDeleteUserFromGroupParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,

        {
            data: {
                [enumApiParameter.LOGIN] : params[enumApiParameter.LOGIN]
            }
        }
    )
}

export default deleteUserFromGroup
