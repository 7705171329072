import { saveAs } from 'file-saver'
import { IDesktopApplicationConfig, IDesktopGroupConfig, IDesktopUserConfig } from '../../../../Types/Store/StoreDesktopConfig'

const onDownloadConfig = (itemIndex: number, configs: IDesktopApplicationConfig[] | IDesktopGroupConfig[] | IDesktopUserConfig[]) =>{
        
    saveAs(
        new Blob(
            [JSON.stringify(configs[itemIndex].data)],
            {type: 'application/json'}
        ),
        'config.json'
    )
    
}

export default onDownloadConfig
