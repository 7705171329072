import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material"
import { TCase } from "../../../../Types/Store/StoreCaseManagement"
import { TCasePropertyForUpdate } from "../Case"
import useValidation, { enumFormValidationRule } from "../../../formValidator"
import { useState, useEffect } from 'react'
import patchUpdateCasePreviousCaseId from "../../../../Api/CaseManagement/v2/patch-update-case-previous-id"
import { useAction } from "../../../../Store/Hook/use-action"
import EditValueDialog from "./EditValueDialog"
import EditIcon from '@mui/icons-material/Edit'
import { Link } from "react-router-dom"

type TCasePreviousId = {
    caseToView: TCase
    setNeedUpdateProperty: React.Dispatch<React.SetStateAction<TCasePropertyForUpdate>>
}

const CasePrevoiusId = (props: TCasePreviousId) =>{

    const { caseToView, setNeedUpdateProperty } = props
    const { setCaseManagementError } = useAction()

    const [
        previousCaseIdValue,
        isPreviousCaseIdValueValid,
        previousCaseIdValueError,
        onPreviousCaseIdValueChange
    ] = useValidation(
        [enumFormValidationRule.UUID], 
        caseToView.previousCaseId
    )

    const [ open, setOpen] = useState(false)
    const [ isNeedToSend, setNeedToSend ] = useState(false)

    const handleClickOpenDialog = () => {
        onPreviousCaseIdValueChange('', true)
        setOpen(true)
    }
    const handleCloseDialog = () => {
        setOpen(false)
        onPreviousCaseIdValueChange('',true)
    }
    const handleClickApply = () =>{
        if(isPreviousCaseIdValueValid){
            setNeedToSend(true)
        }
    }

    useEffect(()=>{
        if(isNeedToSend === true){
            setNeedToSend(false)
            if(previousCaseIdValue!=='' && isPreviousCaseIdValueValid){
                patchUpdateCasePreviousCaseId({
                    caseId: caseToView.id,
                    previousCaseId: previousCaseIdValue
                })
                .then((response)=>{
                    setNeedUpdateProperty('all')
                })
                .catch((error)=>{
                    console.log('CANT UPDATE PreviousCaseId ', {
                        caseId: caseToView.id,
                        previousCaseId: previousCaseIdValue
                    })
                    setCaseManagementError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT UPDATE PREVIOUS CASE ID ' + previousCaseIdValue
                    })
                })
                .finally(()=>{
                    handleCloseDialog()
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSend])

    return(
        <TableRow
            hover
            key={'specialFieldTag-PreviousCaseId'}
            sx={{
                maxWidth : '100%',
            }}
        >
            <TableCell
                align="right"
                sx={{ border: 'unset'}}
            >
                <strong>Previous Case ID</strong>
            </TableCell>
            <TableCell
                align="left"
                sx={{ border: 'unset'}}
            >
                <EditValueDialog
                    open = { open }
                    handleCloseDialog = { handleCloseDialog                }
                    handleClickApply  = { handleClickApply                 }
                    title             = { 'Change previous case ID'        }
                    id                = { 'previousCaseIdValue'            }
                    value             = { previousCaseIdValue              }
                    label             = { 'Enter new value in UUID format' }
                    isValidValue      = { isPreviousCaseIdValueValid       }
                    valueError        = { previousCaseIdValueError         }
                    onChangeValue     = { (e)=>{
                        if(String(e.target.value).trim() === ''){
                            onPreviousCaseIdValueChange(String(e.target.value).trim(),true)
                        }else{
                            onPreviousCaseIdValueChange(String(e.target.value),false)
                        }
                    }}
                />

                <div>
                    
                    <Link to={`/case-management/case/${caseToView.previousCaseId}`}>
                        { caseToView.previousCaseId }
                    </Link> 

                    <Tooltip title="Set new previous case Id">
                        <IconButton
                            size="small"
                            onClick={handleClickOpenDialog}
                        >
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                

            </TableCell>
        </TableRow>
    )
}

export default CasePrevoiusId
