const colorLibrary = {
    backgroundColor: '#5700ff',
}



const buttonOutlinedStyle = {
    borderColor:colorLibrary.backgroundColor,
    color:colorLibrary.backgroundColor,
    '&:hover':{
        borderColor:colorLibrary.backgroundColor,
        color:colorLibrary.backgroundColor,
    }
}

const buttonContainedStyle = {
    backgroundColor: colorLibrary.backgroundColor,
    '&:hover':{
        backgroundColor: colorLibrary.backgroundColor
    }
}

export { buttonOutlinedStyle, buttonContainedStyle }

export default colorLibrary
