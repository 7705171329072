import { useEffect, useState, useRef, useMemo } from "react"
import { TCaseFileDescritpion } from "../../../../Types/CaseManagement"
import getFileLinks from "./Utils/getFileLinks"
import { Box} from "@mui/material"
import Preloader from "./Preloader"
import Warning from "./Warning"
import filterFileLinksBySupportedExtensions from "./Utils/filterFileLinksBySupportedExtensions"

type TSmileInspectorProps = {
    caseId?: string
    filesToOpenInSMI: string[]
}

export type TCaseFileFormat = ''| '.stl' | '_wrap.zip' | '.obj' | '.dcm' | '.ply' | '.ctm' | '.swf'
export type TFileFormatToType = [ string, TCaseFileFormat]
export type TFileLink = TCaseFileDescritpion & {
    isVisible: boolean
}
export type TTCaseFileLinkState = {
    isLoaded: boolean
    supportedExtensions: TFileFormatToType[]
    avalableExtensions: TFileFormatToType[]
    extensionToShowIndex: number
    fileLinks           : TCaseFileDescritpion[]
    linksToShow         : TFileLink[]
}

/*
const fullscreenRequest = async ( elementRef: React.RefObject<HTMLIFrameElement>) =>{
    if(elementRef.current){
        let result = undefined
        try{
            result = await elementRef.current.requestFullscreen().then(()=>{
                return('request performed')
            })
        }catch(e){
            console.log('CANT REQUEST FULLSCRENN MODE')
        }
        return result
    }
    return  undefined
}
*/
const SmileInspector = (props:TSmileInspectorProps) => {
    const baseLinkUrl = window['REACT_APP_SMILEINSPECTOR_URL'] ? window['REACT_APP_SMILEINSPECTOR_URL'] : process.env.REACT_APP_SMILEINSPECTOR_URL
    
    const { 
        caseId,
        filesToOpenInSMI,
    } = props
    const [ caseFileLinksState, setCaseFileLinkState ] = useState<TTCaseFileLinkState>({
        isLoaded          : false,
        supportedExtensions: [
            ['SmileWrapper' ,'_wrap.zip'],
            ['SWF'          ,'.swf'     ],
            ['STL'          ,'.stl'     ],            
            ['OBJ'          ,'.obj'     ],
            ['DCM'          ,'.dcm'     ],
            ['PLY'          ,'.ply'     ],
            ['CTM'          ,'.ctm'     ],
        ],
        avalableExtensions: [],
        extensionToShowIndex : -1,
        fileLinks         : [],
        linksToShow       : [],
    })

    const iframeRef = useRef<HTMLIFrameElement>(null)
    // create MessageChannel on each iframe reload and don't reuse it
    // because SmileInspector can reload itself if needed:
    const [ iframeLoadTrigger, setIframeLoadTrigger ] = useState(false)

    const messageChannel = useMemo(()=>{
        return new MessageChannel()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[iframeLoadTrigger])

    /*
    useEffect(()=>{
        fullscreenRequest(iframeRef)
    },[iframeLoadTrigger])
    */
    
    useEffect(()=>{
        if(caseFileLinksState.isLoaded === true && caseFileLinksState.fileLinks.length > 0){
            if(caseFileLinksState.linksToShow.length > 0){
                if(iframeRef && iframeRef.current){
                    iframeRef.current.addEventListener('load',(e:Event)=>{
                        
                        if(iframeRef.current !==null && iframeRef.current.contentWindow){
                            // send uls array to iframe via message chanel:
                            // init chanel
                            try{
                                iframeRef.current.contentWindow.postMessage(
                                    'init',
                                    "*", 
                                    [messageChannel.port2]
                                )
                                // sending urls
                                messageChannel.port1.postMessage(
                                    caseFileLinksState.linksToShow.map(item=> item.fileLink )
                                )
                            }catch(e){
                                console.log(`Failed to execute 'postMessage' on 'Window'`, e)
                            }
                        }
                        
                    })
                }
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[caseFileLinksState, iframeLoadTrigger])

    const focusOnIframe = () =>{
        if(iframeRef.current && iframeRef.current !== null){
            const iframeWindow:any = iframeRef.current
            iframeWindow.autofocus = true
            iframeWindow.contentWindow.focus()
        }
    }

    useEffect(()=>{
        getFileLinks({
            caseId,
            caseFileLinksState,
            setCaseFileLinkState,
            filesToOpenInSMI,
            onFinish: ()=>{
                // mark state as loaded
                setCaseFileLinkState((prev)=>{
                    return({ ...prev, isLoaded: true })
                })
                // Focus on Iframe after loading - 
                setTimeout(focusOnIframe ,500)
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])


    return(
        <>
            {   // INITIAL STATE
                caseFileLinksState.isLoaded === false && caseFileLinksState.fileLinks.length === 0 ?
                    <Preloader />
                : null
            }

            { // REQUEST PERFORMED BUT RESPONSE EMPTY
                caseFileLinksState.isLoaded === true && caseFileLinksState.fileLinks.length === 0 ?
                    <Warning />
                : null
            }

            {
                caseFileLinksState.isLoaded === true && caseFileLinksState.fileLinks.length > 0 ? 
                (
                    caseFileLinksState.linksToShow.length > 0 ?
                        <>  
                            
                            <div
                                style={{
                                    width: '100%',
                                    height: 'calc( 100vh - 148px )',
                                    borderLeft: 'none',
                                }}
                            >
                                <iframe
                                    title="SmileInspector"
                                    allow="cross-origin-isolated"
                                    ref = { iframeRef }
                                    style={{
                                        width: '100%',
                                        height: 'calc( 100vh - 148px )',
                                        border: 'none' ,
                                        borderLeft: 'none',
                                    }}
                                    src={ `${baseLinkUrl}?${'wait-file-urls'}` } // uls will be sended via messageChanel
                                    onLoad={()=>{ setIframeLoadTrigger(prev => !prev) }}
                                    onMouseOver={()=>{ focusOnIframe() }}
                                />
                            </div>
                        </>
                    :
                        (
                            filterFileLinksBySupportedExtensions(caseFileLinksState.fileLinks, caseFileLinksState.avalableExtensions).length > 0 ?

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Box>
                                        This case contains <strong>{ caseFileLinksState.avalableExtensions.map(extDesc => extDesc[0]).join()}</strong> files.<br/>
                                    </Box>
                                    <Box>
                                        Select files to display in Files tab.
                                    </Box>
                                </Box>

                            :

                                <Box
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        borderLeft: 'none',
                                        padding: 20
                                    }}
                                >
                                    No loadable files detected
                                </Box>
                        )
                )                
                
                : null
            }
        </>
    )
}

export default SmileInspector
