import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"

export type TApiGetUrlForCaseFileParams = {
    [enumApiParameter.ID     ] : string
    [enumApiParameter.S3_KEY ] : string
    attachment?                : boolean
}

export type TApiGetUrlForCaseFileResponse = {
    [enumApiParameter.S3_KEY     ]: string
    [enumApiParameter.FILE_LINK  ]: string
}

const getUrlForCaseFile = async ( requestParams: TApiGetUrlForCaseFileParams ): Promise<AxiosResponse<TApiGetUrlForCaseFileResponse>>  =>{
    
    return await API.get(
        `/v2/cases/${ requestParams.id }/file-links/${ requestParams.s3key }`,
        { 
            params:{ ...requestParams}
        }
    )
}

export default getUrlForCaseFile
