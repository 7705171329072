import API from '../api-base'
import { AxiosResponse } from "axios"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=Workflow%20service%20v1#/Configuration%20API/delete

const deleteConfiguration = async ( params: {
    key: string
} ): Promise<AxiosResponse>  =>{
    
    const { key } = params

    return await API.delete(
        `/api/configuration/${key}`
    )
}

export default deleteConfiguration
