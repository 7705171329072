import { Box , Button, Table, TableHead, TableCell, TableRow, TextField, Toolbar, TableBody, FormControl, FormHelperText, CircularProgress, Icon } from "@mui/material"
import { ReactElement, useEffect, useMemo, useState } from "react"
import CaseExtraView from "../Case/CaseExtraView"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import getEventLogsListByUser from "../../../Api/CaseManagement/v2/get-event-log-list-by-user"
import { useAction } from "../../../Store/Hook/use-action"
import { enumApiParameter, enumSORT } from "../../../Types/General"
import useValidation,{ enumFormValidationRule } from "../../formValidator"
import formatDateToLocal from "../../../Utils/format-date-to-local"
import { DateRangePicker } from 'rsuite'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { getDateInputTemplate } from '../../../Utils/format-date-to-local'
import isAfter from 'date-fns/isAfter'
import { Link } from "react-router-dom"
import Paginaton from "../IndexPage/CaseManagementPaginaton"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import colorLibrary, { buttonContainedStyle } from "../../../Utils/colors"
import adjustDateRange from "../../../Utils/adjust-date-range"

const UserHistory = () =>{
    const { 
        needUpdateListFromAPI,
        historyList,
        sorting,
        pagination,
        filter,
    } = useTypedSelector(state=>state.caseManagementUserHistory)

    const { 
        setNeedUpdateCaseManagementUserHistoryFromAPI,
        setCaseManagementUserHistoryError,
        setCaseManagementErrorNeedToShow,
        setCaseManagementUserHistory,
        setCaseManagementUserHistoryPagination,
        setCaseManagementUserHistoryFilter,
        setCaseManagementUserHistorySortingDirection,
    } = useAction()

    const [
        userName,
        isUserNameValid,
        userNameValidationError,
        onUserNameChange
    ] = useValidation([enumFormValidationRule.REQUIRED, enumFormValidationRule.EMAIL], filter.user)

    const [
        date,
        isDateValid,
        dateValidationError,
        onDateChange
    ] = useValidation([enumFormValidationRule.REQUIRED ] , filter.from && filter.to ? `${filter.from}|${filter.to}` : '')

    const [ isDataReceivedfromAPI, setDataReceivedFromAPI ] = useState<boolean | undefined>()
    const [ isLoading            , setLoading             ] = useState<boolean>(false)
    const [ historyListLength    , setHistoryListLength   ] = useState(historyList.length)

    useEffect(()=>{
        if(historyList.length > 0){
            if( userName === '' || date === '' ){
                setCaseManagementUserHistory([])
                setCaseManagementUserHistoryPagination({
                    number       : 0,
                    size         : pagination.size,
                    totalElements: 0
                })
            }
        }
        setDataReceivedFromAPI(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[userName, date])

    useEffect(()=>{
        if(needUpdateListFromAPI === true){
            setNeedUpdateCaseManagementUserHistoryFromAPI(false)

            if(isUserNameValid && isDateValid ){
                setCaseManagementUserHistoryFilter({
                    user: userName,
                    from: date.split('|')[0],
                    to  : date.split('|')[1], 
                })
                setLoading(true)
                setDataReceivedFromAPI(false)
                
                getEventLogsListByUser({
                    [enumApiParameter.USER         ]: userName,
                    [enumApiParameter.PERIOD_FROM  ]: date.split('|')[0], 
                    [enumApiParameter.PERIOD_TO    ]: date.split('|')[1], 
                    [enumApiParameter.DIRECTION    ]: sorting,
                    [enumApiParameter.PAGE_NUMBER  ]: pagination.number,
                    [enumApiParameter.PAGE_SIZE    ]: pagination.size
                })
                .then((response)=>{
                    setCaseManagementUserHistory(response.data)
                    setHistoryListLength(response.data.length)
                    setDataReceivedFromAPI(true)
                })
                .catch((error)=>{

                    setCaseManagementUserHistoryError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : "CANT GET USER EVENT LOG"
                    })
                    setCaseManagementErrorNeedToShow(true)
                    console.log('CANT GET USER EVENT LOG', error, error.request)
                    setDataReceivedFromAPI(false)
                })
                .finally(()=>{
                    setLoading(false)
                })

            }
        }
    // eslint-disable-next-line
    },[needUpdateListFromAPI])

    const historyListTableRows = useMemo(()=>{

        const result:ReactElement[] = []

        for(let i = 0; i<historyList.length; i++){

            result.push(
                <TableRow
                    hover
                    key={i}
                    sx={{
                        verticalAlign: 'top',
                        height: '30px',
                    }}
                >
                    <TableCell align="left" width={190}>
                        { formatDateToLocal(historyList[i].createdAt)  }
                    </TableCell>

                    <TableCell align="left" width={320}>
                        <Link to={`/case-management/case/${ historyList[i].caseId }`}>
                            { historyList[i].caseId }
                        </Link>
                    </TableCell>

                    <TableCell
                        width={110}
                        align="left"
                    >
                        { historyList[i].event }
                    </TableCell>

                    <TableCell align="left" sx={{fontSize: 10}}>
                        {
                            <CaseExtraView extra={
                                historyList[i].extraAttributes ? 
                                    Object.entries(historyList[i].extraAttributes)
                                : undefined
                            } />
                        }
                    </TableCell>

                </TableRow>
            )
            
        }

        return(result)
    },[historyList])

    const dateRangeValue:DateRange | null = 
        date ==='' || typeof(date) === 'undefined'   ? 
            null 
        :  
            [ 
                new Date(date.split('|')[0]) , 
                new Date(date.split('|')[1]) 
            ]

    return(
        <div>
            
            <Box>
                
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        
                        bgcolor: '#eee',
                        padding: '12px 0',
                        borderRadius: 1,
                        display: 'flex',
                        height: '92px',
                        justifyContent:'space-between',
                        alignItems: 'center',
                    }}
                >
                    <FormControl error = { !isUserNameValid }>
                        <TextField
                            autoFocus
                            id      = "searchByUserId"
                            label   = { 'Search By User (Case Sensitive)' }
                            type    = "text"
                            variant = "outlined"
                            value   = { userName }  // 'username@smiledirectclub.com' 
                            error     = { !isUserNameValid }
                            onChange={(e)=>{
                                onUserNameChange(e.target.value)
                                setCaseManagementUserHistory([])
                            }}
                            size='small'
                            sx={{ 
                                width: '450px',
                                marginRight: 0
                            }}
                        />
                        <FormHelperText>
                            { userNameValidationError }
                        </FormHelperText>
                    </FormControl>

                    <FormControl error = { !isDateValid }>
                        <DateRangePicker
                            placeholder="Created Date Range"
                            format={ getDateInputTemplate()[0] }
                            placement={'auto'}
                            onChange={(e: DateRange | null )=>{ 
                                if(e === null){
                                    onDateChange('',true)
                                }else{
                                    onDateChange(adjustDateRange(e).map(item=>item.toISOString()).join('|'))
                                    setCaseManagementUserHistory([])
                                }
                            }}

                            style={{
                                padding: '8px',
                                zIndex: 20,
                                width: '270px',
                                border: !isDateValid ? '1px solid red' : undefined,
                                borderRadius: '6px',
                            }}
                            disabledDate={ date => isAfter(date, new Date())}
                            value = { dateRangeValue }
                        />
                        <FormHelperText>
                            { dateValidationError }
                        </FormHelperText>
                    </FormControl>

                    <div style={{display:'flex', alignItems:'center', height:'100%', margin:'0 20px 0 0'}}>
                        <Button 
                            size="large" 
                            variant="contained" 
                            onClick={()=>{
                                onUserNameChange(userName)
                                onDateChange(date)
                                setNeedUpdateCaseManagementUserHistoryFromAPI(true)
                            }}
                            sx={ buttonContainedStyle }
                        >
                            Search
                        </Button>
                    </div>

                </Toolbar>
                
                {
                    historyList.length > 0 ?
                        <Box >
                            <Paginaton 
                                currentPageNumber       = { pagination.number }
                                currentPageSize         = { pagination.size   }
                                lastRequestRecordsCount = { historyListLength }

                                setPageNumber = {(pageNumber: number)=>{
                                    setCaseManagementUserHistoryPagination({
                                        ...pagination,
                                        number       : pageNumber,
                                        size         : pagination.size,
                                    })
                                    setNeedUpdateCaseManagementUserHistoryFromAPI(true)
                                    setLoading(true)
                                }}
                                setPageSize = {(pageSize:number)=>{
                                    setCaseManagementUserHistoryPagination({
                                        ...pagination,
                                        number       : 0,
                                        size         : pageSize,
                                    })
                                    setNeedUpdateCaseManagementUserHistoryFromAPI(true)
                                    setLoading(true)
                                }}
                            />
                        </Box>
                    :null
                }

                <Box 
                    style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflowY: 'auto'
                }}>
                    {
                        historyList.length > 0 ?
                            (
                                isLoading === true ?
                                    <Box sx={{
                                        width: 'calc( 100%)', 
                                        height: '426px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid #ddd',
                                        borderRadius: '4px',
                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                :
                                    <Table size="small">
                                        <TableHead 
                                            sx={{
                                                backgroundColor: colorLibrary.backgroundColor
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ color: '#fff', display: 'flex', cursor: 'pointer'}}
                                                    onClick={()=>{
                                                        if(sorting === enumSORT.ASC){
                                                            setCaseManagementUserHistorySortingDirection(enumSORT.DESC)
                                                        }else{
                                                            setCaseManagementUserHistorySortingDirection(enumSORT.ASC)
                                                        }
                                                        setNeedUpdateCaseManagementUserHistoryFromAPI(true)
                                                        setLoading(true)
                                                    }}
                                                > 
                                                    Created Date
                                                    <Icon>
                                                        { 
                                                            sorting === enumSORT.ASC ?
                                                                <ArrowDownwardIcon />
                                                            :
                                                                <ArrowUpwardIcon />
                                                        }
                                                    </Icon>
                                                </TableCell>
                                                <TableCell sx={{ color: '#fff'}}> CMS ID           </TableCell>
                                                <TableCell sx={{ color: '#fff'}}> Event            </TableCell>
                                                <TableCell sx={{ color: '#fff'}}> Extra Attributes </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                historyListTableRows
                                            }
                                        </TableBody>
                                    </Table>
                            )
                        :
                            <Box sx={{
                                width: 'calc( 100%)', 
                                height: '426px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '2px solid #ddd',
                                borderRadius: '4px',
                            }}>
                                
                                {
                                    isLoading === false && typeof(isDataReceivedfromAPI) === 'undefined' &&
                                    'Please, select search parameters'
                                }
                                {
                                    isLoading === false && isDataReceivedfromAPI === true &&
                                    'The search has not given any results'
                                }
                                
                            </Box>
                    }
                </Box>
            </Box>
        </div>
    )
}

export default UserHistory
