import { authClient } from "../App"

type TParams = (assessToken:string)=>void

const getAccessTokenAnd = async (funcToDo: TParams) =>{
    
    const { accessToken } = await authClient.tokenManager.getTokens()
    if (!accessToken) {
        authClient.token.getWithRedirect({
            responseType: 'code'
        })
    }else{
        funcToDo( accessToken.accessToken as string )
    }
}

export default getAccessTokenAnd

