import { Box, Button } from "@mui/material"
import { ChangeEvent, useState } from "react"
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { toolbarButtonStyle } from "../FilesToolbar"
import FilesTreeNodeDragAndDropUploader from "./FilesTreeNodeDragAndDropUploader"
import { FileDesc } from "./useDrag"

type TFilesToolbarActionUploadFileProps = {
    caseId             : string
    selectedNodes      : string[]
    needUpdateFileTree : ()=>void
}

export const convertFileListToFileDescArray = (filesList:FileList|null) =>{
    const fileListDesc:FileDesc[] = []
        if(filesList && filesList.length){
            for(let i = 0; i<filesList.length; i++){
                const file:File = filesList[i]
                fileListDesc.push({
                    file,
                    path:`/${file.name}`
                })
            }
        }
    return(fileListDesc)
}

const FilesToolbarActionUploadFile = (props:TFilesToolbarActionUploadFileProps) =>{
    const { 
        caseId,
        selectedNodes,
        needUpdateFileTree,
    } = props

    const [filesList, setFileList] = useState<FileDesc[]|undefined>([]) 

    const resetFilesList = () =>{
        setFileList(undefined)
    }

    const onInputChange = async (e: ChangeEvent<HTMLInputElement>) =>{
        e.preventDefault()
        setFileList(convertFileListToFileDescArray(e.target.files))
    }

    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}
            
        >   
            <label htmlFor="contained-button-file">
                <input 
                    id="contained-button-file" 
                    type="file" 
                    style={{display: 'none'}} 
                    onChange={onInputChange}
                    multiple
                />
                <Button size ='small' variant="contained" component="span" sx={{...toolbarButtonStyle, height: '30px'}}
                    
                >
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                        <NoteAddIcon/>
                        Upload
                    </Box>
                    
                </Button>
            </label>
            <FilesTreeNodeDragAndDropUploader
                caseId             = { caseId                                 }
                filesList          = { filesList                              }
                nodeId             = { selectedNodes[0] ? selectedNodes[0]: ''}
                resetFilesList     = { resetFilesList                         }
                needUpdateFileTree = { needUpdateFileTree                     }
            />
        </Box>
    )
}

export default FilesToolbarActionUploadFile
