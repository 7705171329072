import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTypedSelector } from '../../../../Store/Hook/use-typed-selector'
import useValidation, { enumFormValidationRule } from '../../../formValidator'
import { enumConfigType } from "./CreateConfig"
import getApplicationConfigByNameAndVersion from '../../../../Api/DesktopConfiguration/v2/get-application-config-by-name-and-version'
import { useAction } from '../../../../Store/Hook/use-action'
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-eclipse"
import getGroupConfig from '../../../../Api/DesktopConfiguration/v2/get-group-config'
import getUserConfig from '../../../../Api/DesktopConfiguration/v2/get-user-config'
import { TApiPostCreateUpdateApplicationConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-application-config'
import { TApiPostCreateUpdateGroupConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-group-config'
import { TApiPostCreateUpdateUserConfigParams } from '../../../../Api/DesktopConfiguration/v2/post-create-user-config'

export type TEditConfigProps ={
    configType    : enumConfigType
    buttonDisable : boolean
    updateData?   : {
        application? : string
        appVersion?  : string
        groupId?     : string
        login?       : string
        configJson?  : string
    }
    onUpdateConfig(props:TApiPostCreateUpdateApplicationConfigParams | TApiPostCreateUpdateGroupConfigParams | TApiPostCreateUpdateUserConfigParams) : void
}

const EditConfig = (props:TEditConfigProps) =>{

    const [ open                      , setOpen                           ] = useState(false)
    const [ isNeedToSendUpdateRequest , setIsNeedUpdateToSendRequest      ] = useState(false)
    const [ isNeedToGetConfigFromAPI  , setNeedToGetConfigFromAPI         ] = useState(false)

    const [
        application,
        isApplicationValid,
        ,
        onApplicationChange
    ] = useValidation([enumFormValidationRule.REQUIRED])
    

    const [
        groupId,
        isGroupIdValid,
        ,
        onGroupIdChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        version,
        ,
        ,
        onVersionChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        userLogin,
        isUserLoginValid,
        ,
        onUserLoginChange
    ] = useValidation([enumFormValidationRule.EMAIL])

    const [
        fileContent,
        isFileContentValid,
        fileContentValidationError,
        onFileContentChange

    ] = useValidation([enumFormValidationRule.JSON_FORMAT])

    const { selectedApplicationVersion } = useTypedSelector(store => store.desktopConfigurations.users)
    const {
        SetError,
        SetErrorNeedToShow,
    } = useAction()

    useEffect(()=>{
        if(isNeedToGetConfigFromAPI === true){
            if(props.configType === enumConfigType.APPLICATION){
                if(application && version){
                    getApplicationConfigByNameAndVersion({
                        appName: application,
                        appVersion: version
                    })
                    .then((response)=>{

                        setOpen(true)
                        if(response.data && response.data.data){
                            onFileContentChange(JSON.stringify(response.data.data,null, 2),true)
                        }else{
                            onFileContentChange(JSON.stringify(response.data,null, 2),true)
                        }
                    })
                    .catch((error)=>{
                        SetError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                        })
                        SetErrorNeedToShow(true)
                        console.log("CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME", JSON.stringify(error))
                    })
                }
            }

            if(props.configType === enumConfigType.GROUP){
                if(application && version && groupId){
                    getGroupConfig({
                        application,
                        appVersion : version,
                        groupId
                    })
                    .then((response)=>{
                        console.log(response.data)
                        setOpen(true)
                        if(response.data && response.data.data){
                            onFileContentChange(JSON.stringify(response.data.data,null, 2),true)
                        }else{
                            onFileContentChange(JSON.stringify(response.data,null, 2),true)
                        }
                    })
                    .catch((error)=>{
                        SetError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                        })
                        SetErrorNeedToShow(true)
                        console.log("CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME", JSON.stringify(error))
                    })
                    
                }
            }

            if(props.configType === enumConfigType.USER){
                if(application && version && userLogin){
                    getUserConfig({
                        application,
                        appVersion : version,
                        login      : userLogin
                    })
                    .then((response)=>{
                        setOpen(true)
                        if(response.data && response.data.data){
                            onFileContentChange(JSON.stringify(response.data.data,null, 2),true)
                        }else{
                            onFileContentChange(JSON.stringify(response.data,null, 2),true)
                        }
                    })
                    .catch((error)=>{
                        SetError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                        })
                        SetErrorNeedToShow(true)
                        console.log("CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME", JSON.stringify(error))
                    })
                }
            }

            setNeedToGetConfigFromAPI(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isNeedToGetConfigFromAPI])

    useEffect(()=>{
        if(isNeedToSendUpdateRequest === true){

            if( props.configType === enumConfigType.APPLICATION ){
                if(isApplicationValid && isFileContentValid){
                    handleClose()
                    props.onUpdateConfig({
                        application,
                        version,
                        data: fileContent
                    })
                }
            }

            if( props.configType === enumConfigType.GROUP ){

                if(isApplicationValid && isGroupIdValid && isFileContentValid){
                    handleClose()
                    props.onUpdateConfig({
                        application,
                        version,
                        groupId,
                        data: fileContent
                    })
                }
            }

            if( props.configType === enumConfigType.USER ){

                if(isApplicationValid && isUserLoginValid && isFileContentValid){
                    handleClose()
                    props.onUpdateConfig({
                        application,
                        version,
                        login: userLogin,
                        data: fileContent
                    })
                }
            }

            setIsNeedUpdateToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendUpdateRequest])

    const handleClickOpen = () => {
        
        if( 
            props.configType === enumConfigType.APPLICATION
        ){
            if( props.updateData){
                if(
                    props.updateData.application && props.updateData.appVersion
                ){
                    onApplicationChange(props.updateData.application, true)
                    onVersionChange(props.updateData.appVersion, true)
                }
            }
        }

        if( 
            props.configType === enumConfigType.GROUP
        ){
            if( props.updateData){
                if(
                    props.updateData.application && props.updateData.appVersion && props.updateData.groupId
                ){

                    onApplicationChange(props.updateData.application, true)
                    onVersionChange(props.updateData.appVersion, true)
                    onGroupIdChange(props.updateData.groupId, true)
                }
            }
        }

        if( 
            props.configType === enumConfigType.USER
        ){
            if(typeof(props.updateData)!=='undefined'){
                if(typeof(props.updateData.application)!=='undefined' && typeof(props.updateData.login)!=='undefined'){
                    onApplicationChange(selectedApplicationVersion ? selectedApplicationVersion.name : '', true)
                    onVersionChange(selectedApplicationVersion ? selectedApplicationVersion.version : '', true)
                    onUserLoginChange(props.updateData.login,true)
                }
            }
        }

        setNeedToGetConfigFromAPI(true)
    }

    const handleClose = () => {
        setOpen(false)
        onApplicationChange('',true)
        onGroupIdChange('', true)
        onFileContentChange('',true)
    }
    
    const performUpdateAction = () =>{

        if( props.configType === enumConfigType.APPLICATION ){

            onApplicationChange(application)
            onFileContentChange(fileContent)

        }

        if( props.configType === enumConfigType.GROUP ){

            onApplicationChange(application)
            onGroupIdChange(groupId)
            onFileContentChange(fileContent)

        }

        if( props.configType === enumConfigType.USER ){

            onApplicationChange(application)
            onUserLoginChange(userLogin)
            onFileContentChange(fileContent)

        }

        setIsNeedUpdateToSendRequest(true)
    }

    return(
        <>
            <Button
                disabled = { props.buttonDisable === true ? true : false }
                variant={
                    props.configType === enumConfigType.USER  || props.configType === enumConfigType.GROUP ? "contained" : "outlined"
                }
                size={ "small" }
                sx={{
                    margin: props.configType === enumConfigType.USER || props.configType === enumConfigType.GROUP ? 
                        '14px 4px 0 0' 
                    : 
                        '0 4px 0 0',
                    height: '30px',
                }}
                onClick={handleClickOpen}
            >
                Edit
            </Button>

            <Dialog 
                open={open} 
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth
            >

                <DialogTitle> Edit config </DialogTitle>

                <DialogContent>

                    <Box sx={{ margin: '20px 0 20px 0' }}>
                        <FormControl fullWidth error = { !isApplicationValid }>

                            <TextField
                                label= { 'Software' }
                                disabled
                                value={ application }
                            />

                            <TextField
                                sx={{
                                    margin: '20px 0 0 0'
                                }}
                                label= { 'Version' }    
                                disabled
                                value={ version }
                            />

                        </FormControl>

                        {
                        
                            props.configType === enumConfigType.GROUP ?
                                <FormControl fullWidth error = { !isGroupIdValid }>
                                    <TextField
                                        sx={{
                                            margin: '20px 0 0 0'
                                        }}
                                        label= { 'Group ID' }    
                                        disabled
                                        value={  groupId }
                                    />

                                </FormControl>
                            : null
                        }

                        <FormControl fullWidth error = { !isFileContentValid }
                            sx={{ padding: '20px 0 0 0'}}
                        >
                            <Box sx={{
                                margin: `20px 24px ${!isFileContentValid ? '0' : '20px'} 0`,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                width: '100%',
                                height: '300px',
                            }}>
                                <AceEditor
                                    mode="json"
                                    theme="eclipse"
                                    onChange={(value, stat) => {
                                        onFileContentChange(value)
                                    }}
                                    value={ 
                                        fileContent
                                    }

                                    editorProps={{ 
                                        $blockScrolling: true,
                                    }}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        border: '1px solid grey',
                                    }}
                                    setOptions={{
                                        useWorker: false
                                    }}
                                />
                            </Box>
                            <FormHelperText> { fileContentValidationError } </FormHelperText>
                        </FormControl>

                    </Box>

                    <DialogActions>
                        
                        <Button 
                            onClick  = { handleClose }
                            variant  = { 'outlined' }
                        >
                            Cancel
                        </Button>

                        <Button 
                            onClick  = { performUpdateAction }
                            disabled = { !isFileContentValid }
                            variant  = { 'contained' }
                        >
                            Apply
                        </Button>

                    </DialogActions>

                </DialogContent>

            </Dialog>

        </>
    )
}

export default EditConfig
