import { useEffect, useState } from "react"
import getConfigurations from "../../Api/Workflow/v2/get-configurations"
import { TWorkflowConfiguration } from "../../Types/Store/StoreWorkflow"
import { Box } from "@mui/material"
import { CircularProgress } from "@mui/material"

import DeleteWorkflowConfiguration from "./DeleteWorkflowConfiguration"
import EditWorkflowConfiguration from "./EditWorkflowConfiguration"
import CreateWorkflowConfiguration from "./CreateWorkflowConfiguration"
import ErrorModal, { TApiErrorDescription } from "./ErrorModal"



const Workflow = () =>{

    const [ apiError, setApiError] = useState<TApiErrorDescription>({
        status: '',
        path: '',
        message: '',
    })



    const [ isNeedUpdateFromApi, setNeedUpdateFromApi] = useState(true)
    const [ isLoading, setLoading ] = useState(true)
    const [ workflowConfigurations, setWorkflowConfigurations ] = useState<TWorkflowConfiguration[]>([]) 
    
    useEffect(()=>{
        if(isNeedUpdateFromApi=== true){
            setNeedUpdateFromApi(false)
            setLoading(true)
            
            getConfigurations()
            .then((response)=>{
                setWorkflowConfigurations(response.data.items)
            })
            .catch((error)=>{
                console.log('CANT GET WORKFLOW Configurations LIST FROM API', JSON.stringify(error))
                setApiError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT GET WORKFLOW Configurations LIST FROM API"
                })
            })
            .finally(()=>{
                setLoading(false)
            })

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedUpdateFromApi])

    return(
        <div>
            {
                isLoading === true ?
                    <Box sx={{
                        width: 'calc( 100% - 350px)', 
                        height: '426px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                    }}>
                        <CircularProgress/>
                    </Box>
                :
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '20px 0 0 0',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderBottom: '1px solid #888',
                                margin: '4px 8px',
                                padding: '8px',
                                fontWeight: 'bold',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    minWidth: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                    }}
                                >
                                    Property Name
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '200px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0 8px 0 8px',
                                        padding: '0 8px',
                                    }}
                                >
                                    Value
                                </Box>
                            </Box>
                        </Box>

                        {
                            workflowConfigurations.map((workflowItem, workflowItemIndex)=>{
                                return(
                                    <Box
                                        key={`workflowItem-${workflowItemIndex}-${workflowItem.key}`}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: '1px solid #888',
                                            margin: '4px 8px',
                                            borderRadius: '6px',
                                            padding: '8px',

                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                }}
                                            >
                                                { workflowItem.description }
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '200px',
                                                    border: '1px solid #ddd',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    margin: '0 8px 0 8px',
                                                    padding: '0 8px',
                                                }}
                                            >
                                                { workflowItem.value }
                                            </Box>


                                            <EditWorkflowConfiguration
                                                needUpdateFromApi = { ()=>{
                                                    setNeedUpdateFromApi(true)
                                                }}
                                                itemToEdit  = { workflowItem }
                                                setApiError = { setApiError  }
                                            />

                                            <DeleteWorkflowConfiguration
                                                needUpdateFromApi = { ()=>{
                                                    setNeedUpdateFromApi(true)
                                                }}
                                                itemToDelete = { workflowItem }
                                                setApiError = { setApiError  }
                                            />
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                        <CreateWorkflowConfiguration 
                            needUpdateFromApi = { ()=>{setNeedUpdateFromApi(true)} }
                            setApiError       = { setApiError                      }
                        />

                    </Box>
            }

            <ErrorModal
                isNeedToShow = { Boolean(apiError.status && apiError.path && apiError.message) }
                status  = { apiError.status  }
                path    = { apiError.path    }
                message = { apiError.message }
                setNeedToHide={()=>{
                    setApiError({
                        status: '',
                        path: '',
                        message: '',
                    })
                }}
            />

        </div>
    )
}

export default Workflow
