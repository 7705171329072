
export type TMtpCaseListTableHederDescriptor = {
    id         : string
    label      : string
    align      : 'left' | 'right' | 'center'
    isSortable : boolean
}

export const tableHeaderDescription:TMtpCaseListTableHederDescriptor[] = [
    {
        id         : 'id',
        label      : 'ID',
        align      : 'left',
        isSortable : false,
    },
    {
        id        : 'status',
        label     : 'Status',
        align     : 'left',
        isSortable: false,
    },
    {
        id: 'createdAt',
        label: 'Created Date',
        align: 'left',
        isSortable: true,
    },

    {
        id: 'completedAt',
        label: 'Completed Date',
        align: 'left',
        isSortable: false,
    },

    {
        id: 'info',
        label: 'Info',
        align: 'left',
        isSortable: false,
    }
]
