
// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=Workflow%20service%20v1#/Configuration%20API/create

import API from '../api-base'
import { AxiosResponse } from "axios";
import { TWorkflowConfiguration } from "../../../Types/Store/StoreWorkflow";

type TResonse = TWorkflowConfiguration

const postCreateConfiguration = async (params:TWorkflowConfiguration): Promise<AxiosResponse<TResonse>> =>{
    return await API.post(
    
        `/api/configuration`,
    
        { 
            ...params
        }
    )
}

export default postCreateConfiguration
