import formatDateToLocal from "../../../../Utils/format-date-to-local"

const converter = (key:string, value: string | number):string | number =>{
    if(key==='createdAt' || key==='updatedAt'){
        if(Number.isInteger(value)){
            formatDateToLocal(new Date(value).toISOString())
        }else{
            return  formatDateToLocal(String(value))
        }
    }
    if(key==='data'){
        return JSON.stringify(value)
    }
    return value
}

export default converter
