const headerName = [
    {
        path: "/",
        element: <> Case Management </>,
    },
        { 
            path: "case-management/case/:caseId",
            element: <> Case </>,
        },
        { 
            path: "case-management/case/:caseId/files",
            element: <> Case </>,
        },
        { 
            path: "case-management/case/:caseId/history",
            element: <> Case </>,
        },
        { 
            path: "case-management/case/:caseId/inspector",
            element: <> Case </>,
        },
    { 
        path: "case-management/user-history",
        element: <> User History </>,
    },
    
    { 
        path: "case-management/case-status-transitions",
        element: <> Status Transition </>,
    },
    
    { 
        path: "case-management/workflow",
        element: <> Workflow </>,
    },
    { 
        path: "groups",
        element: <> Groups </>,
    },
        { 
            path: "groups/:uuid",
            element: <> Group </>,
        },
    { 
        path: "mobile-treatment-plan",
        element: <> Mobile Treatment Plan </>,
    },
        { 
            path: "mobile-treatment-plan/:caseId",
            element: <> MTP Case </>,
        },
    { 
        path: "desktop-configuration",
        element: <> Desktop Configuration </>,
    },
]

export default headerName
