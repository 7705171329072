import getIconByFilenameExtension from "../../../../../Utils/getIconByFilenameExtension"
import { TTreeFileDescription } from "./getFilesTree"
import FolderIcon from '@mui/icons-material/Folder'


export const getIconByFiledescritpion = (fileDesc:TTreeFileDescription) =>{
    let iconComponent = FolderIcon
        
    if(fileDesc.type === 'FILE'){
        iconComponent = getIconByFilenameExtension(fileDesc.name)
    }

    return( iconComponent )
}
