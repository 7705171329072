import packageJson from '../../../package.json'

const AppVersion = () =>{

    const { APP_VERSION } = window

    return(
        <div id='app-version'>
            v { 
                APP_VERSION ?
                    APP_VERSION
                : 
                    packageJson.version 
            }
        </div>
    )
}

export default AppVersion
