import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseByCaseIdParams } from "./get-case-by-case-id"
import { TComment } from "../../../Pages/CaseManagement/Case/Comments/useComments"

// https://smiledirectclub.atlassian.net/browse/TPW-975
// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/getByCaseId_1


export type TApiGetCommentsResponse = {
    items:TComment[]
}

const getCommentsByCaseId = async ( requestParams: TApiGetCaseByCaseIdParams ): Promise<AxiosResponse<TApiGetCommentsResponse>>  =>{
    return await API.get(
        `/v2/cases/${requestParams.caseId}/comments`
    )
}

export default getCommentsByCaseId
