import { FunctionComponent, useEffect, useMemo } from "react"
import { enumApiParameter, enumSortingField } from "../../Types/General"
import getGroupsByName from "../../Api/UserGroups/get-groups-by-name"
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { useAction } from "../../Store/Hook/use-action"
import { useTypedSelector } from "../../Store/Hook/use-typed-selector"
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'
import GroupAddNew from './GroupAddNew'
import ErrorModal from "./ErrorModal"
import { StyledTableCell, StyledTableRow } from "../../Components/StyledTableParts"
import ConfigTableHeader from "../DesktopConfigurations/TabsComponents/Tables/ConfigTableHeader"
import formatDateToLocal from "../../Utils/format-date-to-local"
import { userGroupsTableHeaderDescription } from "../DesktopConfigurations/TabsComponents/Tables/tableHeaderDescription"

const Groups: FunctionComponent = () =>{
    

    const { 
        setGroupsFromAPI,
        setGroupsSorting,
        setGroupsPagination,
        setNeedUpdateUsersFromAPI,
        setNeedUpdateGroupsFromAPI,

    } = useAction()

    const {
        list,
        pagination,
        sorting,
        needUpdateDataFromAPI
    } = useTypedSelector( state => state.groups)

    useEffect(()=>{
        if(needUpdateDataFromAPI === true){
            getGroupsByName({
                [enumApiParameter.DIRECTION  ] : sorting,
                [enumApiParameter.PAGE_NUMBER] : pagination.number,
                [enumApiParameter.PAGE_SIZE  ] : pagination.size
            }).then((response)=>{
                setGroupsFromAPI({
                    groups: response.data.content,
                    pagination:{
                        number: response.data.number,
                        size: response.data.size,
                        totalElements: response.data.totalElements,
                    }
                })
            }).catch((error)=>{
                console.log('GET GROUPS BY NAME ERROR', error, error.request)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateDataFromAPI])

    const groupsList = useMemo(()=>{
        return(
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">

                    <ConfigTableHeader
                        headerDescription    = { userGroupsTableHeaderDescription }
                        sorting = {{
                            sortingDirection: sorting,
                            sortingField: enumSortingField.GROUP_NAME 
                        }}
                        SetSortingDirection  = { setGroupsSorting                 }
                        SetNeedUpdateFromAPI = { setNeedUpdateGroupsFromAPI       }
                    />

                    <TableBody>
                        {
                            list.map((item, index)=>{
                                return(
                                    
                                        <StyledTableRow key={String(index) + item.id}>
                                            <StyledTableCell align="justify" >

                                                <Link to={`/groups/${item.id}`} onClick={()=>{
                                                    setNeedUpdateUsersFromAPI(true)
                                                }}>
                                                    { item.name }
                                                </Link>
                                                
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                { item.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                { item.version }
                                            </StyledTableCell>
                                            <StyledTableCell align="justify">
                                                { item.description }
                                            </StyledTableCell>
                                            <StyledTableCell align="justify">
                                                {
                                                    formatDateToLocal(item.createdAt) 
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="justify">
                                                { 
                                                    formatDateToLocal(item.updatedAt)
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                )
                            })

                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[list, needUpdateDataFromAPI])

    const lastElementsCount = pagination.totalElements % pagination.size
    
    return(
        <div
            style={{
                margin: '0 10px 0 10px'
            }}
        >
            <h2>
                Groups
            </h2>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    
                    <Pagination
                        variant="outlined" 
                        shape="rounded"
                        color="secondary"
                        sx={{margin: '0 0 8px 0'}}
                        page={pagination.number+1} 
                        count={(pagination.totalElements - lastElementsCount)/pagination.size + ( lastElementsCount === 0 ? 0 : 1  )} 
                        onChange={
                            (e:any, clickedPageNumber:number)=>{ 
                                setGroupsPagination(clickedPageNumber-1)
                            }
                        }
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <GroupAddNew />
                </Box>
                
            </Box>

            { groupsList }
            <ErrorModal/>
        </div>
    )
}

export default Groups
