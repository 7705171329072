import API from '../api-base'
import { TComment } from '../../../Pages/CaseManagement/Case/Comments/useComments'
import { enumApiParameter } from '../../../Types/General'

// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/updateComment

export type TApiEditCaseCommentParams = {
    [enumApiParameter.CASE_ID    ] : string
    commentId                      : string
    content: string
}


const putEditCaseComment = async ( params: TApiEditCaseCommentParams ): Promise<TComment>  =>{
    return await API.put(
        `/v2/cases/${params.caseId}/comments/${params.commentId}`,
        { 
            content: params.content
        }
    )
}

export default putEditCaseComment
