import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material"
import { TCasePropertyForUpdate } from "../Case"
import useValidation, { enumFormValidationRule } from "../../../formValidator"
import { TCase } from "../../../../Types/Store/StoreCaseManagement"
import EditIcon from '@mui/icons-material/Edit'
import { useState, useEffect} from 'react'
import patchUpdateCaseTags from "../../../../Api/CaseManagement/v2/patch-update-case-tags"
import { useAction } from "../../../../Store/Hook/use-action"
import EditValueDialog from "./EditValueDialog"

type TCaseTagFieldProps = {
    setNeedUpdateProperty: React.Dispatch<React.SetStateAction<TCasePropertyForUpdate>>
    caseToView: TCase
}

const CaseTagField = (props:TCaseTagFieldProps) =>{

    const { caseToView, setNeedUpdateProperty } = props
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    const [
        localTagValue,
        isLocalTagValueValid,
        localTagValueError,
        onLocalTagValueChange
    ] = useValidation(
        [enumFormValidationRule.STRING_ARRAY], 
        caseToView.tags ? caseToView.tags.join(',') : ''
    )

    const [ open, setOpen] = useState(false)
    const [ isNeedToSendTags, setNeedToSendTags ] = useState(false)

    const handleClickOpenDialog = () => {
        onLocalTagValueChange(caseToView.tags ? caseToView.tags.join(',') : '', true)
        setOpen(true)
    }
    const handleCloseDialog = () => {
        setOpen(false)
        onLocalTagValueChange('',true)
    }
    const handleClickApply = () =>{
        if(isLocalTagValueValid){
            setNeedToSendTags(true)
        }
    }

    useEffect(()=>{
        if(isNeedToSendTags === true){
            setNeedToSendTags(false)
            if(isLocalTagValueValid){
                patchUpdateCaseTags({
                    caseId: caseToView.id,
                    tags: localTagValue.split(',')
                })
                .then((response)=>{
                    setNeedUpdateProperty('all')
                })
                .catch((error)=>{
                    console.log('CANT UPDATE TAGS ', {
                        caseId: caseToView.id,
                        tags: localTagValue.split(',')
                    } )
                    setCaseManagementError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT UPDATE TAGS ' + localTagValue.split(',')
                    })
                    setCaseManagementErrorNeedToShow(true)
                })
                .finally(()=>{
                    handleCloseDialog()
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendTags])
    
    return(
        <TableRow
            hover
            key={'specialFieldTag'}
            sx={{
                maxWidth : '100%',
            }}
        >
            <TableCell 
                align="right"
                sx={{ border: 'unset'}}
            >
                <strong>Tags</strong>
            </TableCell>
            
            <TableCell 
                align="left"
                sx={{ border: 'unset'}}
            >
                <EditValueDialog
                    open = { open }
                    handleCloseDialog = { handleCloseDialog                      }
                    handleClickApply  = { handleClickApply                       }
                    title             = { 'Change tags'                          }
                    id                = { 'tagsValue'                            }
                    value             = { localTagValue                          }
                    label             = { 'Enter tag values separated by commas' }
                    isValidValue      = { isLocalTagValueValid                   }
                    valueError        = { localTagValueError                     }
                    onChangeValue     = { (e)=>{
                        if(String(e.target.value).trim() === ''){
                            onLocalTagValueChange(String(e.target.value).trim(),true)
                        }else{
                            onLocalTagValueChange(String(e.target.value),false)
                        }
                    }}
                />
                
                <div>
                    
                    {
                        caseToView.tags ? caseToView.tags.join(',') : ''
                    }
                    <Tooltip title="Change tags">
                        <IconButton
                            size="small"
                            onClick={handleClickOpenDialog}
                        >
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default CaseTagField
