import { ChangeEvent } from 'react'
import useDrag from "./useDrag"
import FilesTreeNodeDragAndDropUploader from './FilesTreeNodeDragAndDropUploader'
import { Box, Button } from '@mui/material'
import { toolbarButtonStyle } from '../FilesToolbar'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { convertFileListToFileDescArray } from './FilesToolbarActionUploadFile'

type TCaseFilesUploader = {
    caseId : string | undefined
    needUpdateFileTree: () => void
}

const CaseFilesUploader = (props:TCaseFilesUploader) =>{

    const {
        caseId,
        needUpdateFileTree,
    } = props

    const { 
        isDragged,
        droppedFilesDesc,
        dragHandler,
        dragLeaveHandler,
        dropHandler,
        resetDroppedFilesList,
        setDroppedFilesDesc,
    } = useDrag()

    const onInputChange = async (e: ChangeEvent<HTMLInputElement>) =>{
        e.preventDefault()
        setDroppedFilesDesc(convertFileListToFileDescArray(e.target.files))
    }

    return(
        <div
            style={{ 
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent:'center',
                alignItems: 'center',
                flexDirection: 'column',
                border: `4px solid ${isDragged ? '#f00' : '#fff'}`,
                backgroundColor: '#eee'
            }}
            onDragStart = { dragHandler      }
            onDragOver  = { dragHandler      }
            onDragLeave = { dragLeaveHandler }
            onDrop      = { dropHandler      }
        >
            <div
                style={{ margin : '20px'}} 
            >
                There are no files yet. 
            </div>
            <div>
                You can upload files by <strong>Drag'n'Drop</strong> or... 
            </div>
            <div
                style={{ margin : '20px'}} 
            >
                use
            </div>
            <div>
                
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                    
                >   
                    <label htmlFor="contained-button-file">
                        <input 
                            id="contained-button-file" 
                            type="file" 
                            style={{display: 'none'}} 
                            onChange={onInputChange}
                            multiple
                        />
                        <Button 
                            size ='large' 
                            variant="contained" 
                            component="span" 
                            sx={{
                                ...toolbarButtonStyle,
                                backgroundColor: '#5700ff',
                                '&:hover':{
                                    backgroundColor: '#5700ff',
                                }
                            }}
                        >
                            
                            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                <NoteAddIcon/>
                                Upload
                            </Box>
                            
                        </Button>
                    </label>
                </Box>
            </div>
            
            <FilesTreeNodeDragAndDropUploader
                caseId            = { caseId ? caseId : ''      }
                nodeId            = { 'ROOTFOLDER|'             }
                filesList         = { droppedFilesDesc          }
                resetFilesList    = { resetDroppedFilesList     }
                needUpdateFileTree= { needUpdateFileTree        }
            />

        </div>
    )
}


export default CaseFilesUploader
