import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import putCreateGroup from '../../Api/UserGroups/put-create-group'
import postUpdateGroup from '../../Api/UserGroups/post-update-group'
import { useAction } from '../../Store/Hook/use-action'
import useValidation, { enumFormValidationRule } from '../formValidator'
import { FormControl, FormHelperText } from '@mui/material'
import colorLibrary from '../../Utils/colors'

export type TFormAddGroupProps = {
    editMode?: boolean
    uuid?: string
    groupName?:string
    groupDescription?:string
}

export default function FormDialog(props: TFormAddGroupProps) {
    const [ open                , setOpen                ] = useState(false)
    const [ isNeedToSendRequest , setIsNeedToSendRequest ] = useState(false)
    
    const { 
        SetNeedUpdateCurrentGroupFromApi, 
        setNeedUpdateGroupsFromAPI,
        SetUserGroupsError, 
        SetUserGroupsErrorNeedToShow, 
    } = useAction()
    const [
        groupName,
        isGroupNameValid,
        groupNameValidationError,
        onGroupNameChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    const [
        groupDescription,
        isGroupDescriptionValid,
        groupDescriptionValidationError,
        onGroupDescriptionChange
    ] = useValidation([enumFormValidationRule.REQUIRED])
    
    useEffect(()=>{
        if(isNeedToSendRequest === true){
            if( isGroupNameValid && isGroupDescriptionValid){
                handleClose()
                if(props.editMode !== true){
                    putCreateGroup({
                        name: groupName,
                        description: groupDescription  
                    }).then((response)=>{
                        setNeedUpdateGroupsFromAPI(true)
                    }).catch((error)=>{
                        console.log("CAN'T ADD User to group", error.response, error)
                        SetUserGroupsError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CAN'T ADD User to group"
                        })
    
                        SetUserGroupsErrorNeedToShow(true)
                    })
                }else{
                    postUpdateGroup({
                        id: props.uuid ? props.uuid : '',
                        name: groupName,
                        description: groupDescription
                    }).then((response)=>{
                        SetNeedUpdateCurrentGroupFromApi(true)
                    }).catch((error)=>{
                        console.log("CAN'T UPDATE group", error.response, error)
                        SetUserGroupsError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CAN'T UPDATE group"
                        })
    
                        SetUserGroupsErrorNeedToShow(true)
                    })
                }
            }
            setIsNeedToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    const handleClickOpen = () => {
        setOpen(true)
        if(props.editMode){
            onGroupNameChange(props.groupName ? props.groupName : '', true)
            onGroupDescriptionChange(props.groupDescription ? props.groupDescription : '', true)
        }
    }

    const handleClose = () => {
        setOpen(false)
        onGroupNameChange('', true)
        onGroupDescriptionChange('', true)
    }

    const handleAddGroup = () => {
        onGroupNameChange(groupName)
        onGroupDescriptionChange(groupDescription)
        setIsNeedToSendRequest(true)
    }

    return (
        <div>
            <Button 
                variant={
                    props.editMode ? 'outlined' : 'contained'
                }
                color='primary' onClick={handleClickOpen} 
                sx={{
                    margin          : props.editMode ? undefined : '0 0 8px 0',
                    borderColor     : props.editMode ? colorLibrary.backgroundColor : undefined,
                    color           : props.editMode ? colorLibrary.backgroundColor : undefined,
                    backgroundColor : props.editMode ? undefined : colorLibrary.backgroundColor,
                    '&:hover'       : props.editMode ? undefined : { backgroundColor: colorLibrary.backgroundColor}
                }}
            >
                {
                    props.editMode ? 'Edit': 'Add group'

                }
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {
                        props.editMode ? 
                        'Edit group'
                        :
                        'Add new group'
                    }
                    
                </DialogTitle>
                <DialogContent sx={{minWidth: 500}}>
                <DialogContentText>
                    {
                        props.editMode ? 
                        "Edit group Name and group's description"
                        :
                        "Enter group Name and group's description"
                    }
                    
                </DialogContentText>
                <FormControl fullWidth error = { !isGroupNameValid }>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Group Name"
                        type="text"
                        error = { !isGroupNameValid }
                        fullWidth
                        variant="standard"
                        value={groupName}
                        onChange={(e)=>{
                            onGroupNameChange(e.target.value)
                        }}
                    />
                    <FormHelperText>
                        { groupNameValidationError }
                    </FormHelperText>
                </FormControl>
                <FormControl fullWidth error = { !isGroupDescriptionValid }>
                    <TextField
                        fullWidth 
                        margin    = "dense"
                        id        = "name"
                        label     = "Description"
                        type      = "text"
                        error     = { !isGroupDescriptionValid }
                        variant   = "standard"
                        value     = { groupDescription }
                        
                        onChange  = {(e)=>{
                            onGroupDescriptionChange(e.target.value)
                        }}
                    />
                    <FormHelperText>
                        { groupDescriptionValidationError }
                    </FormHelperText>
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAddGroup}>
                    {
                        props.editMode ? 
                        'Save'
                        :
                        'Add'
                    }
                    
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
