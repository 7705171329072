import { AxiosResponse } from "axios"
import API from './api-base'
import { enumApiParameter } from "../../Types/General"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=UserGroups%20service%20v1#/Groups%20API/update

export type TApiPostUpdateGroup = {
    [enumApiParameter.ID          ] : string 
    [enumApiParameter.NAME        ] : string 
    [enumApiParameter.DESCRIPTION ] : string 
}

const postUpdateGroup = async ( params: TApiPostUpdateGroup ): Promise<AxiosResponse>  =>{
    return await API.post(
        
        `/groups`,
        
        { 
            ...params 
        }
    )
}

export default postUpdateGroup
