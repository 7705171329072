import { DateRange } from 'rsuite/esm/DateRangePicker'

const setTimeTo0000 = ( timeTo0000:Date ) =>{
    timeTo0000.setHours(0)
    timeTo0000.setMinutes(0)
    timeTo0000.setSeconds(0)
    timeTo0000.setMilliseconds(0)
    return(timeTo0000)
}

const setTimeTo2359 = ( timeTo2359:Date ) =>{
    timeTo2359.setHours(23)
    timeTo2359.setMinutes(59)
    timeTo2359.setSeconds(59)
    timeTo2359.setMilliseconds(999)
    return(timeTo2359)
} 

const adjustDateRange = (e:DateRange):DateRange =>{
    
    const [dateStart, dateEnd] = e
    const newDateStart = new Date(dateStart)
    const newDateEnd = new Date(dateEnd)
    return([ 
        setTimeTo0000(newDateStart), 
        setTimeTo2359(newDateEnd)
    ])
}

export default adjustDateRange
