import { useMemo } from 'react'
import { Box } from "@mui/material"
import { TCaseFileDescription } from "../../../../Types/CaseManagement"
import { TreeItem, TreeView } from "@mui/x-tree-view"
import IconSquarePlus from "./IconSquarePlus"
import IconSquareMinus from "./IconSquareMinus"
import convertNodesRecordsToNodesTree from './Utils/convertNodesRecordsToNodesTree'
import convertFilesToNodesRecords from './Utils/convertFilesToNodesRecords'
import FilesTreeNode, { nodeStyle } from './FilesTreeNode'
import FolderIcon from '@mui/icons-material/Folder'
import useDrag from './FilesUploader/useDrag'
import FilesTreeNodeDragAndDropUploader from './FilesUploader/FilesTreeNodeDragAndDropUploader'

type TFilesTreeProps = {
    caseId       : string
    filesList    : TCaseFileDescription[]
    newFolderPath: string[][]
    needUpdateFileTree: ()=>void

    expandedNodes     : string[]
    setExpandedNodes  : (newExpandedNodes:string[])=>void

    setSelectedNodes  : (newSelectedNodes:string[])=>void
}

const FilesTree = (props: TFilesTreeProps) =>{
    const {
        caseId,
        filesList, 
        newFolderPath,
        needUpdateFileTree,
        expandedNodes,
        setExpandedNodes,
        setSelectedNodes,
    } = props
    
    const nodesTree = useMemo(()=>{
        return( convertNodesRecordsToNodesTree(convertFilesToNodesRecords(filesList,newFolderPath)))
    },[filesList, newFolderPath])

    const { 
        isDragged,
        dragHandler,
        dragLeaveHandler,
        dropHandler,
        droppedFilesDesc,
        resetDroppedFilesList,
    } = useDrag()



    const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {

        if(nodeIds.length === 1){
            const folderPrefix = 'FOLDER|'
            const idString = nodeIds[0]
            const isFolder = idString.indexOf(folderPrefix) > -1
            if(isFolder === true){

                let isNodeSelected = false
                for(let i = 0; i<expandedNodes.length; i++){
                    if(expandedNodes[i].indexOf(idString)>-1){
                        isNodeSelected = true
                        break
                    }
                }
                if(isNodeSelected === true){
                    setExpandedNodes([
                        ...expandedNodes.filter(nodeId=> nodeId.indexOf(idString) === -1 )
                    ])
                }else{
                    const splittedFoldersPath = idString.split('/') 
                    const foldersInPath = splittedFoldersPath.slice(1,splittedFoldersPath.length)
    
                    const nodesIdForAllFolderInPath:string[] = ['ROOTFOLDER|']
                    let folderAccumulator = `${folderPrefix}`
                    foldersInPath.forEach((folderItem)=>{
                        folderAccumulator = `${folderAccumulator}/${folderItem}`
                        nodesIdForAllFolderInPath.push(folderAccumulator)
                    })
                    setExpandedNodes(nodesIdForAllFolderInPath)
                }
            }
        }

        setSelectedNodes(nodeIds)
    }

    return(
        <Box>
            <TreeView
                multiSelect         
                defaultCollapseIcon = { <IconSquareMinus />}
                defaultExpandIcon   = { <IconSquarePlus /> }
                onNodeSelect        = { handleSelect       }
                expanded            = { expandedNodes      }
                sx={{
                    margin: '6px 0',
                }}
            >
                <TreeItem
                    nodeId = { 'ROOTFOLDER|' }
                    label  = {
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FolderIcon
                                sx={{
                                    fill:'#5700ff',
                                    margin: '0 6px 0 0'
                                }}
                            />
                                
                            /
                        </Box>
                    }
                    sx={{ 
                        ...nodeStyle,
                        border: `2px solid ${ isDragged ? '#f00' : '#fff'}`
                    }}
                    onDragStart = { dragHandler      }
                    onDragOver  = { dragHandler      }
                    onDragLeave = { dragLeaveHandler }
                    onDrop      = { dropHandler      }
                >
    
                    {
                        nodesTree.map((nodeItem, nodeIndex)=>{
                            return(
                                <FilesTreeNode
                                    caseId = { caseId      }
                                    key    = { nodeItem.id }
                                    node   = { nodeItem    }
                                    needUpdateFileTree={ needUpdateFileTree }
                                />
                            )
                        })
                    }
                    <FilesTreeNodeDragAndDropUploader
                        caseId            = { caseId                    }
                        key               = { `filesUploader-root`      }
                        nodeId            = { 'ROOTFOLDER|'             }
                        filesList         = { droppedFilesDesc          }
                        resetFilesList    = { resetDroppedFilesList     }
                        needUpdateFileTree= { needUpdateFileTree        }
                    />
                </TreeItem>
            </TreeView>
        </Box>
    )
}

export default FilesTree
