import { Box } from "@mui/material"
import FileUploaderTreeItem from "./FileUploaderTreeItem"
import { TUploadTreeNode } from "./FilesTreeNodeDragAndDropUploader"
import getIconColorByFilenameExtension from "../../../../../Utils/getIconColorByFilenameExtension"
import FolderIcon from '@mui/icons-material/Folder'
type TFileUploaderTreeProps = {
    nodesArray: TUploadTreeNode[]
    isUploadStarted: boolean
    depthLevel?: number
}

const FileUploaderTree = (props:TFileUploaderTreeProps) =>{
    
    const {
        isUploadStarted,
        nodesArray,
        depthLevel
    } = props
    
    return(
        <>
            {
                nodesArray.map((nodeItem, nodeItemIndex)=>{
                    return(
                        nodeItem.childs.length === 0 ?
                            <FileUploaderTreeItem
                                key={ `uploaderNode${nodeItem.nodId}-${nodeItemIndex}` }
                                isUploadStarted = { isUploadStarted }
                                nodeItem = {nodeItem}
                                depthLevel = { depthLevel ? depthLevel + 1 : 0 }
                            />
                        :
                            <Box
                                key={ `uploaderNodeFolder${nodeItem.nodId}-${nodeItemIndex}` }
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        component={FolderIcon}
                                        sx={{
                                            fill: getIconColorByFilenameExtension(nodeItem.name),
                                            margin: '0 10px 0 0',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {
                                            nodeItem.name
                                        }
                                    </Box>
                                </Box>
                                <FileUploaderTree
                                    key={ `uploaderNode${nodeItem.nodId}-${nodeItemIndex}` }
                                    isUploadStarted = { isUploadStarted }
                                    nodesArray={ nodeItem.childs }
                                    depthLevel = { depthLevel ? depthLevel : 1  }
                                />
                            </Box>
                    )
                })
            }
        </>
    )
}

export default FileUploaderTree
