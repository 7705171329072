import { AxiosResponse } from "axios"
import API from '../api-base'

import { enumApiParameter } from "../../../Types/General"
import { TApiPostCreateUpdateApplicationConfigParams } from "./post-create-application-config"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/Application%20configuration%20API/update_2

const putUpdateApplicationConfig = async ( params: TApiPostCreateUpdateApplicationConfigParams ): Promise<AxiosResponse>  =>{
    return await API.put(

        `/v2/configurations/applications/${ String(params[enumApiParameter.APPLICATION]) }/${params[enumApiParameter.VERSION]}`,
        
        params.data
        
    )
}

export default putUpdateApplicationConfig
