export enum enumStoreBaseAction {
    SET_BASE_USER_INFO_FROM_API = 'SET_BASE_USER_INFO_FROM_API',
   
}


export interface IStoreBase {
    userInfo:{
        email?: string
    }
}

export type TBaseStoreAction = TBaseActionSetUserInfoFromAPI


export type TBaseActionSetUserInfoFromAPI = {
    type: enumStoreBaseAction.SET_BASE_USER_INFO_FROM_API
    payload: string
}
