import formatBytes from "../../../Utils/format-bytes"


export type TCaseExtraViewProps = {
    extra?: [string, unknown][]
    exclude?: string[]
} 
const CaseExtraView = (props: TCaseExtraViewProps) =>{
    
    const { extra } = props

    return(
        <>
        {
            extra ? 
                extra
                .map((item,index)=>{
                    
                    let itemContent =
                        <>
                            <div style={{
                                minWidth       : 132,
                                fontWeight     : 'bold',
                                fontSize       : '12px',
                                display        : 'flex',
                                justifyContent : 'flex-end',
                                margin         : '0 10px 0 0',
                            }}>
                                { item[0]}:
                            </div> 

                            {
                                    item[0] === 'size' ?
                                        formatBytes(Number(item[1]))
                                    :
                                        `${item[1]} ` 
                            }
                            <br/>
                        </>


                    return(


                    <div key={`$extra-${index}`}
                        style={{
                            display: 'flex',
                            fontSize: '12px',
                        }}
                    >
                        { itemContent }
                    </div>
                )})
            : null
        }
        </>
    )
}

export default CaseExtraView
