import { Box } from "@mui/material"

type TWarningProps = {
    isEnabled: boolean
    text?: string
}

const Warning = (props:TWarningProps) =>{
    
    const { isEnabled, text } = props

    return(
        isEnabled ?
            <Box
                sx={{
                    width: '100%',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid #ccc',
                    borderRadius: '10px',
                }}
            > 
                {
                    text ? 
                        text 
                    :
                        'Please, select software from dropdown list'
                }
                
            </Box>
        :
            <></>
    )
}

export default Warning
