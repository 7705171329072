import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { useAction } from "../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { enumSORT } from "../../../Types/General"
import colorLibrary from "../../../Utils/colors"
import { tableHeaderDescription } from "./mtpTableHeaderDescription"

const sortLabelStyle = {
    '&.MuiTableSortLabel-root': {
        color: 'white',
    },
    '&.MuiTableSortLabel-root:hover': {
        color: 'white',
    },
    '&.Mui-active': {
        color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
        color: 'white !important',
    },
    color: 'white',
}

type TMTPCaseListTableHeaderProps = {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const MTPCaseListTableHeader = (props: TMTPCaseListTableHeaderProps) =>{

    const { setLoading } = props 
    const { direction } = useTypedSelector(state => state.mtp.mtpCasesList.filterData)
    const { setMtpListPageDirection, setMtpNeedUpdateCasesListFromAPI } = useAction()
    return(
        <TableHead
            sx={{
                backgroundColor: colorLibrary.backgroundColor,
            }}
        >
            <TableRow>
                { 
                    tableHeaderDescription.map((headerItem, headerItemIndex) =>{
                        
                        return(
                            <TableCell
                                key={headerItem.id}
                                align={ headerItem.align }
                                padding='normal'
                            >
                                {
                                    headerItem.isSortable ?

                                        <TableSortLabel
                                            active={ headerItem.isSortable }
                                            direction={ direction === enumSORT.ASC ? 'asc' : 'desc' }
                                            
                                            onClick={()=>{
                                            
                                                setMtpListPageDirection( direction === enumSORT.ASC ? enumSORT.DESC : enumSORT.ASC)
                                                setMtpNeedUpdateCasesListFromAPI(true) 
                                                setLoading(true)
                                            }}

                                            sx={sortLabelStyle}
                                        >
                                            { headerItem.label }
                                            
                                        </TableSortLabel>
                                    :

                                        <Box sx={{color: 'white', cursor: 'default'}}>
                                            {
                                                headerItem.label
                                            }
                                        </Box>
                                }
                            </TableCell>
                        )
                    }) 
                }
            </TableRow>
        </TableHead>
    )
}

export default MTPCaseListTableHeader
