import { useMemo } from 'react'
import { TreeView } from '@mui/x-tree-view'

 import getFileTree, { TMTPTreeFileDescription } from './getFileTree'
 import IconSquarePlus from '../../CaseManagement/Case/CaseFiles/IconSquarePlus' 
 import IconSquareMinus from '../../CaseManagement/Case/CaseFiles/IconSquareMinus'
import MTPThreeViewFolder from './MTPThreeViewFolder'
import MTPThreeViewFile   from './MTPThreeViewFile'
import { TMtpCaseFileDescription } from '../../../Types/Store/StoreMtp'

type TMTPThreeViewProps = {
    files? : TMtpCaseFileDescription[]
    child?: TMTPTreeFileDescription[]
}

const MTPThreeView = (props: TMTPThreeViewProps) => {

    const salt = Date.now().toString()
    const { files, child } = props
    
    const fileTree = useMemo(()=>{
        return(
            child ?
                child
            :
                getFileTree( files ? files : [])
        )
    },[files, child])

    return (
        <TreeView
            defaultCollapseIcon={<IconSquareMinus />}
            defaultExpandIcon={<IconSquarePlus />}
            defaultExpanded = {['000']}
        >

            {
                fileTree.map((treeItem, treeItemIndex)=>{
                    return(
                        treeItem.type === 'FOLDER' ?

                            <MTPThreeViewFolder
                                key    = { salt + treeItemIndex }
                                nodeId = {salt + treeItem.name  } 
                                child  = { treeItem.child       } 
                                label  = { treeItem.name        }
                            />
                        :
                            <MTPThreeViewFile 
                                key             = { salt + treeItemIndex }
                                nodeId          = { salt + treeItem.name }
                                label           = { treeItem.name        }
                                rawFileName     = { treeItem.name        }
                                fileDescription = { treeItem.fileDescription }
                            />
                    )
                })
            }

        </TreeView>
    )
}

export default MTPThreeView
