import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import postAddUserToGroup from '../../Api/UserGroups/post-add-user-to-group'
import useValidation, { enumFormValidationRule } from '../formValidator'
import { FormControl, FormHelperText } from '@mui/material'
import { useAction } from '../../Store/Hook/use-action'
import { buttonContainedStyle } from '../../Utils/colors'

export type TFormAddUserProps = {
    groupId: string | undefined
}

export default function FormDialog(props: TFormAddUserProps) {
    const [ open                , setOpen                ] = useState(false)
    const [ isNeedToSendRequest , setIsNeedToSendRequest ] = useState(false)
    const { setNeedUpdateUsersFromAPI,          SetUserGroupsError, SetUserGroupsErrorNeedToShow } = useAction()

    const [
        userId,
        isUserIdValid,
        userIdValidationError,
        onUserIdChange
    ] = useValidation([enumFormValidationRule.EMAIL])

    useEffect(()=>{
        if(isNeedToSendRequest === true){
            if(isUserIdValid === true){
                handleClose()
                
                postAddUserToGroup({
                    groupId: props.groupId ? props.groupId : '',
                    login :  userId
                }).then((response)=>{
                    setNeedUpdateUsersFromAPI(true)
                }).catch((error)=>{
                    console.log("CAN'T ADD User to group", error.response, error)

                    SetUserGroupsError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                    })

                    SetUserGroupsErrorNeedToShow(true)
                })
            }
            setIsNeedToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        onUserIdChange('', true)
    }

    const handleAddUser = () => {
        onUserIdChange(userId)
        setIsNeedToSendRequest(true)
    }

    return (
        <div>
            <Button 
                variant="contained" 
                color='primary' 
                onClick={handleClickOpen} 
                sx={{
                    margin: '0 0 8px 0',
                    ...buttonContainedStyle
                }}
            >
                Add user
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add user to group</DialogTitle>
                <DialogContent sx={{minWidth: 500}}>
                    <DialogContentText>
                        Enter user's email.
                    </DialogContentText>
                    <FormControl fullWidth error={ !isUserIdValid }>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={ userId }
                            error={ !isUserIdValid }
                            onChange={(e)=>{
                                onUserIdChange(e.target.value)
                            }}
                        />
                        <FormHelperText>
                            { userIdValidationError }
                        </FormHelperText>
                    </FormControl>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddUser}>Add user</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
