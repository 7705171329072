import { Box, Button, Dialog, DialogActions } from "@mui/material"
import AceEditor from "react-ace"

type TVariableValueViewerProps = {
    isViewerOpened: boolean
    setViewerOpened: React.Dispatch<React.SetStateAction<boolean>>
    viewerValue: string | undefined
    setViewerValue: React.Dispatch<React.SetStateAction<string | undefined>>
}


const  VariableValueViewer = (props:TVariableValueViewerProps) =>{
    
    const {
        isViewerOpened,
        setViewerOpened,
        viewerValue,
        setViewerValue,
    } = props
    
    const handleClose = () =>{
        setViewerValue('')
        setViewerOpened(false)
    }

    return(
        <Dialog
            fullWidth
            maxWidth='xl'
            open={isViewerOpened}
            onClose={handleClose}
        >
            <Box sx={{
                margin: `20px`,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                width: '95%',
                height: '75vh',
            }}>
                <AceEditor
                    mode="json"
                    theme="eclipse"
                    onChange={(value, stat) => {
                        //onFileContentChange(value)
                    }}
                    value={ 
                        viewerValue
                    }

                    editorProps={{ 
                        $blockScrolling: true,
                    }}
                    style={{
                        height: '100%',
                        width: '100%',
                        border: '1px solid grey',
                    }}
                    setOptions={{
                        useWorker: false
                    }}
                />
            </Box>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default VariableValueViewer
