import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TComment } from "../../../Pages/CaseManagement/Case/Comments/useComments"

// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/createComment

export type TApiPostCommentParams = {
    [enumApiParameter.CASE_ID    ] : string
    content: string
}

const postCreateCaseComment = async ( params: TApiPostCommentParams ): Promise<AxiosResponse<TComment>>  =>{
    return await API.post(
        `/v2/cases/${params.caseId}/comments`,
        {
            content: params.content
        }
    )
}

export default postCreateCaseComment
