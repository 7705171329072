import isOktaTokenPresentInLocalStorage from "./check-token"

const checkLocalToken = async (
    setIsAuthenticated: (isAuthenticated: boolean) => void
) =>{

    await isOktaTokenPresentInLocalStorage()
    .then(()=>{
        setIsAuthenticated(true)
    })
    .catch((e)=>{
        console.log('TOKEN NOT IN STORAGE')
        setIsAuthenticated(false)
    })
}

export default checkLocalToken
