import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCase, TCaseManagementSortingBy } from "../../../Types/Store/StoreCaseManagement"
import { enumApiParameter, enumSORT } from "../../../Types/General"


export type TApiGetCasesByFilterParams = {
    [enumApiParameter.DIRECTION        ]? : enumSORT
    [enumApiParameter.PAGE_NUMBER      ]? : number
    [enumApiParameter.PAGE_SIZE        ]? : number
    [enumApiParameter.CREATED_BY       ]? : string
    dateCreatedFrom                     ? : string
    dateCreatedTo                       ? : string
    dateUpdatedFrom                     ? : string
    dateUpdatedTo                       ? : string
    [enumApiParameter.EXTERNAL_ID      ]? : string
    [enumApiParameter.ID               ]? : string
    locked                              ? : string // boolean ?
    sortBy                              ? : TCaseManagementSortingBy
    source                              ? : string
    [enumApiParameter.STATUS           ]? : string
    automatedMildCase?                    : string
}

const getCasesByFilter = async ( requestParams: TApiGetCasesByFilterParams ): Promise<AxiosResponse<TCase[]>>  =>{
    return await API.get(
        
        `/v2/cases`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getCasesByFilter
