import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export interface TSvgIconProps extends SvgIconProps {
    path: string
}

const MenuIcon = (props:TSvgIconProps) => {
    const { path } = props
    return (
        <SvgIcon {...props}>
            <path d={ path } />
        </SvgIcon>
    )
}

export default MenuIcon
