import { Box } from "@mui/material"
import getIconByFilenameExtension from "../../../../../Utils/getIconByFilenameExtension"
import { TUploadTreeNode } from "./FilesTreeNodeDragAndDropUploader"
import getIconColorByFilenameExtension from "../../../../../Utils/getIconColorByFilenameExtension"
import formatBytes from "../../../../../Utils/format-bytes"


type TFileUploaderTreeItemProps = {
    nodeItem: TUploadTreeNode
    isUploadStarted: boolean
    depthLevel: number
}
const FileUploaderTreeItem = (props:TFileUploaderTreeItemProps) =>{

    const { 
        nodeItem, 
        isUploadStarted,
        depthLevel
    } = props

    return(
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                marginLeft: `${16*depthLevel}px`
            }}
        > 
            <Box
                component={getIconByFilenameExtension(nodeItem.name)}
                sx={{
                    fill: getIconColorByFilenameExtension(nodeItem.name),
                    margin: '0',
                    width: '30px',
                    height: '30px',
                }}
            />
            <Box
                sx={{ margin: '4px' }}
            >
                { nodeItem.name }
            </Box>

            <Box
                sx={{ 
                    margin: '4px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <strong>
                    { formatBytes(nodeItem.size) } 
                </strong>

                {
                    isUploadStarted &&
                    <Box
                        sx={{ margin: '4px' }}
                    >
                        { `( ${nodeItem.progress}% )` }
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default FileUploaderTreeItem
