
const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

const checkUuid = ( stringToTest?:string ):boolean =>{

    let result = false

    if(stringToTest){
        result = uuidPattern.test(stringToTest)
    }

    return result

}

export default checkUuid
