import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetUrlForCaseFileParams } from "./get-url-for-case-file"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Case%20File%20API/fileDeleteLink

type TApiDeleteXaseFileByS3KeyParams = TApiGetUrlForCaseFileParams

const deleteCaseFileByS3Key = async ( params: TApiDeleteXaseFileByS3KeyParams ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/cases/${ params.id }/files/${ params.s3key }`
    )
}

export default deleteCaseFileByS3Key
