import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { useAction } from "../../../../Store/Hook/use-action"
import AccountCircle        from '@mui/icons-material/AccountCircle'
import CancelIcon           from '@mui/icons-material/Cancel'
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { enumSortingField } from '../../../../Types/General'
import { DateRangePicker } from "rsuite"
import { DateRange } from "rsuite/esm/DateRangePicker"
import { getDateInputTemplate } from "../../../../Utils/format-date-to-local"
import isAfter from 'date-fns/isAfter'

const UserListFilters = () => {

    const {
        SetNeedUpdateUsersConfigsFromAPI,
        SetUsersLoginFilter,
        SetUsersSortingDirection,
        SetUsersPeriod,
        SetUsersSelectedApplicationVersion,
    } = useAction()

    const { 
        applicationsDescription
    } = useTypedSelector(store => store.desktopConfigurations)

    const {
        sortingField,
        sortingDirection,
        loginFilter,
        periodFrom,
        periodTo,
        selectedApplicationVersion
    } = useTypedSelector(store => store.desktopConfigurations.users)


    const handleChangeApplication = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value.split('|')
        SetUsersSelectedApplicationVersion({
            name: selectedValue[0],
            version: selectedValue[1]
        })
        SetNeedUpdateUsersConfigsFromAPI(true)
    }

    const onDataRangeChange = (e: DateRange | null ) =>{
        if(e === null){
            SetUsersPeriod({
                periodFrom: null,
                periodTo: null
            })
        }else{
            const dateStrings = e.map(item=>item.toISOString())
            SetUsersPeriod({
                periodFrom: dateStrings[0],
                periodTo: dateStrings[1]
            })
            
            if(sortingField !== enumSortingField.UPDATED_AT){
                SetUsersSortingDirection({
                    sortingField: enumSortingField.UPDATED_AT,
                    sortingDirection
                })
            }
        }
        SetNeedUpdateUsersConfigsFromAPI(true)
    }

    return(
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                padding: '10px 0 8px 10px',
                margin: '10px 0 0 0',
                borderRadius: '6px',
                backgroundColor: '#f3f3f3',
                alignItems: 'center', 
            }}
        >
            <FormControl>
                <InputLabel id="software-selector-label">Software</InputLabel>
                <Select
                    labelId="software-selector-label"
                    id="software-selector"
                    value={ typeof selectedApplicationVersion!=='undefined' ? `${selectedApplicationVersion.name}|${selectedApplicationVersion.version}` : ''}
                    label="Software"
                    size="small"
                    onChange={ handleChangeApplication }
                    sx={{
                        padding: 0,
                        width: '200px',
                        backgroundColor: '#fff',
                    }}
                >
                    {
                        applicationsDescription.map((applicationItem, applicationItemindex)=>{
                            return(
                                
                                applicationItem.versions.map((versionItem, versionItemIndex)=>{
                                    return(
                                        <MenuItem 
                                            value={ `${applicationItem.name}|${versionItem}` }
                                            key = {`version-${applicationItemindex}-${versionItemIndex}`}
                                        >
                                            { `${applicationItem.name} ${versionItem}` }
                                        </MenuItem>
                                    )
                                })
                                
                            )
                        })
                    }
                </Select>
            </FormControl>

            {
                selectedApplicationVersion && selectedApplicationVersion.name ?
                    <div style = {{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <TextField
                                autoFocus
                                disabled={ periodFrom!==null? true : false }
                                id="filterByLogin"
                                label= { 'Filter By Login' }
                                type="text"
                                variant="outlined"
                                value={ loginFilter }
                                size="small"
                                sx={{ marginRight: loginFilter!=='' ? undefined : '60px', backgroundColor: '#fff' }}

                                onChange={(e)=>{ 
                                    SetUsersLoginFilter(String(e.target.value))
                                    SetUsersSortingDirection({
                                        sortingField: enumSortingField.LOGIN,
                                        sortingDirection: sortingDirection
                                    })
                                    SetNeedUpdateUsersConfigsFromAPI(true)
                                }}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {
                                loginFilter!=='' ?
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                            SetUsersLoginFilter('')
                                            SetNeedUpdateUsersConfigsFromAPI(true)
                                        }}
                                        sx={{ marginRight: loginFilter!=='' ? '20px' : undefined }}
                                    >
                                        <CancelIcon/>
                                </IconButton> 
                                : null
                            }
                            
                            <DateRangePicker
                                disabled={ loginFilter!==''? true : false }
                                className='dataRange'
                                placeholder="Updated Date"
                                format={ getDateInputTemplate()[0] }
                                placement={'auto'}
                                onChange={ onDataRangeChange }
                                
                                style={{
                                    padding: '8px',
                                    zIndex: 20,
                                    width: '220px',
                                }}
                                value = { 
                                    periodFrom && periodTo ? [ new Date(periodFrom), new Date(periodTo) ] : null
                                }
                                shouldDisableDate={ date => isAfter(date, new Date())}
                            />
                    </div>
                : null
            }
        </Box>
    )
}

export default UserListFilters
